import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Howitworks = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">How It Works</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    How It Works
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          {/* How it works*/}
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-acc">
                <span>01</span>
                <h1>Check the services</h1>
                <p>
                  We offer services for almost every need of a person. Our
                  services include repairs, cleaning, aesthetic services, and
                  more.{' '}
                </p>
                <p>
                  You can select the service as per your needs after browsing
                  the descriptions and user reviews available on our website.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/work-01.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4  order-last order-md-first">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/work-02.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="work-wrap work-wrap-post">
                <span>02</span>
                <h1>Schedule an appointment</h1>
                <p>
                  Once you&#39;ve made up your mind, you may set up an
                  appointment. You only need to provide us with information
                  about your preferred time and date, along with any special
                  needs or information. Eventually, you can easily book a
                  service from our website or application with us and customize
                  your service as per your needs.
                </p>
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-acc ">
                <span>03</span>
                <h1>Maintenance</h1>
                <p>
                  Our professional and verified service providers will arrive at
                  your doorstep at the scheduled time, equipped with all the
                  necessary tools and supplies.{' '}
                </p>
                <p>
                  They will execute the service with utmost care and expertise,
                  ensuring your satisfaction.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img mb-0">
                <ImageWithBasePath
                  src="assets/img/work-03.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4  order-last order-md-first">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/work-04.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="work-wrap work-wrap-pay">
                <span>02</span>
                <h1>Payment and Feedback</h1>
                <p>
                  After the service is completed, you can make a secure payment
                  through our website or app. We provide multiple easy methods
                  for you to make payments. Dont forget to rate and review the
                  service  your feedback helps us maintain high standards and
                  continually improve.
                </p>
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p> */}
              </div>
            </div>
          </div>
          {/* /How it works */}
        </div>
      </div>
    </>
  );
};

export default Howitworks;
