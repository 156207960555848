import React, { useState } from 'react';
import * as Icon from 'react-feather';
import PagesAuthHeader from './common/header';
import { Link, Navigate} from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { loginUser } from '../../../../core/data/api/api';
import { useDispatch } from 'react-redux';
import { setUserRole,setToken } from '../../../../core/data/redux/action';
const Login = () => {
  const routes = all_routes;
 
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState(''); // State for radio buttons
  const [redirectToDashboard, setRedirectToDashboard] = useState(false); 
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const data = await loginUser(email, password);
      console.log('Login successful:', data.data.payload.token);
      const user_token = data.data.payload.token;
      localStorage.setItem('user_token', user_token); 
      dispatch(setToken(user_token));
      dispatch(setUserRole(userType)); 
      setEmail('');
      setPassword('');
      setRedirectToDashboard(true);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.customerProfile} />;
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Login</h3>
                  <p>Please enter your details</p>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="johndoe@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                      />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">Password</label>
                        </div>
                        <div className="col-auto">
                          <Link
                            className="forgot-link"
                            to={routes.passwordRecovery}
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="pass-group">
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? (
                            <Icon.EyeOff className="react-feather-custom" />
                          ) : (
                            <Icon.Eye className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">User Type</label>
                      <div className="d-flex">
                        <div className="form-check me-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="user"
                            name="userType"
                            value="user"
                            checked={userType === 'user'}
                            onChange={(e) => setUserType(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor="sendUser">
                          User
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="provider"
                            name="userType"
                            value="provider"
                            checked={userType === 'provider'}
                            onChange={(e) => setUserType(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor="sendProvider">
                          Provider
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Login
                  </button>
                  <p className="no-acc">
                    Dont have an account{' '}
                    <Link to={routes.chooseSignUp}>Register</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
