export const DeletedServicesdata = [
  {
    id: 1,
    image: 'assets/img/services/service-06.jpg',

    discount: '25%',

    amt: '$80',
    tax: '$20',
    date1: '28 Sep 2022',
    date2: '28 Sep 2022',

    icon: 'edit',
    icon1: 'trash-2',

    name: 'Computer Repair',
    category: 'Computer',
    created: 'Provider',
    sub: 'Other',
    bg: 'badge-pending',
    content: 'Pending',
    class: '#delete-item',
    bg1: 'badge-delete',
    content1: 'Deleted',
    bg2: 'badge-inactive',
    content2: 'Inactive',
  },
  {
    id: 2,
    image: 'assets/img/services/service-04.jpg',

    discount: '10%',

    amt: '$50',
    tax: '$5',
    date1: '17 Sep 2022',
    date2: '17 Sep 2022',

    icon: 'edit',
    icon1: 'trash-2',

    name: 'Car Repair Services',
    category: 'Automobile',
    created: 'Admin',
    sub: 'Other',
    bg: 'badge-pending',
    content: 'Pending',
    class: '#delete-item',
    bg1: 'badge-delete',
    content1: 'Deleted',
    bg2: 'badge-inactive',
    content2: 'Inactive',
  },
  {
    id: 3,
    image: 'assets/img/services/service-08.jpg',

    discount: '10%',

    amt: '$14',
    tax: '$2',
    date1: '13 Sep 2022',
    date2: '13 Sep 2022',

    icon: 'edit',
    icon1: 'trash-2',

    name: 'Steam Car Wash',
    category: 'Car Wash',
    created: 'Provider',
    sub: 'Other',
    bg: 'badge-pending',
    content: 'Pending',
    class: '#delete-item',
    bg1: 'badge-delete',
    content1: 'Deleted',
    bg2: 'badge-inactive',
    content2: 'Inactive',
  },
  {
    id: 4,
    image: 'assets/img/services/service-09.jpg',

    discount: '35%',

    amt: '$100',
    tax: '$25',
    date1: '7 Sep 2022',
    date2: '7 Sep 2022',

    icon: 'edit',
    icon1: 'trash-2',

    name: 'House Cleaning',
    category: 'Cleaning',
    created: 'Admin',
    sub: 'Other',
    bg: 'badge-pending',
    content: 'Pending',
    class: '#delete-item',
    bg1: 'badge-delete',
    content1: 'Deleted',
    bg2: 'badge-inactive',
    content2: 'Inactive',
  },
];
