import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { fetchCategories } from '../../../../core/data/api/api'; // Adjust the path as necessary

// Define the type for a category
interface SubCategory {
  id: number;
  name: string;
}

interface Category {
  id: number;
  name: string;
  image_url: string;
  sub_categories: SubCategory[];
}

const Categories = () => {
  const routes = all_routes;
  const [categoriesData, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const getCategories = async () => {
      try {
        const categoryData: Category[] = await fetchCategories();
        setCategories(categoryData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    getCategories();
  }, []);

  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Categories</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {categoriesData.map(category => {
              // Ensure there are no leading slashes
              const imageUrl = `https://papi.embteltestdomain.com/content/${category.image_url.replace(/^\/*/, '')}`;
             

              return (
                <div key={category.id} className="col-md-6 col-lg-4 d-flex">
                  <div className="category-card flex-fill">
                    <div className="category-img">
                      <img
                        src={imageUrl}
                        alt={category.name}
                        className="img-fluid"
                        style={{ width: '100%', height: '20em' }} 
                       
                      />
                    </div>
                    <div className="category-info">
                      <div className="category-name">
                        <span className="category-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/category-01.svg"
                            alt="Service"
                          />
                        </span>
                        <h6>
                          <Link to={routes.serviceGrid}>{category.name}</Link>
                        </h6>
                      </div>
                      <p>{category.sub_categories.length} Sub Services</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
