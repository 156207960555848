import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Calendar } from 'primereact/calendar';

const ProviderHoliday = () => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    document.body.classList.add('provider-body');

    return () => document.body.classList.remove('provider-body');
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Holidays &amp; Leave</h6>
                </div>
              </div>
              <div className="col-md-8">
                <div className="leave-action text-md-end">
                  <Link to="#" className="btn btn-secondary">
                    <Icon.File className="react-feather-custom" /> Leave History
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#add-holiday"
                  >
                    Add Holiday
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary add-set"
                    data-bs-toggle="modal"
                    data-bs-target="#add-leave"
                  >
                    Add Leave
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* calendar */}
          <div className="row">
            <div className="col-md-12">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
              />
            </div>
          </div>
          {/* /calendar */}
        </div>
      </div>

      {/* Add Holiday */}
      <div className="modal fade custom-modal" id="add-holiday">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Add Holiday</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label">Holiday Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Holiday Name"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">From</label>
                    <div className="form-icon">
                      <Calendar
                        value={date}
                        onChange={(e:any) => setDate(e.value)}
                        placeholder="Select Date"
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">To</label>
                    <div className="form-icon">
                      <Calendar
                        value={date}
                        onChange={(e: any) => setDate(e.value)}
                        placeholder="Select Date"
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Description</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      placeholder="Enter Description"
                      defaultValue={''}
                    />
                  </div>
                  <div className="modal-submit text-end">
                    <Link
                      to="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Holiday */}
      {/* Add Leave */}
      <div className="modal fade custom-modal" id="add-leave">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label">From</label>
                    <div className="form-icon">
                      <Calendar
                        value={date}
                        onChange={(e: any) => setDate(e.value)}
                        placeholder="Select Date"
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">To</label>
                    <div className="form-icon">
                      <Calendar
                        value={date}
                        onChange={(e: any) => setDate(e.value)}
                        placeholder="Select Date"
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Reason</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Enter Reason"
                      defaultValue={''}
                    />
                  </div>
                  <div className="modal-submit text-end">
                    <Link
                      to="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Leave */}
      {/* Alert Message */}
      <div className="modal fade custom-modal" id="alertmsg">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Alert Message</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="msg-alert">
                  <p>If You Take Your Appointment Automatically Cancelled.</p>
                </div>
                <div className="modal-submit text-end">
                  <Link
                    to="#"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Ok
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Alert Message */}
    </>
  );
};

export default ProviderHoliday;
