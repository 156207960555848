import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';

const TermsCondition = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Terms &amp; Condition</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Terms &amp; Condition
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}
            <div className="col-md-12">
              <div className="terms-content">
                <p>
                  Welcome to Power Buddy! The terms and conditions agreement
                  outlines the rules for utilizing our services via our website.
                  When you access and use our services through our platforms,
                  you are agreeing to comply with and be bound by these Terms.
                </p>
                <p>
                  Please read these terms carefully. You must not use our
                  services if you disagree with any part of these terms.
                </p>

                <p>
                  For several services, we offer a platform (Power Buddy) that
                  links consumers with independent service providers (also known
                  as &quot;Service Professionals&quot;).These Terms, our Privacy
                  Policy, and any additional terms or policies that we might
                  introduce from time to time have legal effect on you if you
                  use our services.
                </p>
                <h5 className="text-primery mt-5">SERVICES OVERVIEW</h5>
                <ul>
                  <li>
                   <b> We as Intermediators:</b> You are utilizing the services of
                    independent professionals, and we are working as
                    intermediaries between you and these service professionals.
                  </li>
                  <li>
                  <b>  Service Provision:</b> We do not provide services directly.
                    Since we are not providing services by ourselves, we are not
                    completely responsible for the quality, efficiency, and
                    caliber of service execution. However, we assure you that we
                    will provide you with a trustworthy service provider.
                  </li>
                  <li>
                   <b> Communications:</b> We send you updates via WhatsApp, email, or
                    SMS regarding your reservations. However, if you do not want
                    to receive these messages, you can easily opt out, and you
                    will stop getting messages, but doing so may make it more
                    difficult for you to access certain services.
                  </li>
                  {/* <li>
                    Duis non leo commodo, euismod ipsum a, feugiat libero.
                  </li> */}
                </ul>

                <h5 className="text-primery mt-5">ACCOUNT REGISTRATION</h5>
                <ul>
                  <li>
                    <b>Creating an Account:</b> To utilize our services, you will need
                    to create an account on the platform. The person who will
                    create the account needs to be at least 18.
                  </li>
                  <li>
                   <b> Accuracy of Information:</b> You agree to provide accurate and
                    current information when creating your Account and to update
                    this information as necessary.
                  </li>
                  <li>
                   <b> Account Security:</b> You bear the responsibility for all
                    activities conducted using your account and for keeping your
                    account credentials secret.You must tell us immediately if
                    you discover any illegal use of your account.
                  </li>
                  <li>
                   <b> Communication Preferences:</b> By creating an Account, you agree
                    to receive communications related to your bookings, our
                    Services, and promotional offers from Power Buddy and our
                    partners.
                  </li>
                </ul>

                <h5 className="text-primery mt-5">ACONTENT FROM USERS</h5>
                <ul>
                  <li>
                    <b>Posting Content:</b> You are welcome to post reviews, comments,
                    or any other kind of content as a user of our services. You
                    are still accountable for any user material you post,
                    including its accuracy and legality.
                  </li>
                  <li>
                    <b>Content Use License:</b> When you post your content with your
                    consent on our website, we have a right to use, display,
                    change, and distribute it in connection with our services.
                  </li>
                  <li>
                   <b> Review and Removal:</b> Any user content that disobeys these
                    Terms may be reviewed and removed at our sole discretion.
                  </li>
                </ul>

                <h5 className="text-primery mt-5">PAYMENT TERMS</h5>
                <ul>
                  <li>
                   <b> Service Fees:</b> The Platform serves as an interface for Pro
                    Service payments. You will receive a detailed explanation of
                    service fees at the time of booking.
                  </li>
                  <li>
                    <b>Refunds and cancellations:</b> At the time the booking is done,
                    the refunding and cancelation rules of Pro Services will be
                    stated. Upon its discretion, Power Buddy may decide to deal
                    with disputes and provide refunds.
                  </li>
                </ul>

                <h5 className="text-primery mt-5">TERMINATION</h5>
                <ul>
                  <li>
                    <b>Account Termination:</b> If you breach these Terms or take part
                    in any unlawful or harmful activity, we reserve the right to
                    suspend or cancel your Account and your access to the
                    Platform.
                  </li>
                  <li>
                    <b>Effect of Termination:</b> In the event that you decide to
                    terminate, you must stop using the Platform. All clauses in
                    these Terms that should, by definition, survive termination
                    will still be in effect.
                  </li>
                </ul>

                <h5 className="text-primery mt-5">TERMS CHANGES</h5>
                <ul>
                  <li>
                    Power Buddy reserves the right to make changes to these
                    Terms at any time. If there are any major changes, we will
                    post the updated Terms on our Platform to let you know. Your
                    continued use of the Services following such modifications
                    will be interpreted as your agreement to the revised Terms.
                  </li>
                </ul>

                <h5 className="text-primery mt-5">CONTACT US</h5>
                <ul>
                  <li>
                    If you have any questions or concerns about these Terms or
                    our Services, please contact us at hello@embtelsolutions.com
                    XX
                  </li>
                </ul>

                {/* <div className="terms-btn">
                  <Link to="#" className="btn btn-secondary">
                    Not right now...
                  </Link>
                  <Link to="#" className="btn btn-primary">
                    I agree with terms
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
