import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import ProviderBookingModal from '../../common/modals/provider-booking-modal';
import { all_routes } from '../../../../core/data/routes/all_routes';


const ProviderBooking = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  return (
    <><div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Booking List</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <div className="review-sort me-2">
                <p>Sort</p>
                <Dropdown
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.value)}
                  options={value}
                  optionLabel="name"
                  placeholder="A - Z"
                  className="select provider-select select-space" />
              </div>
              <div className="grid-listview">
                <ul>
                  <li>
                    <Link to="#">
                      <Icon.Filter className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerBookDetails}>
                      <Icon.Calendar className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerService}>
                      <Icon.Grid className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerBooking} className="active">
                      <Icon.List className="standard-feather" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-md-12">
            {/* Booking List */}
            <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails2}>
                    <ImageWithBasePath
                      src="assets/img/services/service-08.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={1}
                    onClick={() => handleItemClick(1)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[1] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails2}>Computer Services</Link>
                    <span className="badge badge-danger">Cancelled</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 27 Sep
                      2023, 17:00-18:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $100.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Newyork, USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div>
                        John Doe
                      </div>
                      <p>info@johndoe.com</p>
                      <p>+1 888 888 8888</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="booking-action">
                <Link to={routes.booking1} className="btn btn-secondary">
                  Reschedule
                </Link>
              </div>
            </div>
            {/* /Booking List */}
            {/* Booking List */}
            <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails2}>
                    <ImageWithBasePath
                      src="assets/img/services/service-18.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={2}
                    onClick={() => handleItemClick(2)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[2] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails2}>Car Repair Services</Link>{' '}
                    <span className="badge badge-success">Completed</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 23 Sep
                      2023, 10:00-11:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $50.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Alabama, USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-03.jpg" />
                        </div>
                        John Smith
                      </div>
                      <p>info@johnsmith.com</p>
                      <p>+1 607-276-5393</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="booking-action">
                <Link to={routes.booking1} className="btn btn-primary">
                  Rebook
                </Link>
                <Link
                  to="#"
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-review"
                >
                  <Icon.PlusCircle className="react-feather-custom" /> Add
                  Review
                </Link>
                <div className="view-action">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                  <Link to={routes.serviceDetails2} className="view-btn">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            {/* /Booking List */}
            {/* Booking List */}
            <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails2}>
                    <ImageWithBasePath
                      src="assets/img/services/service-07.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={3}
                    onClick={() => handleItemClick(3)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[3] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails2}>Interior Designing</Link>{' '}
                    <span className="badge badge-info">Inprogress</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 27 Sep
                      2023, 17:00-18:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $50.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Washington,
                      DC, USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-06.jpg" />
                        </div>
                        Quentin
                      </div>
                      <p>info@quentin.com</p>
                      <p>+1 601-810-9218</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="booking-action">
                <Link to={routes.providerChat} className="btn btn-primary">
                  <Icon.MessageSquare className="react-feather-custom" /> Chat
                </Link>
                <Link to="#" className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
            {/* /Booking List */}
            {/* Booking List */}
            <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails2}>
                    <ImageWithBasePath
                      src="assets/img/services/service-13.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={4}
                    onClick={() => handleItemClick(4)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[4] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails2}>
                      House Cleaning Services
                    </Link>{' '}
                    <span className="badge badge-warning">Pending</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 27 Sep
                      2023, 17:00-18:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $375.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Virginia,
                      USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div>
                        Johnson
                      </div>
                      <p>info@johnson.com</p>
                      <p>+1 312-328-3970</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="booking-action">
                <Link to={routes.providerChat} className="btn btn-primary">
                  <Icon.MessageSquare className="react-feather-custom" /> Chat
                </Link>
                <Link to="#" className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
            {/* /Booking List */}
            {/* Booking List */}
            <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails2}>
                    <ImageWithBasePath
                      src="assets/img/services/service-06.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={5}
                    onClick={() => handleItemClick(5)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[5] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails2}>
                      Electric Panel Repairing Service
                    </Link>{' '}
                    <span className="badge badge-danger">Cancelled</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 27 Sep
                      2023, 17:00-18:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $100.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Newyork, USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div>
                        John Doe
                      </div>
                      <p>Info@johndoe.com</p>
                      <p>+1 888 888 8888</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="booking-action">
                <Link to={routes.booking1} className="btn btn-primary">
                  Rebook
                </Link>
                <Link
                  to="#"
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-review"
                >
                  <Icon.PlusCircle className="react-feather-custom" /> Add
                  Review
                </Link>
                <div className="view-action">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                  <Link to={routes.serviceDetails2} className="view-btn">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            {/* /Booking List */}
          </div>
        </div>
        {/* Pagination */}
        <div className="row">
          <div className="col-sm-6">
            <div className="review-entries">
              <span>Show</span>
              <select>
                <option>07</option>
                <option>08</option>
              </select>
              <span>entries</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="review-pagination">
              <p>1 - 09 of 09</p>
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item active">
                  <Link className="page-link" to="#">
                    2 <span className="visually-hidden">(current)</span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Pagination */}
      </div>
    </div><ProviderBookingModal /></>
  );
};

export default ProviderBooking;
