export const ReviewTypes = [
  {
    id: '1',
    status: 'High',
    isActive: true,
  },
  {
    id: '2',
    status: 'Normal',
    isActive: false,
  },
  {
    id: '3',
    status: 'Excellent',
    isActive: true,
  },
  {
    id: '4',
    status: 'Good',
    isActive: false,
  },
];
