export const providerCouponsData = [
  {
    name: 'New Year',
    code: 'NEW2023',
    type: 'Percentage',
    discount: '5%',
    limit: '100',
    used: '1',
    validDate: '02 Sep 2023 - 10 Sep 2023',
    content: 'Once per customer',
    servicesName: 'Interior Designing',
    status: 'Action',
  },
  {
    name: 'Christmas',
    code: 'JOLLY15',
    type: 'Fixed',
    discount: '$2.00',
    limit: '100',
    used: '1',
    validDate: '04 Sep 2023 - 12 Sep 2023',
    content: 'Once per customer',
    servicesName: 'House Cleaning',
    status: 'Inaction',
  },
  {
    name: 'Valentines',
    code: 'LOVERLOVER',
    type: 'Percentage',
    discount: '5%',
    limit: '100',
    used: '1',
    validDate: '10 Sep 2023 - 20 Sep 2023',
    content: 'Once per customer',
    servicesName: 'Car Wash',
    status: 'Action',
  },
  {
    name: 'Halloween',
    code: 'SPOOKY15',
    type: 'Fixed',
    discount: '$3.00',
    limit: '100',
    used: '1',
    validDate: '15 Sep 2023 - 12 Sep 2023',
    content: 'Once per customer',
    servicesName: 'Computer Services',
    status: 'Inaction',
  },
  {
    name: 'Black Friday',
    code: 'GIFTGUIDE',
    type: 'Percentage',
    discount: '5%',
    limit: '100',
    used: '1',
    validDate: '20 Sep 2023 - 22 Sep 2023',
    content: 'Once per customer',
    servicesName: 'Car Repair',
    status: 'Action',
  },
]