import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const BlogDetails = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <>
        <div>
          {/* Breadcrumb */}
          <div className="breadcrumb-bar">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-12">
                  <h2 className="breadcrumb-title">Our Blog</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={routes.homeOne}>Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Blog
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Blog Details
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* /Breadcrumb */}
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 blog-details">
                  <div className="blog-head">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <span className="cat-blog">Construction</span>
                        </li>
                      </ul>
                    </div>
                    <h3>
                      Lorem ipsum dolor sit amet, eiusmod tempor ut labore et
                      dolore magna aliqua.{' '}
                    </h3>
                    <div className="blog-category sin-post">
                      <ul>
                        <li>
                          <Icon.Calendar className="react-feather-custom me-1" />
                          28 Sep 2023
                        </li>
                        <li>
                          <div className="post-author">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="Post Author"
                              />
                              <span>Admin</span>
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Blog Post */}
                  <div className="blog blog-list">
                    <div className="blog-image">
                      <Link to={routes.blogDetails}>
                        <ImageWithBasePath
                          className="img-fluid"
                          src="assets/img/blog/blog-04.jpg"
                          alt="Post Image"
                        />
                      </Link>
                    </div>
                    <div className="blog-content">
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                        quia consequuntur magni dolores eos qui ratione
                        voluptatem sequi nesciunt.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                        quia consequuntur magni dolores eos qui ratione
                        voluptatem sequi nesciunt.
                      </p>
                      <p className="test-info">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                        quia consequuntur magni dolores eos qui ratione
                        voluptatem sequi nesciunt.
                      </p>
                    </div>
                  </div>
                  {/* /Blog Post */}
                  <div className="social-widget blog-review">
                    <h4>Tags</h4>
                    <div className="ad-widget">
                      <ul>
                        <li>
                          <Link to="#">Construction</Link>
                        </li>
                        <li>
                          <Link to="#">Car Wash</Link>
                        </li>
                        <li>
                          <Link to="#">Appliance</Link>
                        </li>
                        <li>
                          <Link to="#">Interior</Link>
                        </li>
                        <li>
                          <Link to="#">Carpentry</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Reviews */}
                  <div className="service-wrap blog-review">
                    <h4>Comments</h4>
                    <ul>
                      <li>
                        <div className="review-box">
                          <div className="review-profile">
                            <div className="review-img">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                className="img-fluid"
                                alt="img"
                              />
                              <div className="review-name">
                                <h6>Dennis</h6>
                                <p>a week ago</p>
                              </div>
                            </div>
                            <Link to="#" className="reply-box">
                              <i className="fas fa-reply me-2" /> Reply
                            </Link>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqa. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat
                          </p>
                          <div className="new-comment reply-com">
                            <form>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Name*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Email*
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email Address"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Website
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Website"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Message*
                                    </label>
                                    <textarea
                                      rows={4}
                                      className="form-control"
                                      placeholder="Enter Your Comment Here...."
                                      defaultValue={''}
                                    />
                                  </div>
                                </div>
                                <div className="submit-section">
                                  <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <ul className="comments-reply">
                          <li>
                            <div className="review-box">
                              <div className="review-profile">
                                <div className="review-img">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-02.jpg"
                                    className="img-fluid"
                                    alt="img"
                                  />
                                  <div className="review-name">
                                    <h6>Dennis</h6>
                                    <p>a week ago</p>
                                  </div>
                                </div>
                                <Link to="#" className="reply-box">
                                  <i className="fas fa-reply me-2" /> Reply
                                </Link>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipicing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqa. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat
                              </p>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="review-box">
                          <div className="review-profile">
                            <div className="review-img">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-03.jpg"
                                className="img-fluid"
                                alt="img"
                              />
                              <div className="review-name">
                                <h6>Jaime</h6>
                                <p>a week ago</p>
                              </div>
                            </div>
                            <Link to="#" className="reply-box">
                              <i className="fas fa-reply me-2" /> Reply
                            </Link>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqa. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat
                          </p>
                          <div className="new-comment reply-com">
                            <form>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Name*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Email*
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email Address"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Website
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Website"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Message*
                                    </label>
                                    <textarea
                                      rows={4}
                                      className="form-control"
                                      placeholder="Enter Your Comment Here...."
                                      defaultValue={''}
                                    />
                                  </div>
                                </div>
                                <div className="submit-section">
                                  <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="review-box">
                          <div className="review-profile">
                            <div className="review-img">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-07.jpg"
                                className="img-fluid"
                                alt="img"
                              />
                              <div className="review-name">
                                <h6>Joseph</h6>
                                <p>a week ago</p>
                              </div>
                            </div>
                            <Link to="#" className="reply-box">
                              <i className="fas fa-reply me-2" /> Reply
                            </Link>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqa. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat
                          </p>
                          <div className="new-comment reply-com">
                            <form>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Name*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Email*
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email Address"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Website
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Website"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Message*
                                    </label>
                                    <textarea
                                      rows={4}
                                      className="form-control"
                                      placeholder="Enter Your Comment Here...."
                                      defaultValue={''}
                                    />
                                  </div>
                                </div>
                                <div className="submit-section">
                                  <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* /Reviews */}
                  {/* Comments */}
                  <div className="new-comment">
                    <h4>Write a Comment</h4>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Email *</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email Address"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">Message *</label>
                            <textarea
                              rows={6}
                              className="form-control"
                              placeholder="Enter Your Comment Here...."
                              defaultValue={''}
                            />
                          </div>
                        </div>
                        <div className="submit-section">
                          <button
                            className="btn btn-primary submit-btn"
                            type="submit"
                          >
                            Post Comment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* /Comments */}
                </div>
                {/* Blog Sidebar */}
                <div className="col-lg-4 col-md-12 blog-sidebar theiaStickySidebar">
                  <div className="stickybar">
                    {/* Search */}
                    <div className="card search-widget">
                      <div className="card-body">
                        <form className="search-form">
                          <div className="input-group">
                            <input
                              type="text"
                              placeholder="Search..."
                              className="form-control"
                            />
                            <button type="submit" className="btn btn-primary">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* /Search */}
                    {/* Categories */}
                    <div className="card about-widget">
                      <div className="card-body">
                        <h4 className="side-title">About Me</h4>
                        <ImageWithBasePath src="assets/img/profile.jpg" alt="User" />
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam,{' '}
                        </p>
                        <Link to="#" className="btn btn-primary">
                          About Author
                        </Link>
                      </div>
                    </div>
                    {/* /Categories */}
                    {/* Categories */}
                    <div className="card category-widget">
                      <div className="card-body">
                        <h4 className="side-title">Categories</h4>
                        <ul className="categories">
                          <li>
                            <Link to={routes.categories}>Car Wash</Link>
                          </li>
                          <li>
                            <Link to={routes.categories}>Plumbing</Link>
                          </li>
                          <li>
                            <Link to={routes.categories}>Carpenter</Link>
                          </li>
                          <li>
                            <Link to={routes.categories}>Computer Service</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /Categories */}
                    {/* Latest Posts */}
                    <div className="card post-widget">
                      <div className="card-body">
                        <h4 className="side-title">Latest News</h4>
                        <ul className="latest-posts">
                          <li>
                            <div className="post-thumb">
                              <Link to={routes.blogDetails}>
                                <ImageWithBasePath
                                  className="img-fluid"
                                  src="assets/img/services/service-01.jpg"
                                  alt="Blog Image"
                                />
                              </Link>
                            </div>
                            <div className="post-info">
                              <p>06 Nov 2023</p>
                              <h4>
                                <Link to={routes.blogDetails}>
                                  Lorem ipsum dolor amet, consectetur adipiscing
                                  elit. Amet.
                                </Link>
                              </h4>
                            </div>
                          </li>
                          <li>
                            <div className="post-thumb">
                              <Link to={routes.blogDetails}>
                                <ImageWithBasePath
                                  className="img-fluid"
                                  src="assets/img/services/service-02.jpg"
                                  alt="Thumb Image"
                                />
                              </Link>
                            </div>
                            <div className="post-info">
                              <p>06 Nov 2023</p>
                              <h4>
                                <Link to={routes.blogDetails}>
                                  Lorem ipsum dolor amet, consectetur adipiscing
                                  elit. Amet.
                                </Link>
                              </h4>
                            </div>
                          </li>
                          <li>
                            <div className="post-thumb">
                              <Link to={routes.blogDetails}>
                                <ImageWithBasePath
                                  className="img-fluid"
                                  src="assets/img/services/service-03.jpg"
                                  alt="Thumb image"
                                />
                              </Link>
                            </div>
                            <div className="post-info">
                              <p>06 Nov 2023</p>
                              <h4>
                                <Link to={routes.blogDetails}>
                                  Lorem ipsum dolor amet, consectetur adipiscing
                                  elit. Amet.
                                </Link>
                              </h4>
                            </div>
                          </li>
                          <li>
                            <div className="post-thumb">
                              <Link to={routes.blogDetails}>
                                <ImageWithBasePath
                                  className="img-fluid"
                                  src="assets/img/services/service-04.jpg"
                                  alt="Thumb Image"
                                />
                              </Link>
                            </div>
                            <div className="post-info">
                              <p>06 Nov 2023</p>
                              <h4>
                                <Link to={routes.blogDetails}>
                                  Lorem ipsum dolor amet, consectetur adipiscing
                                  elit. Amet.
                                </Link>
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /Latest Posts */}
                    {/* Tags */}
                    <div className="card tags-widget">
                      <div className="card-body">
                        <h4 className="side-title">Tags</h4>
                        <ul className="tags">
                          <li>
                            <Link to="#" className="tag">
                              Consulting
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="tag">
                              Design
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /Tags */}
                    {/* Archives */}
                    <div className="card widget widget-archive mb-0">
                      <div className="card-body">
                        <h4 className="side-title">Archives</h4>
                        <ul>
                          <li>
                            <Link to="#">January 2023</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /Archives */}
                  </div>
                </div>
                {/* /Blog Sidebar */}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default BlogDetails;
