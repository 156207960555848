import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import { Link,Navigate } from 'react-router-dom';
import { forgotPassword } from '../../../../core/data/api/api';
import { all_routes } from '../../../../core/data/routes/all_routes';

const PasswordRecovery = () => {
  const routes = all_routes;
  // const history = useHistory();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(email);
      // console.log("OPT send to Email", response);
      setMessage('Password reset link has been sent to your email.');
      setError('');
      setRedirectToDashboard(true);
    } catch (error) {
      setError('Failed to send password reset link. Please try again.');
      setMessage('');
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.resetPassword} state={{email}} />;
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Password Recovery */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Password Recovery</h3>
                  <p>
                    Enter your email and we will send you a link to reset your password.
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="johndoe@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary w-100 login-btn" type="submit">
                    Submit
                  </button>
                  {message && <div className="alert alert-success mt-2">{message}</div>}
                  {error && <div className="alert alert-danger mt-2">{error}</div>}
                  <p className="no-acc mt-0">
                    Remember Password? <Link to={routes.login}>Login</Link>
                  </p>
                </form>
              </div>
            </div>
            {/* /Password Recovery */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
