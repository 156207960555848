import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { createSubCategory, getCategoryList } from '../../../../core/data/api/api';

// Define a TypeScript interface for the props
interface SubCatogriesModalProps {
  selectedCatogries: {
    id: string;
    name: string;
    slug: string;
    image_url: string;
    is_featured: boolean;
   
  };
}

const SubCatogriesModal: React.FC<SubCatogriesModalProps> = ({
  selectedCatogries,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const [isFeatured, setIsFeatured] = useState(false);

  const [addName, setAddName] = useState('');
  const [addSlug, setAddSlug] = useState('');
  const [addImage, setAddImage] = useState<File | null>(null);
  const [addImageUrl, setAddImageUrl] = useState('');
  const [addFeatured, setAddFeatured] = useState(false);
  const [selectedParentCategory, setSelectedParentCategory] = useState('');

  const [token, setToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCategoryList(token);
        setData(result.data.payload.records);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    if (selectedCatogries) {
      setIsFeatured(selectedCatogries.is_featured);
      setImageUrl(selectedCatogries.image_url);
    }
  }, [selectedCatogries]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setAddImage(file);
      setAddImageUrl(URL.createObjectURL(file));
    }
  };
//  console.log("selected Parent category ", selectedParentCategory)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (addImage) {
      const imageData = await toBase64(addImage);
      try {
        await createSubCategory(token, addName, addSlug, imageData, addFeatured, selectedParentCategory);
        setSuccessMessage('Category created successfully!');
        setIsSubmitted(true);
        setTimeout(() => {
          const modalElement = document.getElementById('add-category');
          if (modalElement) {
            (modalElement as any).classList.remove('show');
            (modalElement as any).style.display = 'none';
            document.body.classList.remove('modal-open');
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
              backdrop.remove();
            }
          }
          setIsSubmitted(false); 
          resetForm();
        }, 3000); 
        
      } catch (error) {
        console.error('Error creating category:', error);
      }
    }
  };

  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const resetForm = () => {
    setAddName('');
    setAddSlug('');
    setAddImage(null);
    setAddImageUrl('');
    setAddFeatured(false);
    setSelectedParentCategory('');
  };

  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="modal-title ">Add Sub Category</h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="react-feather-custom"></Icon.X>
              </button>
            </div>
            <div className="modal-body pt-0">
              {!isSubmitted ? (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Parent Category</label>
                    <select
                      className="form-select"
                      value={selectedParentCategory}
                      onChange={(e) => setSelectedParentCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {data.map((category: any) => (
                        <option className="mb-1" key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addName}
                      placeholder=" "
                      onChange={(e) => setAddName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label me-1">Category Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" "
                      value={addSlug}
                      onChange={(e) => setAddSlug(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Category Image</label>
                    <div className="form-uploads">
                      <div className="form-uploads-path">
                        <div>
                          {addImageUrl && (
                            <img src={addImageUrl} alt="Category" className="img-thumbnail mt-3" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                          )}
                        </div>
                        <ImageWithBasePath
                          src="assets/img/icons/upload.svg"
                          alt="img"
                        />
                        <div className="file-browse">
                          <h6>Drag & drop image or </h6>
                          <div className="file-browse-path">
                            <input type="file" onChange={handleImageChange} />
                            <Link to="#"> Browse</Link>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Is Featured?</label>
                    <ul className="custom-radiosbtn">
                      <li>
                        <label className="radiossets">
                          Yes
                          <input
                            type="radio"
                            name="radio"
                            checked={addFeatured}
                            onChange={() => setAddFeatured(true)}
                          />
                          <span className="checkmark-radio" />
                        </label>
                      </li>
                      <li>
                        <label className="radiossets">
                          No
                          <input
                            type="radio"
                            name="radio"
                            checked={!addFeatured}
                            onChange={() => setAddFeatured(false)}
                          />
                          <span className="checkmark-radio" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="text-end">
                    <Link
                      to="#"
                      type="button"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              ) : (
                <div className="text-center alert alert-success">
                  <h5>Category created successfully!</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}

      {/* Edit Category */}
      <div className="modal fade" id="edit-category">
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">User Details</h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="react-feather-custom"></Icon.X>
              </button>
            </div>
            <div className="modal-body pt-0">
              <form>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Name:</label>
                  <p className="fs-5">{selectedCatogries?.name}</p>
                </div>

                <div className="mb-3 border-bottom">
                  <label className="form-label">Slug:</label>
                  <p className="fs-5">{selectedCatogries?.slug}</p>
                </div>

                <div className="mb-3">
                  <label className="form-label">Category Image</label>
                  <div className="form-uploads">
                    <div className="form-uploads-path">
                      {imageUrl && (
                        <img
                          src={imageUrl}
                          alt="Category"
                          className="img-thumbnail mb-3"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Featured:</label>
                  <p className="fs-5">{isFeatured ? 'TRUE' : 'FALSE'}</p>
                </div>

                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Category */}
    </>
  );
};

export default SubCatogriesModal;
