import React, { useState, useEffect } from 'react';

type Props = {
  prevTab: CallableFunction;
  nextTab: CallableFunction;
  formData: any;
  setLocationData: (data: any) => void;
};

const Location: React.FC<Props> = ({ prevTab, formData, nextTab, setLocationData }) => {
  const [localFormData, setLocalFormData] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    gmap_place_id: '',
    latitude: '',
    longitude: ''
  });

  const [errors, setErrors] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    gmap_place_id: '',
    latitude: '',
    longitude: ''
  });

  useEffect(() => {
    setLocalFormData({
      address: formData.address || '',
      country: formData.country || '',
      state: formData.state || '',
      city: formData.city || '',
      pincode: formData.pincode || '',
      gmap_place_id: formData.gmap_place_id || '',
      latitude: formData.latitude || '',
      longitude: formData.longitude || ''
    });
  }, [formData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const validateFields = () => {
    const newErrors = {
      address: localFormData.address ? '' : 'Address is required',
      country: localFormData.country ? '' : 'Country is required',
      state: localFormData.state ? '' : 'State is required',
      city: localFormData.city ? '' : 'City is required',
      pincode: localFormData.pincode ? '' : 'Pincode is required',
      gmap_place_id: localFormData.gmap_place_id ? '' : 'Google Maps Place ID is required',
      latitude: localFormData.latitude ? '' : 'Latitude is required',
      longitude: localFormData.longitude ? '' : 'Longitude is required'
    };

    if (localFormData.pincode && !/^\d{5,6}$/.test(localFormData.pincode)) {
      newErrors.pincode = 'Invalid pincode format';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSaveClick = () => {
    if (validateFields()) {
      setLocationData(localFormData);
      nextTab();
    }
  };

  return (
    <>
      <div className="addition-service card-section space-service">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-service">
              <h4>Location</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Address"
                name="address"
                value={localFormData.address}
                onChange={handleInputChange}
              />
              {errors.address && <small className="text-danger">{errors.address}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Country"
                name="country"
                value={localFormData.country}
                onChange={handleInputChange}
              />
              {errors.country && <small className="text-danger">{errors.country}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your City"
                name="city"
                value={localFormData.city}
                onChange={handleInputChange}
              />
              {errors.city && <small className="text-danger">{errors.city}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>State</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your State"
                name="state"
                value={localFormData.state}
                onChange={handleInputChange}
              />
              {errors.state && <small className="text-danger">{errors.state}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Pincode</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Pincode"
                name="pincode"
                value={localFormData.pincode}
                onChange={handleInputChange}
              />
              {errors.pincode && <small className="text-danger">{errors.pincode}</small>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Google Maps Place ID</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Maps Place ID"
                name="gmap_place_id"
                value={localFormData.gmap_place_id}
                onChange={handleInputChange}
              />
              {errors.gmap_place_id && <small className="text-danger">{errors.gmap_place_id}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Latitude</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Latitude Number"
                name="latitude"
                value={localFormData.latitude}
                onChange={handleInputChange}
              />
              {errors.latitude && <small className="text-danger">{errors.latitude}</small>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Longitude</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Longitude Number"
                name="longitude"
                value={localFormData.longitude}
                onChange={handleInputChange}
              />
              {errors.longitude && <small className="text-danger">{errors.longitude}</small>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="map-grid">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                  aria-hidden="false"
                  tabIndex={0}
                  className="contact-map"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="bottom-btn">
            <div className="field-btns">
              <button
                className="btn btn-prev prev_btn"
                type="button"
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
                onClick={prevTab}
              >
                <i className="fas fa-arrow-left" /> Prev
              </button>
              <button
                className="btn btn-primary next_btn"
                type="button"
                onClick={handleSaveClick}
              >
                Next <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
