import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';
import { verifyUser } from '../../../../core/data/api/api';
import { useDispatch } from 'react-redux';
import { setUserRole } from '../../../../core/data/redux/action';

const EmailOtp = () => {
  const routes = all_routes;
  const [otp, setOtp] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      setError("Token not found. Please sign up again.");
    }
  }, []);

  const handleVerifyOtp = async (e: any) => {
    e.preventDefault();
    try {
      const response = await verifyUser(otp, token);
      localStorage.setItem('tokenUP', response.data.payload.token);
      localStorage.removeItem('token');
      dispatch(setUserRole('user'));  // Set the user role to "user"
      setRedirectToDashboard(true);
    } catch (error: any) {
      // console.error('Error verifying OTP:', error.message);
      setError(error.message || 'Error verifying OTP. Please try again.');
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.customerProfile} />;
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap otp-wrap">
                <div className="otp-img">
                  <ImageWithBasePath src="assets/img/icons/email-otp.svg" alt="image" />
                </div>
                <div className="login-header">
                  <h3>Email OTP Verification</h3>
                  <p>
                    OTP sent to your Email Address ending{' '}
                    <span>******doe@example.com</span>
                  </p>
                </div>
                <form onSubmit={handleVerifyOtp}>
                  <div className="form-group">
                    <div className="d-flex passcode-wrap digit-group passcode-verified justify-content-center">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  {error && <div className="text-danger">{error}</div>}
                  <p className="no-acc">
                    Did not get the OTP? <Link to="#">Resend OTP</Link>
                  </p>
                  <button
                    className="btn btn-primary w-100 login-btn mb-0"
                    type="submit"
                  >
                    Verify &amp; Proceed
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailOtp;
