import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import { CurrentRoute } from '../../../core/models/interface';
import { all_routes } from '../../../core/data/routes/all_routes';
const FooterOne = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);
  const routes = all_routes;
  const language = [
    { name: 'English' },
    { name: 'France' },
    { name: 'Spanish' },
  ];
  const value = [{ name: 'US Dollars' }, { name: 'INR' }, { name: 'Kuwait' }];
  const current_route_array = useSelector(
    (state: CurrentRoute) => state.current_route_array,
  );

  return (
    <>
      {!current_route_array.includes('authentication') ? (
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget">
                    <div className="footer-logo mb-1">
                      <Link to={'/'}>
                        <ImageWithBasePath
                          className='footer-logo-image'
                          src="assets/img/logo.png"
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="footer-content">
                      <p>
                      Power Buddy is aware of the value of your time. Our aim is to deliver top-notch services directly to your home, entirely changing the way you manage your demands{' '}
                      </p>
                    </div>
                    {/* <div className="footer-selects">
                      <h2 className="footer-title">Language & Currency</h2>
                      <div className="row">
                        <div className="col-lg-12 d-flex">
                          <div className="footer-select">
                            <ImageWithBasePath
                              src="assets/img/icons/global.svg"
                              alt="img"
                            />
                            <Dropdown
                              value={selectedLanguage}
                              onChange={(e) => setSelectedLanguage(e.value)}
                              options={language}
                              optionLabel="name"
                              placeholder="English"
                              className="select footer-react-select"
                            />
                          </div>
                          <div className="footer-select">
                            <ImageWithBasePath
                              src="assets/img/icons/dropdown.svg"
                              className="footer-dropdown"
                              alt="img"
                            />

                            <Dropdown
                              value={selectedValue}
                              onChange={(e) => setselectedValue(e.value)}
                              options={value}
                              optionLabel="name"
                              placeholder="US Dollars"
                              className="select footer-react-select"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">Quick Links</h2>
                    <ul>
                      <li>
                        <Link to={routes.contactUs}>Contact us</Link>
                      </li>
                      {/* <li>
                        <Link to={routes.blogGrid}>Blogs</Link>
                      </li> */}
                      {/* <li>
                        <Link to={routes.contactUs}>Contact Us</Link>
                      </li> */}
                      {/* <li>
                        <Link to={routes.faq}>FAQ</Link>
                      </li> */}
                      {/* <li>
                        <Link to={routes.login}>Become a Professional</Link>
                      </li> */}
                      <li>
                        <Link to="/admin/login">Admin</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget footer-contact">
                    <h2 className="footer-title">Contact Us</h2>
                    <div className="footer-contact-info">
                      <div className="footer-address">
                        <p>
                          <span>
                            <Icon.MapPin className="react-feather-custom" />
                          </span>{' '}
                          39159, Paseo Padre Pkwy, Suite #119, Fremont, CA 94538
                        </p>
                      </div>
                      <p>
                        <span>
                          <Icon.Phone className="react-feather-custom" />
                        </span>{' '}
                        (510) 962-7900
                      </p>
                      <p className="mb-0">
                        <span>
                          <Icon.Mail className="react-feather-custom" />
                        </span>{' '}
                        hello@embtelsolutions.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <h2 className="footer-title">Follow Us</h2>
                    <div className="social-icon">
                      <ul>
                        <li>
                          <Link to={'/'} target="_blank">
                            <i className="fa-brands fa-facebook"></i>{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'} target="_blank">
                            <i className="fab fa-twitter"></i>{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'} target="_blank">
                            <i className="fa-brands fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'} target="_blank">
                            <i className="fa-brands fa-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <h2 className="footer-subtitle">Newsletter Signup</h2>
                    <div className="subscribe-form">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                      />
                      <button type="submit" className="btn footer-btn">
                        <Icon.Send className="react-feather-custom" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container">
              <div className="copyright">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="copyright-text">
                      <p className="mb-0">
                        Copyright &copy; 2024. All Rights Reserved.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/* <div className="payment-image">
                      <ul>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/visa.png"
                              alt="img"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/mastercard.png"
                              alt="img"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/stripe.png"
                              alt="img"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/discover.png"
                              alt="img"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="col-md-4">
                    <div className="copyright-menu">
                      <ul className="policy-menu">
                        <li>
                          <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to={routes.termsCondition}>
                            Terms & Conditions
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default FooterOne;
