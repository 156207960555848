import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { createBooking, fetchServiceList } from '../../../../core/data/api/api';
import 'react-calendar/dist/Calendar.css';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import { AppState} from '../../../../core/models/interface';
const Booking1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routes = all_routes;
  const { bookingServiceId } = location.state || {};
  const [value, setValue] = useState(new Date());
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [service, setService] = useState(null);
  const tokenBooking = useSelector((state: AppState) => state.user_token);

 
      // console.log('Token services Details:',tokenBooking);
   


  useEffect(() => {
    const getServiceList = async () => {
      try {
        const data = await fetchServiceList();
        const services = data.data.payload.data;
        const matchedService = services.find(
          (service) => service.sub_category_id === bookingServiceId,
        );

        if (matchedService) {
          setService(matchedService);
          // console.log('Matched Service:', matchedService);
        } else {
          console.log(
            'No matching service found for the provided sub_category_id',
          );
        }
      } catch (error) {
        console.error('Error fetching service list:', error);
      }
    };
    getServiceList();
  }, [bookingServiceId]);

  const handleBooking = async () => {
    setLoading(true);
    setError(null);
    try {
      const bookingDetails = {
        service_id: bookingServiceId,
        price:String(service.price),
        city,
        state,
        country,
        booking_date: value.toISOString().split('T')[0],
        appointment_time: appointmentTime,
      };
      // console.log('Booking successful:', bookingDetails);
      const response = await createBooking(tokenBooking, bookingDetails);
      // console.log('Booking successful:', response,tokenBooking);

      setCity('');
      setState('');
      setCountry('');
      setAppointmentTime('');
      setValue(new Date());
      navigate(routes.bookingDone);
    } catch (error) {
      setError('Failed to create booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      const today = new Date();
      return date < today.setHours(0, 0, 0, 0);
    }
    return false;
  };

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: 'short' });
  };

  const handleViewChange = ({ activeStartDate, view }) => {
    const today = new Date();
    if (view === 'month' && activeStartDate < today) {
      setValue(today);
    }
  };

  return (
    <div>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath src="assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Booking */}
            <div className="col-lg-10 mx-auto">
              {/* Booking Step */}
              <ul className="step-register row">
                <li className="active col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Done </h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>
              {/* /Booking Step */}
              {/* Appointment */}
              <div className="booking-service">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="service-book">
                      <div className="service-book-img">
                        <ImageWithBasePath src="assets/img/booking.jpg" alt="img" />
                      </div>
                      {service && (
                        <div className="serv-profile">
                          <span className="badge">{service.slug}</span>
                          <h2>{service.title}</h2>
                          <p className="mb-0" dangerouslySetInnerHTML={{ __html: service.description }} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-md-7 col-sm-6">
                        <div className="provide-box">
                          <span>
                            <Icon.Phone className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Phone</h6>
                            <p>+1 (510) 962-7900</p>
                          </div>
                        </div>
                        <div className="provide-box">
                          <span>
                            <Icon.Mail className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Email</h6>
                            <p>hello@embtelsolutions.com</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-sm-6">
                        <div className="provide-box">
                          <span>
                            <Icon.MapPin className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Address</h6>
                            <p>39159, Paseo Padre Pkwy, Suite #119, Fremont, CA 94538</p>
                          </div>
                        </div>
                        <div className="provide-box">
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/service-icon.svg"
                              alt="img"
                            />
                          </span>
                          {service && (
                            <div className="provide-info">
                              <h6>Service Amount</h6>
                              <h5>${service.price}</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book-form">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter City"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">State</label>
                      <input
                        type="text"
                        className="form-control"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Enter State"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Enter Country"
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Appointment */}
              {/* Appointment Date & Time */}
               {/* Appointment Date & Time */}
               <div className="row">
                <div className="col-lg-4">
                  <div className="book-title">
                    <h5>Appointment Date</h5>
                  </div>
                  <div id="datetimepickershow">
                    <Calendar
                      onChange={setValue}
                      value={value}
                      minDate={new Date()}
                      tileDisabled={tileDisabled}
                      formatShortWeekday={formatShortWeekday}
                      onActiveStartDateChange={handleViewChange}
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="book-title">
                        <h5>Appointment Time</h5>
                      </div>
                    </div>
                  </div>
                  <div className="token-slot mt-2">
                    {[
  '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
  '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30',
  '21:00', '21:30', '22:00', '22:30'
].map((time) => (
                      <div className="form-check-inline visits me-0" key={time}>
                        <label className="visit-btns">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="appointment"
                            value={time}
                            onChange={(e) => setAppointmentTime(e.target.value)}
                            disabled={loading}
                          />
                          <span className="visit-rsn">{time}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="book-submit text-end">
                    <Link to={routes.homeOne} className="btn btn-secondary" disabled={loading}>
                      Cancel
                    </Link>
                    <button onClick={handleBooking} className="btn btn-primary" disabled={loading}>
                      {loading ? 'Loading...' : 'Book Appointment'}
                    </button>
                  </div>
                  {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
              </div>
              {/* /Appointment Date & Time */}
              {/* /Appointment Date & Time */}
              {/* <div className="submit-section text-center">
                <button
                  className="btn btn-primary submit-btn"
                  onClick={handleBooking}
                  disabled={loading}
                >
                  {loading ? 'Booking...' : 'Book Now'}
                </button>
                {error && <div className="text-danger mt-2">{error}</div>}
              </div> */}
            </div>
            {/* /Booking */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking1;
