import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FooterOne from '../../home/footer-one';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../home/home-header';

const BookingDetails = () => {
  const routes = all_routes;

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
    });
  }, []);
  return (
    <>
      <HomeHeader type={1} />
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Booking Details</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Booking Details
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content">
          <div className="container">
            <div className="row">
              {/* Orders */}
              <div className="col-lg-10 mx-auto">
                {/* Breadcrumb */}
                <div className="page-topbar">
                  <div className="breadcrumb">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={routes.homeOne}>Home</Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Orders
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          ID 2378910
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                {/* /Breadcrumb */}
                {/* Bookeing Id */}
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="book-id">
                      <h4>Booking ID: 2378910</h4>
                      <p>
                        <Icon.Calendar className="react-feather-custom" /> 22
                        Sep 2023 10:23 AM
                      </p>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="book-id-action">
                      <Link to="#" className="btn btn-secondary">
                        <i className="fa-solid fa-location-crosshairs me-2" />
                        Live Track
                      </Link>
                      <Link to={routes.invoice} className="btn btn-secondary">
                        <Icon.Clipboard className="react-feather-custom me-2" />
                        Invoice
                      </Link>
                      <Link to="#" className="btn btn-secondary">
                        <Icon.Printer className="react-feather-custom me-2" />
                        Print
                      </Link>
                    </div>
                  </div>
                </div>
                {/* /Bookeing Id */}
                {/* Slot Booked */}
                <div className="slot-box">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="slot-booked">
                        <h6>Booked Slot</h6>
                        <ul>
                          <li>
                            <Icon.Calendar className="react-feather-custom" />{' '}
                            22 Sep 2023
                          </li>
                          <li>
                            <Icon.Clock className="react-feather-custom" />{' '}
                            10:00AM - 11:00AM
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="slot-user">
                        <h6>Booked Slot</h6>
                        <div className="slot-chat">
                          <div className="slot-user-img">
                            <ImageWithBasePath
                              className="avatar"
                              src="assets/img/profiles/avatar-02.jpg"
                              alt="image"
                            />
                            <div className="slot-user-info">
                              <p>John Doe</p>
                              <p>john@example.com</p>
                            </div>
                          </div>
                          <div className="chat-item">
                            <div className="slot-user-info">
                              <p>+1 888 888 8888</p>
                              <p>Montana, USA</p>
                            </div>
                            <div>
                              <Link
                                to={routes.customerChat}
                                className="btn btn-primary"
                              >
                                <ImageWithBasePath
                                  className="me-1"
                                  src="assets/img/icons/message.svg"
                                  alt="image"
                                />
                                Chat
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="slot-action">
                        <h6>Booking Status</h6>
                        <Link to="#" className="btn btn-light-success">
                          Completed
                        </Link>
                        <Link to="#" className="btn btn-light-warning">
                          Pending
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Slot Booked */}
                <div className="payment-summary">
                  <div className="row">
                    {/* Service Location */}
                    <div className="col-md-6">
                      <h6 className="order-title">
                        Service Location &amp; Contact Details
                      </h6>
                      <div className="slot-address">
                        <ul>
                          <li>
                            <span>
                              <Icon.MapPin className="react-feather-custom" />
                            </span>
                            <div>
                              <h6>Address</h6>
                              <p>38 Taylor Street Mount Vernon, NY 10550</p>
                            </div>
                          </li>
                          <li>
                            <span>
                              <Icon.Phone className="react-feather-custom" />
                            </span>
                            <div>
                              <h6>Email</h6>
                              <p>johnsmith@example.com</p>
                            </div>
                          </li>
                          <li>
                            <span>
                              <Icon.Phone className="react-feather-custom" />
                            </span>
                            <div>
                              <h6>Phone</h6>
                              <p>+1 888 888 8888</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="slot-pay">
                        <p>
                          <span className="slot-title">Payment</span> Visa ****
                          **** **** **56
                          <span className="slot-img">
                            <ImageWithBasePath
                              src="assets/img/icons/visa.svg"
                              alt="image"
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* /Service Location */}
                    {/* Order Summary */}
                    <div className="col-md-6">
                      <h6 className="order-title">Order Summary</h6>
                      <div className="ord-summary">
                        <div className="order-amt">
                          <div className="order-info">
                            <div className="order-img">
                              <ImageWithBasePath
                                src="assets/img/booking.jpg"
                                alt="img"
                              />
                            </div>
                            <div className="order-profile">
                              <h6>Computer Services</h6>
                              <p>Newyork, USA</p>
                            </div>
                          </div>
                          <h5>$599.00</h5>
                        </div>
                        <ul>
                          <li>
                            Sub Total <span className="ord-amt">$257.00</span>
                          </li>
                          <li>
                            Discount<span className="ord-code">NEW2023</span>{' '}
                            <span className="ord-amt">-$11.00</span>
                          </li>
                          <li>
                            Tax @ 12.5% <span className="ord-amt">$5.36</span>
                          </li>
                          <li className="ord-total">
                            Total <span className="ord-amt">$251.36</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /Order Summary */}
                  </div>
                </div>
                <div className="row">
                  {/* Booking History */}
                  <div className="col-md-6">
                    <h6 className="order-title">Booking History</h6>
                    <div className="book-history">
                      <ul>
                        <li>
                          <h6>Booking</h6>
                          <p>
                            <i className="fa-solid fa-calendar-days me-1" />{' '}
                            September 5, 2023
                          </p>
                        </li>
                        <li>
                          <h6>Provider Accept</h6>
                          <p>
                            <i className="fa-solid fa-calendar-days me-1" />{' '}
                            September 5, 2023
                          </p>
                        </li>
                        <li>
                          <h6>Completed on</h6>
                          <p>
                            <i className="fa-solid fa-calendar-days me-1" />{' '}
                            September 5, 2023
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Booking History */}
                  {/* Reviews */}
                  <div className="col-md-6">
                    <div className="order-reviews">
                      <div className="row align-items-center">
                        <div className="col-5">
                          <h6 className="order-title">Reviews</h6>
                        </div>
                        <div className="col-7 text-end">
                          <Link
                            to="#"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#add-review"
                          >
                            <Icon.PlusCircle className="react-feather-custom me-2" />
                            Add Review
                          </Link>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <div className="order-comment">
                            <div className="rating">
                              <i className="fas fa-star filled me-1" />
                              <i className="fas fa-star filled me-1" />
                              <i className="fas fa-star filled me-1" />
                              <i className="fas fa-star filled me-1" />
                              <i className="fas fa-star filled" />
                            </div>
                            <h6>A wonderful experience was all the help...</h6>
                            <p>
                              <i className="fa-solid fa-calendar-days me-1" />{' '}
                              September 5, 2023
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Reviews */}
                </div>
              </div>
              {/* /Orders */}
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default BookingDetails;
