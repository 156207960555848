import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';

import { Dropdown } from 'primereact/dropdown';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import DefaultEditor from 'react-simple-wysiwyg';
import { all_routes } from '../../../../core/data/routes/all_routes';
const routes = all_routes;

const CreateService = () => {
  dayjs.extend(customParseFormat);

  const onChange = (time: any, timeString: any) => {
    console.log(time, timeString);
  };
  const [values] = React.useState();
  const [addList, setAddList] = useState(['']);
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedSub, setselectedSub] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const handelAdd = () => {
    setAddList([...addList, ' ']);
  };

  const handelRemove = (index: any) => {
    const list = [...addList];
    list.splice(index, 1);
    setAddList(list);
  };
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const category = [
    { name: 'Select Category' },
    { name: 'Car wash' },
    { name: 'House Cleaning' },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const [images, setImages] = useState([
    'assets/img/services/service-01.jpg',
    'assets/img/services/service-02.jpg',
    'assets/img/services/service-03.jpg',
    'assets/img/services/service-04.jpg',
  ]);
  const deleteImage = (index: any) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };
  const handleTimeChange = (id: any, key: any, value: any) => {
    const updatedWorkingHours = workingHours.map((hour) =>
      hour.id === id ? { ...hour, [key]: value } : hour,
    );
    setWorkingHours(updatedWorkingHours);
  };
  const addNewWorkingHourRow = () => {
    const newId = workingHours.length + 1;
    setWorkingHours([
      ...workingHours,
      {
        id: newId,
        from: dayjs('00:00:00', 'HH:mm:ss'),
        to: dayjs('00:00:00', 'HH:mm:ss'),
        slots: '',
      },
    ]);
  };
  const [workingHours, setWorkingHours] = useState([
    {
      id: 1,
      from: dayjs('00:00:00', 'HH:mm:ss'),
      to: dayjs('00:00:00', 'HH:mm:ss'),
      slots: '',
    },
  ]);

  const handleInputChange = (id: any, event: any) => {
    const { name, value } = event.target;
    const updatedWorkingHours = workingHours.map((hour) =>
      hour.id === id ? { ...hour, [name]: value } : hour,
    );
    setWorkingHours(updatedWorkingHours);
  };
  return (
    <>
      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Create Service</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create Service
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
      </>

      <div className="page-wrapper">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                {/* Service List */}
                <div className="service-wizard">
                  <ul id="progressbar">
                    <li
                      className={
                        currentStep === 1
                          ? 'active'
                          : currentStep > 1
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon span-info">
                        <span>
                          <i className="fa-regular fa-circle-check" />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Information</h6>
                      </div>
                    </li>
                    <li
                      className={
                        currentStep === 2
                          ? 'active'
                          : currentStep > 2
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon">
                        <span>
                          <i className="fa-regular fa-clock" />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Availability</h6>
                      </div>
                    </li>
                    <li
                      className={
                        currentStep === 3
                          ? 'active'
                          : currentStep > 3
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon">
                        <span>
                          <i className="fa-regular fa-map" />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Location</h6>
                      </div>
                    </li>
                    <li
                      className={
                        currentStep === 4
                          ? 'active'
                          : currentStep > 4
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon">
                        <span>
                          <Icon.Image />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Gallery</h6>
                      </div>
                    </li>
                    <li
                      className={
                        currentStep === 5
                          ? 'active'
                          : currentStep > 5
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon">
                        <span>
                          <i className="fa-solid fa-chart-bar" />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Seo</h6>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* /Service List */}
                <div className="service-inform-fieldset">
                  {/* Service Information */}
                  {currentStep === 1 && (
                    <fieldset id="first-field">
                      <div className="card add-service">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sub-title">
                              <h6>Service Information</h6>
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                Service Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Service Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">Category</label>
                              <div>
                                <Dropdown
                                  value={selectedLanguage}
                                  onChange={(e) => setselectedLanguage(e.value)}
                                  options={category}
                                  optionLabel="name"
                                  placeholder="Select Category"
                                  className="select service-select"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                Sub Category
                              </label>
                              <div>
                                <Dropdown
                                  value={selectedSub}
                                  onChange={(e) => setselectedSub(e.value)}
                                  options={category}
                                  optionLabel="name"
                                  placeholder="Select Sub Category"
                                  className="select service-select"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label d-block">
                                Price
                                <span className="brief-bio float-end">
                                  Set 0 for free
                                </span>
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label d-block">
                                Duration
                              </label>
                              <div className="form-duration">
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="col-form-label">
                                Description
                              </label>
                              <DefaultEditor
                                value={values}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card add-service">
                        <div className="add-service-toggle">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="sub-title">
                                <h6>Additional Service</h6>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="status-toggle sml-status float-sm-end">
                                <input
                                  type="checkbox"
                                  id="status_1"
                                  className="check"
                                  checked={true}
                                />
                                <label
                                  htmlFor="status_1"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="addservice-info">
                          {addList.map((add: any, index: any) => (
                            <div className="row service-cont" key={index}>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="col-form-label">Item</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter  Service Item"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="col-form-label">
                                    Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Services Price"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex">
                                  <div className="form-group w-100">
                                    <label className="col-form-label">
                                      Duration
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Service Duration"
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-expect-error
                                    onClick={() => handelRemove()}
                                  >
                                    <label className="col-form-label">
                                      &nbsp;
                                    </label>
                                    <Link
                                      to="#"
                                      className="btn btn-danger-outline trash"
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Link
                          to="#"
                          className="add-text add-extra"
                          onClick={handelAdd}
                        >
                          <i className="feather-plus-circle" /> Add Additional
                          Service
                        </Link>
                      </div>
                      <div className="card add-service">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sub-title">
                              <h6>Video</h6>
                            </div>
                            <div className="form-group mb-0">
                              <label className="col-form-label">
                                Video Link
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="https://www.youtube.com/shorts/Lf-Z7H8bZ8o"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom-btns">
                            {/* <div class="field-btns">
														<button class="btn btn-primary prev_btn" type="button"><i class="fa-solid fa-arrow-left"></i>Prev</button>
													</div>  */}
                            <div className="field-btns">
                              <button
                                className="btn btn-primary next_btn"
                                type="button"
                                onClick={handleNext}
                              >
                                Next <i className="fa-solid fa-arrow-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* /Service Information */}
                  {/* Availability */}
                  {currentStep === 2 && (
                    <fieldset style={{ display: 'block' }}>
                      <div className="card add-service">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sub-title">
                              <h6>Availability</h6>
                            </div>
                          </div>
                          <div className="col-md-12">
                            {/* Timeslot */}
                            <div className="timeslot-sec availablt-time-slots">
                              {/* Schedule Nav */}
                              <label className="col-form-label">
                                Configure Time Slots
                              </label>
                              <div className="schedule-nav">
                                <ul className="nav">
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link active"
                                      data-bs-toggle="tab"
                                      to="#all_days"
                                    >
                                      All Days
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_monday"
                                    >
                                      Monday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_tuesday"
                                    >
                                      Tuesday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_wednesday"
                                    >
                                      Wednesday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_thursday"
                                    >
                                      Thursday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_friday"
                                    >
                                      Friday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_saturday"
                                    >
                                      Saturday
                                    </Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      to="#slot_sunday"
                                    >
                                      Sunday
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              {/* /Schedule Nav */}
                              <div className="tab-content pt-0">
                                <div className="tab-pane active" id="all_days">
                                  <div className="hours-info">
                                    <h4 className="nameof-day">All Days</h4>
                                    {workingHours.map((hour) => (
                                      <div
                                        key={hour.id}
                                        className="row hours-cont"
                                      >
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <label>From</label>
                                            <div className="form-availability-field">
                                              <TimePicker
                                                className="form-control timepicker"
                                                onChange={(value) =>
                                                  handleTimeChange(
                                                    hour.id,
                                                    'from',
                                                    value,
                                                  )
                                                }
                                                defaultValue={hour.from}
                                                format="h:mm A"
                                              />
                                              <span className="cus-icon">
                                                <i className="fe fe-clock" />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <label>To</label>
                                            <div className="form-availability-field">
                                              <TimePicker
                                                className="form-control timepicker"
                                                onChange={(value) =>
                                                  handleTimeChange(
                                                    hour.id,
                                                    'to',
                                                    value,
                                                  )
                                                }
                                                defaultValue={hour.to}
                                                format="h:mm A"
                                              />
                                              <span className="cus-icon">
                                                <i className="fe fe-clock" />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label>Slots</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="slots"
                                              value={hour.slots}
                                              onChange={(event) =>
                                                handleInputChange(
                                                  hour.id,
                                                  event,
                                                )
                                              }
                                              placeholder="Enter Slot"
                                            />
                                          </div>
                                        </div>
                                        {hour.id > 1 && (
                                          <div className="col-md-1">
                                            <button
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                              onClick={() =>deleteWorkingHourRow(hour.id)}
                                              
                                              className="btn btn-danger-outline delete-icon"
                                            >
                                              <Icon.Trash2 className="react-feather-custom trashicon" />{' '}
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                  <Link
                                    to="#"
                                    className="add-text add-hours"
                                    onClick={addNewWorkingHourRow}
                                  >
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div className="tab-pane fade" id="slot_monday">
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Monday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="slot_tuesday"
                                >
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Tuesday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="slot_wednesday"
                                >
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Wednesday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="slot_thursday"
                                >
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Thursday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div className="tab-pane fade" id="slot_friday">
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Friday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="slot_saturday"
                                >
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Saturday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                                <div className="tab-pane fade" id="slot_sunday">
                                  <div className="hours-info">
                                    <h4 className="nameof-day">Sunday</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group ">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to="#" className="add-text add-hours">
                                    <i className="feather-plus-circle" /> Add
                                    More
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* Timeslot */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom-btns">
                            <div className="field-btns">
                              <button
                                className="btn btn-primary prev_btn"
                                type="button"
                                onClick={handlePrev}
                              >
                                <i className="fa-solid fa-arrow-left" />
                                Prev
                              </button>
                            </div>
                            <div className="field-btns">
                              <button
                                className="btn btn-primary next_btn"
                                type="button"
                                onClick={handleNext}
                              >
                                Next <i className="fa-solid fa-arrow-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* /Availability */}
                  {/* Location */}
                  {currentStep === 3 && (
                    <fieldset style={{ display: 'block' }}>
                      <div className="card add-service">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sub-title">
                              <h6>Location</h6>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="col-form-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Address"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">Country</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Country"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your City"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">State</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your State"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">Pincode</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Pincode"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="col-form-label">
                                Google Maps Place ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Maps Place ID"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">Latitude</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Latitude Number"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                Longitude
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Longitude Number"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <div className="map-grid">
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-expect-error
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex={0}
                                className="contact-map"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom-btns">
                            <div className="field-btns">
                              <button
                                className="btn btn-primary prev_btn"
                                type="button"
                                onClick={handlePrev}
                              >
                                <i className="fa-solid fa-arrow-left" />
                                Prev
                              </button>
                            </div>
                            <div className="field-btns">
                              <button
                                className="btn btn-primary next_btn"
                                type="button"
                                onClick={handleNext}
                              >
                                Next <i className="fa-solid fa-arrow-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* /Location */}
                  {/* Gallery */}
                  {currentStep === 4 && (
                    <fieldset style={{ display: 'block' }}>
                      <div className="card add-service">
                        <div className="sub-title">
                          <h6>Gallery</h6>
                        </div>
                        <div className="file-upload">
                          <ImageWithBasePath
                            src="assets/img/icons/upload-icon.svg"
                            alt="image"
                          />
                          <h6>
                            Drag &amp; drop files or <span>Browse</span>
                          </h6>
                          <p>Supported formates: JPEG, PNG</p>
                          <input type="file" accept="video/image" />
                        </div>
                        <div className="file-preview">
                          <label>Select Default Image</label>
                          <ul className="gallery-selected-img">
                            {images.map((image, index) => (
                              <li key={index}>
                                <div className="img-preview">
                                  <ImageWithBasePath
                                    src={image}
                                    alt={`Service Image ${index + 1}`}
                                  />
                                  <Link
                                    to="#"
                                    className="remove-gallery"
                                    onClick={() => deleteImage(index)}
                                  >
                                    <Icon.Trash2
                                      style={{ width: '16px', height: '16px' }}
                                    />
                                  </Link>
                                </div>
                                <label className="custom_check">
                                  <input
                                    type="radio"
                                    name="gallery"
                                    defaultChecked={index === 0}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="field-bottom-btns">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btn"
                            type="button"
                            onClick={handlePrev}
                          >
                            <i className="fa-solid fa-arrow-left" />
                            Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <button
                            className="btn btn-primary next_btn"
                            type="button"
                            onClick={handleNext}
                          >
                            Next <i className="fa-solid fa-arrow-right" />
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* /Gallery */}
                  {/* Seo */}
                  {currentStep === 5 && (
                    <fieldset style={{ display: 'block' }}>
                      <div className="card add-service">
                        <div className="sub-title">
                          <h6>SEO</h6>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="col-form-label">
                                Meta Title
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="col-form-label">
                                Meta Keywords
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="col-form-label">
                                Meta Description
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-bottom-btns">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btn"
                            type="button"
                            onClick={handlePrev}
                          >
                            <i className="fa-solid fa-arrow-left" />
                            Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <Link
                            to={routes.providerService}
                            className="btn btn-primary done_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#successmodal"
                          >
                            Next
                            <i className="fa-solid fa-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/* /Seo */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* Modal Succss */}
        <div
          className="modal fade add-service-modal"
          id="successmodal"
          tabIndex={-1}
          aria-labelledby="successmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span>
                  <i className="fa-regular fa-circle-check" />
                </span>
                <h3>Success</h3>
                <p>Service has been created succeessfully</p>
                <div className="popup-btn">
                  <a href={routes.providerService} className="btn btn-primary">
                    Go to Dashboard <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Succss */}
      </>
    </>
  );
};

export default CreateService;
