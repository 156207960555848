export const walletHistory = [
  {
    id: 1,
    type: 'Wallet Topup',
    amount: '+$80',
    date: '07 Oct 2023 11:22:51',
    paymenttype: 'Paypal',
    status: 'Completed',
  },
  {
    id: 2,
    type: 'Purchase',
    amount: '-$20',
    date: '06 Oct 2023 11:22:51',
    paymenttype: 'Paypal',
    status: 'Completed',
  },
  {
    id: 3,
    type: 'Wallet Topup',
    amount: '+$80',
    date: '07 Oct 2023 11:22:51',
    paymenttype: 'Paypal',
    status: 'Completed',
  },
  {
    id: 4,
    type: 'Purchase',
    amount: '-$20',
    date: '06 Oct 2023 11:22:51',
    paymenttype: 'Paypal',
    status: 'Completed',
  },
];
