import React from 'react'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom'
import { all_routes } from '../../../../core/data/routes/all_routes';

const ProvidersList = () => {
  const routes = all_routes;
  return (
    <div>
    {/* Breadcrumb */}
    <div className="breadcrumb-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="breadcrumb-title mb-0">Providers</h2>
          </div>
        </div>
      </div>
    </div>
    {/* /Breadcrumb */}	
    {/* Content */}
    <div className="content">
      <div className="container">				
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-11.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>John Smith</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Electrician</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$20.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(320)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-12.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Michael</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Carpenter</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$50.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(228)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-13.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Antoinette</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Cleaner</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$40.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(130)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-14.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Thompson</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Mechanic</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$70.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(95)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-15.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Gloria</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Cleaner</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$30.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(320)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-16.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Lawrence</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Engineer</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$45.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(228)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-17.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Ellen</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Designer</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$25.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(130)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-18.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Nathaniel</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Plumber</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$30.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(95)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-19.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Nicholas</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Electrician</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$20.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(320)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-20.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Stephanie</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Carpenter</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$40.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(228)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-21.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>Charles</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Serviceman</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$55.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(130)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="providerset">
              <div className="providerset-img">
                <Link to={routes.providerDetails}>
                  <ImageWithBasePath src="assets/img/provider/provider-22.jpg" alt="img" />
                </Link>
              </div>
              <div className="providerset-content">
                <div className="providerset-price">
                  <div className="providerset-name">
                    <h4><Link to={routes.providerDetails}>George</Link><i className="fa fa-check-circle" aria-hidden="true" /></h4>
                    <span>Mechanic</span>
                  </div>
                  <div className="providerset-prices">
                    <h6>$70.00<span>/hr</span></h6>
                  </div>
                </div>
                <div className="provider-rating">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" /><span>(95)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">						
            <div className="blog-pagination">
              <nav>
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled">
                    <Link className="page-link page-prev" to="#" tabIndex={-1}><i className="fa-solid fa-arrow-left me-2" /> PREV</Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">1</Link>
                  </li>
                  <li className="page-item">s
                    <Link className="page-link" to="#">2</Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">3</Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link page-next" to="#">NEXT <i className="fa-solid fa-arrow-right ms-2" /></Link>
                  </li>
                </ul>
              </nav>
            </div>					
          </div>
        </div>
      </div>			
    </div>
    {/* /Content */}
  </div>
  )
}

export default ProvidersList