import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const BlogList = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Our Blog</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Blog
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      List
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* Blog Post */}
                <div className="blog blog-list">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-04.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <span className="cat-blog">Car Wash</span>
                        </li>
                        <li>
                          <Icon.Calendar
                            className="react-feather-custom me-1 mb-1"
                            color="#74788d"
                          />
                          28 Sep 2023
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua veniam,
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                      quia consequuntur magni dolores eos qui ratione voluptatem
                      sequi nesciunt.
                    </p>
                    <div className="blog-info">
                      <ul>
                        <li>
                          <div className="post-author">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="Post Author"
                              />
                              <span>Admin</span>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.ThumbsUp color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            25 Likes
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.MessageCircle color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            20 Comments
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Blog Post */}
                {/* Blog Post */}
                <div className="blog blog-list">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-07.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <span className="cat-blog">Construction</span>
                        </li>
                        <li>
                          <Icon.Calendar
                            className="react-feather-custom me-1 mb-1"
                            color="#74788d"
                          />
                          28 Sep 2023
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua veniam,
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                      quia consequuntur magni dolores eos qui ratione voluptatem
                      sequi nesciunt.
                    </p>
                    <div className="blog-info">
                      <ul>
                        <li>
                          <div className="post-author">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="Post Author"
                              />
                              <span>Admin</span>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.ThumbsUp color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            25 Likes
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.MessageCircle color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            20 Comments
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Blog Post */}
                {/* Blog Post */}
                <div className="blog blog-list">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-06.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <span className="cat-blog">Cleaning</span>
                        </li>
                        <li>
                          <Icon.Calendar
                            className="react-feather-custom me-1"
                            color="#74788d"
                          />
                          28 Sep 2023
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua veniam,
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                      quia consequuntur magni dolores eos qui ratione voluptatem
                      sequi nesciunt.
                    </p>
                    <div className="blog-info">
                      <ul>
                        <li>
                          <div className="post-author">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="Post Author"
                              />
                              <span>Admin</span>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.ThumbsUp color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            25 Likes
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.MessageCircle color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            20 Comments
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Blog Post */}
                {/* Blog Post */}
                <div className="blog blog-list">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-08.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <span className="cat-blog">Carpentry</span>
                        </li>
                        <li>
                          <Icon.Calendar
                            className="react-feather-custom me-1"
                            color="#74788d"
                          />
                          28 Sep 2023
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua veniam,
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa aspernatur aut odit aut fugit, sed
                      quia consequuntur magni dolores eos qui ratione voluptatem
                      sequi nesciunt.
                    </p>
                    <div className="blog-info">
                      <ul>
                        <li>
                          <div className="post-author">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="Post Author"
                              />
                              <span>Admin</span>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.ThumbsUp color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            35 Likes
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.MessageCircle color='#FF6700' className="react-feather-custom me-2 mb-1" />
                            10 Comments
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Blog Post */}
                <div className="blog-pagination">
                  <nav>
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <Link
                          className="page-link page-prev"
                          to="#"
                          tabIndex={-1}
                        >
                          <i className="fa-solid fa-arrow-left me-1" /> PREV
                        </Link>
                      </li>
                      <li className="page-item active">
                        <Link className="page-link" to="#">
                          1
                        </Link>
                      </li>
                      {/* <li className="page-item">
                        <Link className="page-link" to="#">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          3
                        </Link>
                      </li> */}
                      <li className="page-item">
                        <Link className="page-link page-next" to="#">
                          NEXT <i className="fa-solid fa-arrow-right ms-1" />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
