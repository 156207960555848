export const ProviderOffer = [
  {
    img: 'assets/img/services/service-06.jpg',
    service: 'Computer Repair',
    amount: '$80',
    '#': '1',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-04.jpg',
    service: 'Car Repair Service',
    amount: '$80',
    '#': '2',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-08.jpg',
    service: 'Steam Car Wash',
    amount: '$80',
    '#': '3',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-09.jpg',
    service: 'House Cleaning',
    amount: '$80',
    '#': '4',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-02.jpg',
    service: 'Building Construction',
    amount: '$80',
    '#': '5',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-06.jpg',
    service: 'Interior Designing',
    amount: '$80',
   "#": '6',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-06.jpg',
    service: 'Commercial Painting ',
    amount: '$80',
    "#": '7',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
  {
    img: 'assets/img/services/service-11.jpg',
    service: 'Plumbing Services ',
    amount: '$80',
    "#": '8',
    offer: '$80',
    offerprice: '$20',
    date: '28 Sep 2023',
    action: 'Chennai / India',
    enddate: '28 Sep 2023',
  },
];
