import React, { useState } from 'react';
import * as Icon from 'react-feather';
// import PagesAuthHeader from '../common/header';
import { Link, Navigate } from 'react-router-dom'; // Import Redirect
import { all_routes } from '../../../core/data/routes/all_routes';
import { adminLogin } from '../../../core/data/api/api';

const AdminLogin = () => {
  const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [redirectToDashboard, setRedirectToDashboard] = useState(false); 

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e:any) => {
    e.preventDefault();
    try {
      const data = await adminLogin(email, password);
      localStorage.setItem('AdminToken', data.data.payload.token);
      // console.log('Login successful:', data.data.payload.token);
      setEmail('');
      setPassword('');
      setRedirectToDashboard(true); 
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  
  if (redirectToDashboard) {
    return <Navigate to={routes.dashboard} />;
  }

  return (
    <>
      {/* <PagesAuthHeader /> */}
      <div className="content ">
        <div className="container ">
          <div className="row mt-5">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Admin Login</h3>
                  <p>Please enter your details</p>
                  {/* <h6>
                    Sign in with{' '}
                    <Link to={routes.loginphone1}>Phone Number</Link>
                  </h6> */}
                </div>
                <form onSubmit={handleLogin}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="johndoe@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                      />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">Password</label>
                        </div>
                        <div className="col-auto">
                          <Link
                            className="forgot-link"
                            to={routes.passwordRecovery}
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="pass-group">
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? (
                            <Icon.EyeOff className="react-feather-custom" />
                          ) : (
                            <Icon.Eye className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Login
                  </button>
                  {/* <p className="no-acc">
                    Dont have an account{' '}
                    <Link to={routes.chooseSignUp}>Register</Link>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
