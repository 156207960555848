import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import PhoneInput from 'react-phone-input-2';
import ProviderAuthHeader from './common/header1';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { buddyRegister } from '../../../../core/data/api/api'; 

const ProviderSignup = () => {
  const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const handleOnChange = (value: any, country: any) => {
    setPhone(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      setErrorMessage('');
      return;
    }

    setPasswordMatchError(false);
    
    try {
      const data = await buddyRegister(name, email, password, confirmPassword);
      localStorage.setItem('token', data.data.payload.token);
      setName('');
      setEmail('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');
      setSuccessMessage('Registration successful!');
      setErrorMessage('');
      setRedirectToDashboard(true);
    } catch (error) {
      setErrorMessage('Registration failed. Please try again.');
      setSuccessMessage('');
      console.error('Registration failed:', error);
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.emailOtpProvider} />;
  }

  return (
    <>
      <ProviderAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Provider Signup</h3>
                </div>
                {/* Signup Form */}
                <form onSubmit={handleSignup}>
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="johndoe@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="col-form-label">Phone Number</label>
                      <div className="form-group">
                        <PhoneInput
                          country={'us'}
                          value={phone}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Password
                      <span className="brief-bio float-end">
                        Must be 8 Characters at Least
                      </span>
                    </label>
                    <div className="pass-group">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        placeholder="*************"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <Icon.Eye className="react-feather-custom" />
                        ) : (
                          <Icon.EyeOff className="react-feather-custom" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="*************"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                     {passwordMatchError && (
                      <div className="text-danger">Passwords do not match</div>
                    )}
                  </div>
                  {successMessage && (
                    <div className="alert alert-success">
                      {successMessage}
                    </div>
                  )}
                  {errorMessage && (
                    <div className="alert alert-danger">
                      {errorMessage}
                    </div>
                  )}
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Signup
                  </button>
                  <p className="no-acc">
                    Already have an account?{' '}
                    <Link to={routes.login}>Sign In</Link>
                  </p>
                </form>
                {/* /Signup Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderSignup;
