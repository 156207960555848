import React, { useState } from 'react';
import { useLocation,Navigate } from 'react-router-dom';
import PagesAuthHeader from './common/header';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';
import { setNewPassword } from '../../../../core/data/api/api';

const ResetPassword = () => {
  const routes = all_routes;
  const location = useLocation();
  const email = location.state?.email || '';
  const [passwords, setPasswords] = useState(['', '']);
  const [otp, setOtp] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const [password, setPassword] = useState('');
  const [passwordResponce, setPasswordResponce] = useState({
    passwordResponceText: '',
    passwordResponceKey: '',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  
  const togglePasswordVisibility = (index:any) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  const onChangePassword = (password:any) => {
    setPassword(password);
    if (password.match(/^$|\s+/)) {
      setPasswordResponce({
        passwordResponceText: 'Whitespaces are not allowed',
        passwordResponceKey: '',
      });
    } else if (password.length === 0) {
      setPasswordResponce({
        passwordResponceText: '',
        passwordResponceKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponce({
        passwordResponceText: 'Weak. Must contain at least 8 characters',
        passwordResponceKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponce({
        passwordResponceText:
          'Average. Must contain at least 1 upper case and number',
        passwordResponceKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponce({
        passwordResponceText: 'Almost. Must contain a special symbol',
        passwordResponceKey: '2',
      });
    } else {
      setPasswordResponce({
        passwordResponceText: 'Awesome! You have a secure password.',
        passwordResponceKey: '3',
      });
    }
  };

  const handleResetPassword = async (e:any) => {
    e.preventDefault();
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE2YjBkZjUxLTljMmEtNGU1YS1hYjM0LTMzN2JlODBhYjMwZCIsIm5hbWUiOiJUcnVwYWwiLCJlbWFpbCI6InRydXBhbEBnbWFpbC5jb20iLCJ1c2VyX3R5cGUiOiJVU0VSIiwiaWF0IjoxNzE1Nzg5NTE0LCJleHAiOjE3MTU3OTA0MTR9.rVM8UlISG9BoaafxQf8VPVfaCFYJUX8iWUnlBb7FFHg'; // Replace with actual token
    try {
      const response = await setNewPassword(email, otp, password, passwords[1], token);
      console.log("Password reset successful", response);
      setMessage('Your password has been reset successfully.');
      setError('');
      setRedirectToDashboard(true);
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      setMessage('');
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.login}/>;
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <p>
                    Your new password must be different from previously used passwords.
                  </p>
                </div>
                {/* Reset Password Form */}
                <form onSubmit={handleResetPassword}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">
                        Email OTP Verification
                      </label>
                      <div className="d-flex passcode-wrap digit-group passcode-verified mt-2 mb-3 justify-content-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>

                      <label className="col-form-label">New Password</label>
                      <div className="pass-group" id="passwordInput">
                        <input
                          type={passwordVisibility[0] ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => onChangePassword(e.target.value)}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(0)}
                        >
                          {passwordVisibility[0] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                      <div
                        className={`password-strength ${
                          passwordResponce.passwordResponceKey === '0'
                            ? 'poor-active'
                            : passwordResponce.passwordResponceKey === '1'
                              ? 'avg-active'
                              : passwordResponce.passwordResponceKey === '2'
                                ? 'strong-active'
                                : passwordResponce.passwordResponceKey === '3'
                                  ? 'heavy-active'
                                  : ''
                        }`}
                        id="passwordStrength"
                      >
                        <span id="poor" className="active"></span>
                        <span id="weak" className="active"></span>
                        <span id="strong" className="active"></span>
                        <span id="heavy" className="active"></span>
                      </div>
                      <div
                        id="passwordInfo"
                        className={
                          passwordResponce.passwordResponceKey === '0'
                            ? 'weak'
                            : passwordResponce.passwordResponceKey === '1'
                              ? 'average'
                              : passwordResponce.passwordResponceKey === '2'
                                ? 'good'
                                : passwordResponce.passwordResponceKey === '3'
                                  ? 'great'
                                  : ''
                        }
                      >
                        {passwordResponce.passwordResponceText}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Confirm Password</label>
                      <div className="pass-group">
                        <input
                          type={passwordVisibility[1] ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={passwords[1]}
                          onChange={(e) => {
                            const updatedPasswords = [...passwords];
                            updatedPasswords[1] = e.target.value;
                            setPasswords(updatedPasswords);
                          }}
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(1)}
                        >
                          {passwordVisibility[1] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary w-100 login-btn" type="submit">
                    Save Change
                  </button>
                  {message && <div className="alert alert-success mt-2">{message}</div>}
                  {error && <div className="alert alert-danger mt-2">{error}</div>}
                </form>
                {/* /Reset Password Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
