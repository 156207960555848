import React from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import CustomerWalletModal from '../../common/modals/customer-wallet-modals';
import { Link } from 'react-router-dom';

const CustomerWallet = () => {
  return (
    <>
      <div className="col-lg-9">
        <div className="row">
          <div className="col-md-6">
            <div className="widget-title">
              <h4>Wallet</h4>
            </div>
          </div>
          <div className="col-md-6 text-md-end">
            <Link
              to="#"
              className="btn btn-primary btn-wallet"
              data-bs-toggle="modal"
              data-bs-target="#add-wallet"
            >
              <Icon.Plus className="standard-feather mb-1" /> Add Wallet
            </Link>
          </div>
        </div>
        {/* Dashboard Widget */}
        <div className="row row-cols-lg-3 row-cols-xl-5 justify-content-center">
          <div className="col-md-6 d-flex">
            <div className="dash-widget dash-wallet flex-fill">
              <div className="dash-img">
                <span className="dash-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/cus-wallet.svg"
                    alt="image"
                  />
                </span>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Balance</h6>
                  <h5>$351.4</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="dash-widget dash-wallet flex-fill">
              <div className="dash-img">
                <span className="dash-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/cus-withdraw.svg"
                    alt="image"
                  />
                </span>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Total Credit</h6>
                  <h5>$590.4</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="dash-widget dash-wallet flex-fill">
              <div className="dash-img">
                <span className="dash-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/cus-credit-card.svg"
                    alt="image"
                  />
                </span>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Total Debit</h6>
                  <h5>$228.04</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="dash-widget dash-wallet flex-fill">
              <div className="dash-img">
                <span className="dash-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/cus-money.svg"
                    alt="image"
                  />
                </span>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Savings</h6>
                  <h5>$200.00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="dash-widget dash-wallet flex-fill">
              <div className="dash-img">
                <span className="dash-icon bg-light-danger">
                  <ImageWithBasePath
                    src="assets/img/icons/cus-file.svg"
                    alt="image"
                  />
                </span>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Taxes</h6>
                  <h5>$20.04</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Dashboard Widget */}
        {/* Wallet Transactions */}
        <h6 className="user-title">Wallet Transactions</h6>
        <div className="table-responsive">
          <table className="table mb-0 custom-table">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Payment Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Wallet Topup</td>
                <td className="text-light-success">+$80</td>
                <td className="text-body">07 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-success">Completed</span>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Purchase</td>
                <td className="text-light-danger">-$20</td>
                <td className="text-body">06 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-danger">Cancel</span>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Refund</td>
                <td className="text-light-success">+$20</td>
                <td className="text-body">06 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-success">Completed</span>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Wallet Topup</td>
                <td className="text-light-success">+$100</td>
                <td className="text-body">03 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-success">Completed</span>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Purchase</td>
                <td className="text-light-danger">-$20</td>
                <td className="text-body">06 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-danger">Cancel</span>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Refund</td>
                <td className="text-light-success">+$20</td>
                <td className="text-body">06 Oct 2023 11:22:51</td>
                <td className="text-body">Paypal</td>
                <td>
                  <span className="badge-success">Completed</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* /Wallet Transactions */}
      </div>
      <CustomerWalletModal />
    </>
  );
};

export default CustomerWallet;
