import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import DefaultEditor from 'react-simple-wysiwyg';
import * as Icon from 'react-feather';
import { getCategoryList, getUserList } from '../../../../core/data/api/api';

interface User {
  id: string;
  name: string;
  img?: string;
  email: string;
  mobile_no: string;
  created_at: string;
  status: string;
  user_type: string;
  role_id: string;
}

interface SubCategory {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  sub_categories: SubCategory[];
}

type Props = {
  nextTab: CallableFunction;
  formData: any;
  setFormData: (formData: any) => void;
};

const ServiceInformation: React.FC<Props> = ({ nextTab, formData, setFormData }) => {
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [token, setToken] = useState<string>('');
  const [providerData, setProviderData] = useState<User[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const result = await getCategoryList(token);
        setCategoryData(result.data.payload.records);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    if (token) {
      fetchCategoryData();
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserList(token);
        const userList: User[] = result.data.payload.getList.flat();
        const filteredData = userList.filter((user: User) => user.user_type === 'POWERBUDDY');
        setProviderData(filteredData);
        localStorage.removeItem(token);
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    fetchData();
  }, [token]);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const categoryId = event.target.value;
    setFormData({ ...formData, category_id: categoryId });

    const selectedCategory = categoryData.find((category) => category.id === categoryId);

    if (selectedCategory && selectedCategory.sub_categories) {
      setSubCategories(selectedCategory.sub_categories);
    } else {
      setSubCategories([]);
    }
  };

  const handleSubCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const subCategoryId = event.target.value;
    setFormData({ ...formData, sub_category_id: subCategoryId });
  };

  const handleProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const buddy_id = event.target.value;
    setFormData({ ...formData, buddy_id });
  };

  const addNewServiceRow = () => {
    const newId = formData.additional_services.length + 1;
    setFormData({
      ...formData,
      additional_services: [...formData.additional_services, { id: newId, title: '', price: '', duration: '' }],
    });
  };

  const deleteServiceRow = (id: number) => {
    const updatedServices = formData.additional_services.filter((service: any) => service.id !== id);
    setFormData({ ...formData, additional_services: updatedServices });
  };

  const handleInputChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedServices = formData.additional_services.map((service: any) =>
      service.id === id ? { ...service, [name]: value } : service
    );
    setFormData({ ...formData, additional_services: updatedServices });
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors: any = {};

    if (!formData.title) errors.title = 'Service Title is required';
    if (!formData.slug) errors.slug = 'Service Slug is required';
    if (!formData.price) errors.price = 'Price is required';
    if (!formData.duration) errors.duration = 'Duration is required';
    if (!formData.buddy_id) errors.buddy_id = 'Provider is required';
    if (!formData.category_id) errors.category_id = 'Category is required';
    if (!formData.sub_category_id) errors.sub_category_id = 'Sub Category is required';
    if (!formData.description) errors.description = 'Description is required';
    if (!formData.video_link) errors.video_link = 'Video Link is required';

    formData.additional_services.forEach((service: any, index: number) => {
      if (!service.title) errors[`service_title_${index}`] = 'Service title is required';
      if (!service.price) errors[`service_price_${index}`] = 'Service price is required';
      if (!service.duration) errors[`service_duration_${index}`] = 'Service duration is required';
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      setShowPopup(true);
    }
  };

  const handleSave = () => {
    const sanitizedFormData = {
      ...formData,
      additional_services: formData.additional_services.map((service: any) => {
        const { id, ...rest } = service;
        return rest;
      }),
    };

    setFormData(sanitizedFormData);
    nextTab();
  };

  return (
    <>
      <fieldset id="first-field">
        <div className="container-service space-service">
          <div className="sub-title">
            <h6>Service Information </h6>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Service Title *</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Name of your service"
                  required
                />
                {formErrors.title && <p className="text-danger">{formErrors.title}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Service Slug *</label>
                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  value={formData.slug}
                  onChange={handleFormChange}
                  placeholder="service_slug"
                  required
                />
                {formErrors.slug && <p className="text-danger">{formErrors.slug}</p>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Price *</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={formData.price}
                  onChange={handleFormChange}
                  placeholder="$10"
                  required
                />
                {formErrors.price && <p className="text-danger">{formErrors.price}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Duration *</label>
                <input
                  type="text"
                  className="form-control"
                  name="duration"
                  value={formData.duration}
                  onChange={handleFormChange}
                  placeholder="15 minutes"
                  required
                />
                {formErrors.duration && <p className="text-danger">{formErrors.duration}</p>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Provider *</label>
                <select
                  className="form-control"
                  value={formData.buddy_id}
                  onChange={handleProviderChange}
                  required
                >
                  <option value="">Select Provider</option>
                  {providerData.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
                </select>
                {formErrors.buddy_id && <p className="text-danger">{formErrors.buddy_id}</p>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Category *</label>
                <select
                  className="form-control"
                  value={formData.category_id}
                  onChange={handleCategoryChange}
                  required
                >
                  <option value="">Select Category</option>
                  {categoryData.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {formErrors.category_id && <p className="text-danger">{formErrors.category_id}</p>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Sub Category *</label>
                <select
                  className="form-control"
                  value={formData.sub_category_id}
                  onChange={handleSubCategoryChange}
                  required
                >
                  <option value="">Select Sub Category</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  ))}
                </select>
                {formErrors.sub_category_id && <p className="text-danger">{formErrors.sub_category_id}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Description *</label>
            <DefaultEditor
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
            {formErrors.description && <p className="text-danger">{formErrors.description}</p>}
          </div>
        </div>
        <div className="form-group">
          <label>Video Link *</label>
          <input
            type="text"
            className="form-control"
            name="video_link"
            value={formData.video_link}
            onChange={handleFormChange}
            placeholder="https://"
            required
          />
          {formErrors.video_link && <p className="text-danger">{formErrors.video_link}</p>}
        </div>
        <div className="container-service space-service">
          <div className="sub-title">
            <h6>Additional Services</h6>
          </div>
          {formData.additional_services.map((service: any, index: any) => (
            <div className="row" key={service.id}>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Service *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={service.title}
                    onChange={(e) => handleInputChange(service.id, e)}
                    placeholder="Additional service"
                    required
                  />
                  {formErrors[`service_title_${index}`] && <p className="text-danger">{formErrors[`service_title_${index}`]}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Price *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="price"
                    value={service.price}
                    onChange={(e) => handleInputChange(service.id, e)}
                    placeholder="$10"
                    required
                  />
                  {formErrors[`service_price_${index}`] && <p className="text-danger">{formErrors[`service_price_${index}`]}</p>}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Duration *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="duration"
                    value={service.duration}
                    onChange={(e) => handleInputChange(service.id, e)}
                    placeholder="15 minutes"
                    required
                  />
                  {formErrors[`service_duration_${index}`] && <p className="text-danger">{formErrors[`service_duration_${index}`]}</p>}
                </div>
              </div>
              {index > 0 && (
                <div className="col-md-1 mt-3 pt-4">
                  <Icon.Trash onClick={() => deleteServiceRow(service.id)} />
                </div>
              )}
            </div>
          ))}
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={addNewServiceRow}>
                Add Service
              </button>
            </div>
          </div>
        </div>
        <div className="container-service space-service">
          <div className="row">
                <div className="col-md-12">
                  <div className="bottom-btn">
                    <div className="field-btns">
                    
                      <button
                        className="btn btn-primary next_btn"
                        type="button"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        
                        onClick={handleNext}
                      >
                        Next <i className="fas fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>




        </div>
      </fieldset>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h3>Recheck Information</h3>
            <p>Please check your information before proceeding.</p>
            <button className="btn btn-secondary" onClick={() => setShowPopup(false)}>
              Check
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceInformation;
