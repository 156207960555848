import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { all_routes } from '../../../../core/data/routes/all_routes';

const Invoice = () => {
  const routes = all_routes;
  return (
    <>
      <>
  {/* Breadcrumb */}
  <div className="breadcrumb-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="breadcrumb-title">Invoice</h2>
          <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={routes.homeOne}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Invoice
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  {/* /Breadcrumb */}
  <div className="content">
    <div className="container">
      <div className="row">
        <div className="col-lg-9 mx-auto">
          {/* Breadcrumb */}
          <div className="page-topbar">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="breadcrumb invoice-breadcrumb">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={routes.homeOne}>Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Orders
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        ID 2378910
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* /Breadcrumb */}
          {/* Invoice */}
          <div className="invoice-wrap">
            <div className="row">
              <div className="col-md-4">
                <div className="invoice-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="invoice-head">
                  <h5>Invoice</h5>
                  <ul>
                    <li>
                      <i className="feather-file-text" /> #LL2378910
                    </li>
                    <li>
                      <i className="feather-calendar" /> Issue date: 22 Sep 2023
                    </li>
                    <li>
                      <i className="feather-calendar" /> Due date: 25 Jan 2023
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="invoice-address">
                  <h6>Invoice From:</h6>
                  <ul>
                    <li>Truelysell</li>
                    <li>
                      367 Hillcrest Lane, Irvine, California, United States
                    </li>
                    <li>truelysell@example.com</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="invoice-address invoice-to">
                  <h6>Invoice To:</h6>
                  <ul>
                    <li>Example Company Name</li>
                    <li>1620 Jerry Dove Drive Myrtle Beach, SC 29577</li>
                    <li>LillianBMacon@rhyta.com</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12">
                <div className="table-responsive invoice-table">
                  <table className="table mb-0 custom-table">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Services</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Hours</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <h2 className="table-avatar table-sml">
                            <Link to="#" className="avatar avatar-m me-2">
                              <ImageWithBasePath
                                className="avatar-img rounded"
                                src="assets/img/services/service-06.jpg"
                                alt="User Image"
                              />
                           </Link>
                            <Link to="#">Computer Repair</Link>
                          </h2>
                        </td>
                        <td>Lorem ipsum dolor amet, adipiscing elit</td>
                        <td>$80</td>
                        <td>1 Hour</td>
                        <td>$80</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <h2 className="table-avatar table-sml">
                            <Link to="#" className="avatar avatar-m me-2">
                              <ImageWithBasePath
                                className="avatar-img rounded"
                                src="assets/img/services/service-02.jpg"
                                alt="User Image"
                              />
                           </Link>
                            <Link to="#">Car Repair Services</Link>
                          </h2>
                        </td>
                        <td>Lorem ipsum dolor amet, adipiscing elit</td>
                        <td>$80</td>
                        <td>1 Hour</td>
                        <td>$80</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <h2 className="table-avatar table-sml">
                            <Link to="#" className="avatar avatar-m me-2">
                              <ImageWithBasePath
                                className="avatar-img rounded"
                                src="assets/img/services/service-04.jpg"
                                alt="User Image"
                              />
                           </Link>
                            <Link to="#">Steam Car Wash</Link>
                          </h2>
                        </td>
                        <td>Lorem ipsum dolor amet, adipiscing elit</td>
                        <td>$80</td>
                        <td>1 Hour</td>
                        <td>$80</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <h2 className="table-avatar table-sml">
                            <Link to="#" className="avatar avatar-m me-2">
                              <ImageWithBasePath
                                className="avatar-img rounded"
                                src="assets/img/services/service-05.jpg"
                                alt="User Image"
                              />
                           </Link>
                            <Link to="#">House Cleaning</Link>
                          </h2>
                        </td>
                        <td>Lorem ipsum dolor amet, adipiscing elit</td>
                        <td>$80</td>
                        <td>1 Hour</td>
                        <td>$80</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="invoice-payment">
                  <h6>Payment info:</h6>
                  <ul>
                    <li>Credit Card - 123***********789</li>
                    <li className="pay-amt">
                      <span>Amount:</span> $252.36
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="invoice-total">
                  <ul>
                    <li>
                      Sub Total <span>$244.00</span>
                    </li>
                    <li>
                      Tax(5%) <span>$8.36</span>
                    </li>
                    <li className="tot-amt">
                      Total <span>$252.36</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-terms">
            <h5>Terms &amp; Conditions:</h5>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nis
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                tempor incididunt ut labore et dolore magna aliqua.Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nis
              </li>
            </ul>
          </div>
        </div>
        {/* /Invoice */}
      </div>
    </div>
  </div>
</>

    </>
  )
}

export default Invoice
