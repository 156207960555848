export const newsletter = [
  {
    id: '1',
    name: 'John Smith',
    email: 'john@example.com',
    phone: '+1 347-679-8275',
    message: 'Need this script',
    date: '28 Sep 2022',
    date2: '18 Oct 2022',
    img: 'assets/admin/img/customer/user-01.jpg',
  },
  {
    id: '2',
    name: 'Johnny',
    email: 'johnny@example.com',
    phone: '+1 347-679-8275',
    message: 'Need this script',
    date: '28 Sep 2022',
    date2: '05 Oct 2022',
    img: 'assets/admin/img/customer/user-04.jpg',
  },
  {
    id: '3',
    name: 'Robert',
    email: 'robert@example.com',
    phone: '+1 347-679-8275',
    message: 'Need this script',
    date: '23 Sep 2022',
    date2: '13 Oct 2022',
    img: 'assets/admin/img/customer/user-02.jpg',
  },
  {
    id: '4',
    name: 'Sharonda',
    email: 'sharonda@example.com',
    phone: '+1 347-679-8275',
    message: 'Need this script',
    date: '03 Sep 2022',
    date2: '23 Oct 2022',
    img: 'assets/admin/img/customer/user-09.jpg',
  },
];
