import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';


const BlogGrid = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Our Blog</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Blog
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      Grid
                    </li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-6 d-flex">
                    {/* Blog Post */}
                    <div className="blog grid-blog flex-fill">
                      <div className="blog-image">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            className="img-fluid"
                            src="assets/img/services/service-19.jpg"
                            alt="Post Image"
                          />
                        </Link>
                      </div>
                      <div className="blog-content">
                        <div className="blog-category">
                          <ul>
                            <li>
                              <span className="cat-blog">Computer</span>
                            </li>
                            <li>
                              <Icon.Calendar
                                className="react-feather-custom me-2"
                                color="#74788d"
                              />
                              28 Sep 2023
                            </li>
                            <li>
                              <div className="post-author">
                                <Link to="#">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-02.jpg"
                                    alt="Post Author"
                                  />
                                  <span>Admin</span>
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <h3 className="blog-title">
                          <Link to={routes.blogDetails}>
                            How to Fix a Computer in Just 3 Steps?
                          </Link>
                        </h3>
                        <p>
                          Sed ut perspiciatis omnis natus error voluptatem
                          architecto beatae vitae dicta sunt explicabo.
                        </p>
                        <Link to={routes.blogDetails} className="read-more">
                          Read More{' '}
                          <Icon.ArrowRightCircle className="react-feather-custom" />
                        </Link>
                      </div>
                    </div>
                    {/* /Blog Post */}
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex">
                    {/* Blog Post */}
                    <div className="blog grid-blog flex-fill">
                      <div className="blog-image">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            className="img-fluid"
                            src="assets/img/services/service-10.jpg"
                            alt="Post Image"
                          />
                        </Link>
                      </div>
                      <div className="blog-content">
                        <div className="blog-category">
                          <ul>
                            <li>
                              <span className="cat-blog">Construction</span>
                            </li>
                            <li>
                              <Icon.Calendar
                                className="react-feather-custom me-2"
                                color="#74788d"
                              />
                              28 Sep 2023
                            </li>
                            <li>
                              <div className="post-author">
                                <Link to="#">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-02.jpg"
                                    alt="Post Author"
                                  />
                                  <span>Admin</span>
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <h3 className="blog-title">
                          <Link to={routes.blogDetails}>
                            Construction Service Scams: How to Avoid Them
                          </Link>
                        </h3>
                        <p>
                          Sed ut perspiciatis omnis natus error voluptatem
                          architecto beatae vitae dicta sunt explicabo.
                        </p>
                        <Link to={routes.blogDetails} className="read-more">
                          Read More{' '}
                          <Icon.ArrowRightCircle className="react-feather-custom" />
                        </Link>
                      </div>
                    </div>
                    {/* /Blog Post */}
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex">
                    {/* Blog Post */}
                    <div className="blog grid-blog flex-fill">
                      <div className="blog-image">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            className="img-fluid"
                            src="assets/img/services/service-08.jpg"
                            alt="Post Image"
                          />
                        </Link>
                      </div>
                      <div className="blog-content">
                        <div className="blog-category">
                          <ul>
                            <li>
                              <span className="cat-blog">Car Wash</span>
                            </li>
                            <li>
                              <Icon.Calendar
                                className="react-feather-custom me-2"
                                color="#74788d"
                              />
                              28 Sep 2023
                            </li>
                            <li>
                              <div className="post-author">
                                <Link to="#">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-02.jpg"
                                    alt="Post Author"
                                  />
                                  <span>Admin</span>
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <h3 className="blog-title">
                          <Link to={routes.blogDetails}>
                            Lorem ipsum dolor sit amet, consectetur sed do
                          </Link>
                        </h3>
                        <p>
                          Sed ut perspiciatis omnis natus error voluptatem
                          architecto beatae vitae dicta sunt explicabo.
                        </p>
                        <Link to={routes.blogDetails} className="read-more">
                          Read More{' '}
                          <Icon.ArrowRightCircle className="react-feather-custom" />
                        </Link>
                      </div>
                    </div>
                    {/* /Blog Post */}
                  </div>
                  <div className="col-md-12">
                    <div className="blog-pagination">
                      <nav>
                        <ul className="pagination justify-content-center">
                          <li className="page-item disabled">
                            <Link
                              className="page-link page-prev"
                              to="#"
                              tabIndex={-1}
                            >
                              <i className="fa-solid fa-arrow-left me-2" /> PREV
                            </Link>
                          </li>
                          <li className="page-item active">
                            <Link className="page-link" to="#">
                              1
                            </Link>
                          </li>
                          {/* <li className="page-item">
                            <Link className="page-link" to="#">
                              2
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link className="page-link" to="#">
                              3
                            </Link>
                          </li> */}
                          <li className="page-item">
                            <Link className="page-link page-next" to="#">
                              NEXT{' '}
                              <i className="fa-solid fa-arrow-right ms-2" />
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogGrid;
