// HomeHeader.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { set_header_data, set_toggleSidebar_data, logout } from '../../../core/data/redux/action';
import * as Icon from 'react-feather';
import { AppState, Header } from '../../../core/models/interface';

type props = {
  type: number;
};

const HomeHeader: React.FC<props> = ({ type }) => {
  const routes = all_routes;
  const location = useLocation();
  const header_data = useSelector((state: Header) => state.header_data);
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar);
  const userRole = useSelector((state: AppState) => state.userRole); // Add this line
  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  const [close, setClose] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState({
    logo: '',
    logoSmall: '',
    logoSvg: '',
  });
  const dispatch = useDispatch();
  
  const toogle = () => {
    dispatch(set_toggleSidebar_data(toggle_data ? false : true));
  };

  const setHeaderData = () => {
    dispatch(set_header_data([]));
  };

  const handleScroll = () => {
    setScrollYPosition(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    type == 1 || type == 4 || type == 10
      ? setImageUrl({
          logo: 'assets/img/logo.png',
          logoSmall: 'assets/img/logo-small.png',
          logoSvg: 'assets/img/logo.svg',
        })
      : setImageUrl({
          logo: 'assets/img/logo-02.svg',
          logoSmall: 'assets/img/logo-icon.png',
          logoSvg: 'assets/img/logo-02.svg',
        });
  }, [type]);

  const routerPath = (pathType: number) => {
    switch (pathType) {
      case 1:
        return { path: routes.homeOne, className: 'header-one' };
      case 10:
        return { path: routes.homeOne, className: '' };
      default:
        return { path: routes.homeOne, className: 'header-one' };
    }
  };

  const renderButtons = (pathType: number) => {
    if (userRole === 'user') {
      return (
        <ul className="nav header-navbar-rht">
          <li className="nav-item">
            <Link className="nav-link header-reg" to={routes.customerProfile}>
              User Dashboard
            </Link>
          </li>
          <li className="nav-item" onClick={() => dispatch(logout())}>
            <Link className="nav-link header-login" to={routes.homeOne}>
            <Icon.Users className="react-feather-custom me-1 mb-1" />
            LogOut
            </Link>
          </li>
        </ul>
      );
    } else if (userRole === 'provider') {
      return (
        <ul className="nav header-navbar-rht">
          <li className="nav-item">
            <Link className="nav-link header-reg" to={routes.createService}>
              Provider Dashboard
            </Link>
          </li>
          <li className="nav-item" onClick={() => dispatch(logout())}>
            <Link className="nav-link header-login" to={routes.homeOne}>
              <Icon.Users className="react-feather-custom me-1 mb-1" />
              LogOut
            </Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="nav header-navbar-rht">
          <li className="nav-item">
            <Link className="nav-link header-reg" to="/authentication/choose-signup">
              Register
            </Link>
          </li>
          <li className="nav-item" onClick={setHeaderData}>
            <Link className="nav-link header-login" to="/authentication/login">
              <Icon.Users className="react-feather-custom me-1 mb-1" />
              Login
            </Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <>
      <header className={`header ${routerPath(type).className} ${scrollYPosition > 100 ? 'fixed' : ''}`}>
        <div className={`${type == 3 ? 'container-fluid' : 'container'}`}>
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link onClick={toogle} id="mobile_btn" to="#">
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routerPath(type).path} className="navbar-brand logo">
                <ImageWithBasePath src={imageUrl.logo} className="img-fluid" alt="Logo" />
              </Link>
              <Link to={routerPath(type).path} className="navbar-brand logo-small">
                <ImageWithBasePath src={imageUrl.logoSmall} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routerPath(type).path} className="menu-logo">
                  <ImageWithBasePath src={imageUrl.logoSvg} className="img-fluid" alt="Logo" />
                </Link>
                <Link onClick={toogle} id="menu_close" className="menu-close" to="#">
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li className="">
                  <Link to={routes.homeOne}>Home</Link>
                </li>
                <li className="">
                  <Link to={routes.serviceGrid}>Services</Link>
                </li>
                <li className="">
                  <Link to={routes.categories}>Categories</Link>
                </li>
                <li className="">
                  <Link to={routes.contactUs}>Contact Us</Link>
                </li>
                {/* <li className="">
                  <Link to={routes.aboutUs}>About Us</Link>
                </li> */}
                {/* <li className="">
                  <Link to={routes.blogGrid}>Blogs</Link>
                </li> */}
                <li className="login-link">
                  <Link to={routes.chooseSignUp}>Register</Link>
                </li>
                <li className="login-link">
                  <Link to={routes.login}>Login</Link>
                </li>
                
              </ul>
              
            </div>
            {renderButtons(type)}
          </nav>
        </div>
      </header>
    </>
  );
};

export default HomeHeader;
