import React, { useState } from 'react';
import { Link, Navigate} from 'react-router-dom';
import * as Icon from 'react-feather';
import PhoneInput from 'react-phone-input-2';
// import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { registerUser } from '../../../../core/data/api/api';

const UserSignup = () => {
  const routes = all_routes;
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const handleOnChange = (value:any, country:any) => {
    console.log(value, country);
    setPhone(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignup = async (e:any) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }
    try {
      const data = await registerUser(name, email, password, confirmPassword); 
      console.log('Registration successful:', data);
      localStorage.setItem('token', data.data.payload.token);
      // localStorage.removeItem('token');
      setName("");
      setEmail("");
      setPhone("");
      setPassword("");
      setConfirmPassword(""); 
      setRedirectToDashboard(true);
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  if (redirectToDashboard) {
    return <Navigate to={routes.emailOtp} />;
  }
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>User Signup</h3>
                </div>
                {/* Registration Form */}
                <form onSubmit={handleSignup}>
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="johndoe@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="col-form-label">Phone Number</label>
                      <div className="form-group input">
                        <PhoneInput
                          country={'us'}
                          value={phone}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Password
                      <span className="brief-bio float-end">
                        Must be 8 Characters at Least
                      </span>
                    </label>
                    <div className="pass-group">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        placeholder="*************"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <Icon.EyeOff className="react-feather-custom" />
                        ) : (
                          <Icon.Eye className="react-feather-custom" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Confirm Password</label>
                    <input
                      type="confirmPassword"
                      className="form-control"
                      placeholder="*************"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {passwordMatchError && (
                      <div className="text-danger">Passwords do not match</div>
                    )}
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Signup
                  </button>
                 
                  <p className="no-acc">
                    Already have an account?{' '}
                    <Link to={routes.login}>Sign In</Link>
                  </p>
                </form>
                {/* /Registration Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSignup;
