import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const AboutUs = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const clientSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">About Us</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content p-0">
          {/* About */}
          <div className="about-sec">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <div className="about-exp ">
                      {/* <span>years of experiences</span> */}
                    </div>
                    <div className="abt-img">
                      <ImageWithBasePath
                        src="assets/img/about-01.jpg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <h6>ABOUT OUR COMPANY</h6>
                    <h2>Best Solution For Cleaning Services</h2>
                    <p>
                      Welcome to your one-stop place for anything you need!
                      Power Buddy delivers quality and convenience right to your
                      door. Power Buddy connects you with trustworthy
                      professionals for a variety of services, whether you need
                      a handyman to fix a broken tap, a hairstylist for a new
                      trim, or a deep cleaning for a spotless home.
                    </p>
                    <p>
                      Power Buddy is aware of the value of your time. Our aim is
                      to deliver top-notch services directly to your home,
                      entirely changing the way you manage your demands. Our
                      objective is to establish ourselves as your go-to partner
                      for convenience, ensuring that every task is completed
                      professionally, and with careful attention to detail. Our
                      goal? Give your time and energy back to the things that
                      really important to you.
                    </p>
                    {/* <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>At vero et accusamus iusto dignissimos</li>
                          <li>At vero et accusamus iusto dignissimos</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>Nam libero tempore, cum soluta nobis</li>
                          <li>Nam libero tempore, cum soluta nobis</li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /About */}
          {/* Work Section */}
          <section className="work-section work-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading">
                    <h2>What Sets Us Apart</h2>
                    {/* <p>Aliquam lorem ante, dapibus in, viverra quis</p> */}
                  </div>
                </div>
              </div>
              <div className="row  ">
                <div className="col-md-3 ">
                  <div className="work-box">
                    <h5 className="about-heading">Wide Range of Services</h5>
                    <p>
                      We provide a wide variety of services to meet all of your
                      needs, from beauty treatments to house repairs.
                    </p>
                    <h4>01</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <h5 className="about-heading">
                      Professional and Verified Providers
                    </h5>
                    <p>
                      We only collaborate with the most excellent professionals
                      to guarantee that you always obtain exceptional services.
                    </p>
                    <h4>02</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <h5 className="about-heading">Convenience</h5>
                    <p>
                      Our services are easy to use and make your work easy. You
                      can utilize our services just by booking a service slot
                      through our website.
                    </p>
                    <h4>03</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <h5 className="about-heading">Customer-Centric Approach</h5>
                    <p>
                      We put your needs first and work hard to make sure you
                      have the best possible experience with us.
                    </p>
                    <h4>04</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Work Section */}
          {/* Choose Us Section */}
          <div className="chooseus-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="choose-content">
                    <h2>Our Values</h2>
                    <p>
                      We make sure we not only offer outstanding service but
                      also create a happy and productive workplace.
                    </p>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#aboutone"
                          aria-expanded="false"
                        >
                          Customer Satisfaction
                        </Link>
                      </h4>
                      <div id="aboutone" className="card-collapse collapse">
                        <p>
                          Our business revolves around you. We ensure client
                          satisfaction from start to finish. In order to
                          effectively serve our clients, we pay attention to
                          what they need.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#abouttwo"
                          aria-expanded="false"
                        >
                          Quality
                        </Link>
                      </h4>
                      <div id="abouttwo" className="card-collapse collapse">
                        <p>
                          Our goal is to give our customers the best service
                          possible. We have collaborated with excellent
                          professionals in their fields who are enthusiastic for
                          their profession.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about3"
                          aria-expanded="false"
                        >
                          Reliability
                        </Link>
                      </h4>
                      <div id="about3" className="card-collapse collapse">
                        <p>
                          You can rely on us to be there for all your needs. Our
                          employees consistently deliver outstanding outcomes on
                          time, and we pride ourselves on providing dependable,
                          consistent service.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about4"
                          aria-expanded="false"
                        >
                          Integrity
                        </Link>
                      </h4>
                      <div id="about4" className="card-collapse collapse">
                        <p>
                          We emphasize to be truthful and open with our
                          customers. We believe winning the trust of our
                          customers through ethical business practices and open
                          communication.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="chooseus-img">
                    <ImageWithBasePath
                      src="assets/img/about-02.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      {/* <h5>2583+</h5> */}
                      <p>Satisfied Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-01.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      {/* <h5>2383+</h5> */}
                      <p>Expert Team</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.png"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      {/* <h5>2129+</h5> */}
                      <p>Project Completed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon border-0">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-03.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      {/* <h5>30+</h5> */}
                      <p>Years of experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Choose Us Section */}

          {/* Client Section */}
          <section className="client-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading aos" data-aos="fade-up">
                    <h2>What our client says</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur elit</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="testimonial-slider">
                    <Slider {...clientSlider}>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avatar"
                              src="assets/img/profiles/avatar-01.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          {/* <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div> */}
                          <p>
                            Power Buddy has helped me a lot. It just exceeded my
                            excellent quality level! I had an emergency plumbing
                            issue late at night, and their team was at my door
                            within an hour. They quickly fixed the problem with
                            professionalism and efficiency. Thanks to Power
                            Buddy, I can relax knowing that my home is in great
                            hands. {' '}
                          </p>
                          <h5>Jessica M.</h5>
                          <h6>Director</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          {/* <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div> */}
                          <p>
                            I got my electrical problem fixed at my house with
                            Power Buddy. The electrician who visited my house
                            was kind and knowledgeable. He identified the issue
                            right away and in no time at all. In a short time,
                            he repaired everything. I found their outstanding
                            quality, and the pricing was reasonable.{' '}
                          </p>
                          <h5>David L.</h5>
                          <h6>Lead</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          {/* <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div> */}
                          <p>
                            Because of my hectic schedule, I usually struggle to
                            clean my house. Power Buddy&#39;s excellent cleaning
                            services have helped me a lot. The group was on
                            time; they arrived at the time I had scheduled. I
                            liked that they used eco-friendly items and paid
                            close attention to every detail. {' '}
                          </p>
                          <h5>Emily R.</h5>
                          <h6>CEO</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Client Section */}
          {/* Service Section */}
          <div className="service-offer">
            <div className="container">
              <div className="col-md-12">
                <div className="offer-paths about-offer">
                  <div className="offer-path-content">
                    <h3>Our Vision </h3>
                    <p>
                      We see a society in which everyone can afford a nice, tidy
                      home and feel good about the way they look, and where
                      competent assistance is always available. Our goal is to
                      reach as many people as possible with our services,
                      bringing convenience, ease, and eventually reduced stress
                      to everyone&#39;s lives.
                    </p>
                    <Link
                      to={routes.contactUs}
                      className="btn btn-primary btn-views"
                    >
                      Get Started
                      <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                  <div className="offer-pathimg">
                    <ImageWithBasePath
                      src="assets/img/service-img.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Service Section */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
