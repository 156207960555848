import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

const ProviderSocialProfile = () => {
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);
  const [selectedValue3, setSelectedValue3] = useState(null);
  const [selectedValue4, setSelectedValue4] = useState(null);
  const [selectedValue5, setSelectedValue5] = useState(null);

  const value1 = [{ name: 'Facebook' }, { name: 'Instagram' }];
  const value2 = [{ name: 'Instagram' }, { name: 'Facebook' }];
  const value3 = [{ name: 'Youtube' }, { name: 'Facebook' }];
  const value4 = [{ name: 'Twitter' }, { name: 'Facebook' }];
  const value5 = [{ name: 'Linkedin' }, { name: 'Facebook' }];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* Social Profiles */}
              <div className="widget-title">
                <h4>Social Profiles</h4>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <Dropdown
                          value={selectedValue1}
                          onChange={(e) => setSelectedValue1(e.value)}
                          options={value1}
                          optionLabel="name"
                          placeholder="Facebook"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex. www.socialmedia.com"
                        />
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-sm-12">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <div className="status-toggle sml-status d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="status_1"
                            className="check"
                          />
                          <label htmlFor="status_1" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <Dropdown
                          value={selectedValue2}
                          onChange={(e) => setSelectedValue2(e.value)}
                          options={value2}
                          optionLabel="name"
                          placeholder="Instagram"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex. www.socialmedia.com"
                        />
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-sm-12">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <div className="status-toggle sml-status d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="status_2"
                            className="check"
                          />
                          <label htmlFor="status_2" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <Dropdown
                          value={selectedValue3}
                          onChange={(e) => setSelectedValue3(e.value)}
                          options={value3}
                          optionLabel="name"
                          placeholder="Youtube"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex. www.socialmedia.com"
                        />
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-sm-12">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <div className="status-toggle sml-status d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="status_3"
                            className="check"
                          />
                          <label htmlFor="status_3" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <Dropdown
                          value={selectedValue4}
                          onChange={(e) => setSelectedValue4(e.value)}
                          options={value4}
                          optionLabel="name"
                          placeholder="Twitter"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex. www.socialmedia.com"
                        />
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-sm-12">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <div className="status-toggle sml-status d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="status_4"
                            className="check"
                          />
                          <label htmlFor="status_4" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <Dropdown
                          value={selectedValue5}
                          onChange={(e) => setSelectedValue5(e.value)}
                          options={value5}
                          optionLabel="name"
                          placeholder="Linkedin"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex. www.socialmedia.com"
                        />
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-sm-12">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <div className="status-toggle sml-status d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="status_6"
                            className="check"
                            defaultChecked
                          />
                          <label htmlFor="status_6" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="acc-submit">
                    <Link to="#" className="btn btn-secondary">
                      Cancel
                    </Link>
                    <Link to="#" className="btn btn-primary">
                      Save Changes
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Social Profiles */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderSocialProfile;
