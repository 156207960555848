import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import CustomersModal from '../common/modals/customers-modal';
import UpdateCustomer from '../common/modals/update-customer';
import DeleteCustomersModal from '../common/modals/delete-customers-modal';
import { getUserList } from '../../../core/data/api/api'; 
import { format } from 'date-fns';
interface User {
    id: string;
    name: string;
    img?: string;
    email: string;
    mobile_no: string;
    created_at: string;
    status: string;
    user_type: string;
    role_id: string;
}
const Customers = () => {
   
    const [data, setData] = useState<User[]>([]);
    const [token, setToken] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const value = [{ name: 'A - Z' }, { name: 'Z - A' }];

    useEffect(() => {
        const storedToken = localStorage.getItem('AdminToken');
        // console.log('Stored token:', storedToken);
        if (storedToken) {
          setToken(storedToken);
        } else {
          console.log("Token not found. Please sign up again.");
        }
      }, []);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getUserList(token);
                const userList: User[] = result.data.payload.getList.flat();
                const filteredData = userList.filter((user: User) => user.user_type === 'POWERBUDDY');
                setData(filteredData);
                localStorage.removeItem(token);
            } catch (error) {
                console.error('Error fetching user list:', error);
            }
        };

        fetchData();
    }, [token]);

    const handleRowClick = (rowData: User) => {
        setSelectedUser(rowData);
    };
    const renderDate = (rowData: any) => {
        return format(new Date(rowData.created_at), 'dd/MM/yyyy HH:mm');
    };
    const actionButton = (rowData: User) => {
        return (
            <div className="table-actions d-flex">
                <button className="btn btn-primary me-3" onClick={() => handleRowClick(rowData)} data-bs-toggle="modal" data-bs-target="#edit-customer">Show details</button>
                <Link className="delete-table me-3" to="#" data-bs-toggle="modal" data-bs-target="#update-customer-data">
                    <Icon.Edit className='react-feather-custom' />
                </Link>
                <Link className="delete-table border-none" to="#" data-bs-toggle="modal" data-bs-target="#delete-customer">
                    <Icon.Trash2 className='react-feather-custom' />
                </Link>
            </div>
        );
    };

    const renderCustomerNameColumn = (rowData: User) => {
        const [name, email] = rowData.name.split('\n');
        return (
            <div className="table-profileimage">
                <div className="ml-2">
                    <span>{name}</span>
                    <br />
                    <span>{email}</span>
                </div>
            </div>
        );
    };

    const rowNumberTemplate = (rowData: User, { rowIndex }: { rowIndex: number }) => {
        return rowIndex + 1;
    };

    return (
        <>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <div className="content-page-header content-page-headersplit">
                        <h5>Customers</h5>
                        <div className="list-btn">
                            <ul>
                                <li>
                                    <div className="filter-sorting">
                                        <ul>
                                            <li>
                                                <Link to="#" className="filter-sets">
                                                    <Icon.Filter className="react-feather-custom me-2"></Icon.Filter>
                                                    Filter
                                                </Link>
                                            </li>
                                            <li>
                                                <span>
                                                    <ImageWithBasePath
                                                        src="assets/img/icons/sort.svg"
                                                        className="me-2"
                                                        alt="img"
                                                    />
                                                </span>
                                                <div className="review-sort">
                                                    <Dropdown
                                                        value={selectedValue}
                                                        onChange={(e) => setSelectedValue(e.value)}
                                                        options={value}
                                                        optionLabel="name"
                                                        placeholder="A - Z"
                                                        className="select admin-select-breadcrumb"
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-customer"
                                    >
                                        <i className="fa fa-plus me-2" />
                                        Add Customer
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-resposnive table-div">
                            <table className="table datatable">
                                <DataTable
                                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                    value={data}
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    showGridlines
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column field="index" header="#" body={rowNumberTemplate}></Column>
                                    <Column sortable field="name" header="Customer Name" body={renderCustomerNameColumn}></Column>
                                    <Column sortable field="mobile_no" header="Mobile"></Column>
                                    <Column sortable field="email" header="Email"></Column>
                                    <Column sortable field="created_at" header="Date" body={renderDate}></Column>
                                  
                                    <Column sortable field="action" header="Action" body={actionButton}></Column>
                                    </DataTable>
                                    </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <CustomersModal selectedUser={selectedUser} />
            <UpdateCustomer selectedUser={selectedUser}/>
            <DeleteCustomersModal />
        </>
    );
};

export default Customers;
