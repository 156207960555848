export const subCategories = [
  {
    id: 1,
    subCategory: 'Service',
    subCategorySlug: 'service',
    category: 'Computer',
    Date: '28 Sep 2023',
    Action: '',
  },
  {
    id: 2,
    subCategory: 'Car',
    subCategorySlug: 'car',
    Date: '17 Sep 2023',
    category: 'Automobile',
    Action: '',
  },
  {
    id: 3,
    subCategory: 'Polish',
    subCategorySlug: 'polish',
    Date: '13 Sep 2023',
    category: 'Car Wash',
    Action: '',
  },
  {
    id: 4,
    subCategory: 'Sofa',
    subCategorySlug: 'Sofa',
    Date: '07 Sep 2023',
    category: 'Cleaning',
    Action: '',
  },
  {
    id: 5,
    subCategory: 'Building',
    subCategorySlug: 'building',
    Date: '07 Sep 2023',
    category: 'Construction',
    Action: '',
  },
  {
    id: 6,
    subCategory: 'Decoration',
    subCategorySlug: 'decoration',
    Date: '07 Sep 2023',
    category: 'Painting',
    Action: '',
  },
  {
    id: 7,
    subCategory: 'Wall',
    subCategorySlug: 'wall',
    Date: '07 Sep 2023',
    category: 'interior',
    Action: '',
  },
  {
    id: 8,
    subCategory: 'Fitting',
    subCategorySlug: 'fitting',
    Date: '10 Sep 2023',
    category: 'Plumbing',
    Action: '',
  },
];
