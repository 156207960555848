// api.js

const BASE_URL = process.env.REACT_APP_API_URL; 

export async function loginUser(email:any, password:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
}
export async function registerUser(name:any, email:any, password:any, confirmPassword:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, password, confirm_password: confirmPassword }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Registration failed');
    }
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
}
export async function buddyRegister(name:any, email:any, password:any, confirmPassword:any) {
    try {
      const response = await fetch(`${BASE_URL}/v1/auth/buddy-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password, confirm_password: confirmPassword }),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Registration failed');
      }
    } catch (error) {
      console.error('Error registering Power Buddy:', error);
      throw error;
    }
}
export async function verifyUser(otp: string, token: string) {
    try {
      const response = await fetch(`${BASE_URL}/v1/auth/verify-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ otp }),
      });
      const data = await response.json();
      if (data.data.isSuccess == true) {
        return data;
      } else if (response.ok) {
        return data;
      } else {
        throw new Error(data.message || 'Verification failed');
      }
    } catch (error) {
      console.error('Error verifying user:', error);
      throw error;
    }
}   
export async function changePassword(confirmPassword:any, newPassword:any, otp:any, token:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ confirm_password: confirmPassword, new_password: newPassword, otp }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Change password failed');
    }
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
}
export async function updateProfile(name:any, email:any, mobile:any, gender:any, address:any, city:any, state:any, country:any, token:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/update-profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ name, email, mobile, gender, address, city, state, country }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Update profile failed');
    }
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
}
export async function sendOTP(token:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/send-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Send OTP failed');
    }
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
}
export async function getMe(token:any, otp:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/get-me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ otp }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get me failed');
    }
  } catch (error) {
    console.error('Error getting me:', error);
    throw error;
  }
}
export async function forgotPassword(email:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ email }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Forgot Password request failed');
    }
  } catch (error) {
    console.error('Error in forgotPassword:', error);
    throw error;
  }
}
export async function setNewPassword(email: any, otp: any, newPassword: any, confirmPassword: any, token: any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/set-new-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
      body: new URLSearchParams({
        email,
        otp,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Set New Password request failed');
    }
  } catch (error) {
    console.error('Error in setNewPassword:', error);
    throw error;
  }
}


// Admin- related - APIs

export async function adminLogin(email:any, password:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Login failed');
    }
  } catch (error) {
    console.error('Error in adminLogin:', error);
    throw error;
  }
}
export async function getUserList(token:any, page = 1, perPage = 100000) {
  try {
    const response = await fetch(`${BASE_URL}/v1/admin/get-users?page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch user list');
    }
  } catch (error) {
    console.error('Error in getUserList:', error);
    throw error;
  }
}
export async function getUserById(token:any, userId:any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/admin/get-user/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch user details');
    }
  } catch (error) {
    console.error('Error in getUserById:', error);
    throw error;
  }
}
export async function updateUserById(token: string, userId: string, userData: any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/admin/update-user/${userId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(userData),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update user details');
    }
  } catch (error) {
    console.error('Error in updateUserById:', error);
    throw error;
  }
}




// Category-related APIs

export async function createCategory(token:string, name: string, slug: string, image: string, isFeatured: boolean) {
  const data = new URLSearchParams();
  data.append('name', name);
  data.append('slug', slug);
  data.append('image', image);
  data.append('is_featured', String(isFeatured));

  try {
    const response = await fetch(`${BASE_URL}/v1/category/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
      body: data,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Create category failed');
    }
  } catch (error) {
    console.error('Error creating category:', error);
    throw error;
  }
}
export async function getCategoryList(token:string, page = 1, perPage = 100000) {
  try {
    const response = await fetch(`${BASE_URL}/v1/category?page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get category list failed');
    }
  } catch (error) {
    console.error('Error fetching category list:', error);
    throw error;
  }
}
export async function getCategoryById(token:string,id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/category/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get category by ID failed');
    }
  } catch (error) {
    console.error('Error fetching category by ID:', error);
    throw error;
  }
}
export async function updateCategory(token: string, id: string, name: string, slug: string, image: string, isFeatured: boolean) {
  const data = new URLSearchParams();
  data.append('name', name);
  data.append('slug', slug);
  data.append('image', image);
  data.append('is_featured', String(isFeatured));

  try {
    const response = await fetch(`https://papi.embteltestdomain.com/v1/category/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
      body: data,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Update category failed');
    }
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
}
export async function deleteCategoryById(token: string, id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/category/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      return { message: 'Category deleted successfully' };
    } else {
      throw new Error('Delete category failed');
    }
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error;
  }
}



// Sub-Category-related APIs

export async function createSubCategory(token: string, name: string, slug: string, image: string, isFeatured: boolean, category_id:string) {
  const data = new URLSearchParams();
  data.append('name', name);
  data.append('slug', slug);
  data.append('image', image);
  data.append('is_featured', String(isFeatured));
  data.append('category_id',category_id)

  try {
    const response = await fetch(`${BASE_URL}/v1/sub-category/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
      body: data,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Create sub-category failed');
    }
  } catch (error) {
    console.error('Error creating sub-category:', error);
    throw error;
  }
}
export async function getSubCategoryList(token: string, page = 1, perPage = 100000) {
  try {
    const response = await fetch(`${BASE_URL}/v1/sub-category?page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get sub-category list failed');
    }
  } catch (error) {
    console.error('Error fetching sub-category list:', error);
    throw error;
  }
}
export async function getSubCategoryById(token: string, id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/sub-category/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get sub-category by ID failed');
    }
  } catch (error) {
    console.error('Error fetching sub-category by ID:', error);
    throw error;
  }
}
export async function updateSubCategory(token: string, id: string, name: string, slug: string, image: string, isFeatured: boolean, category_id:string) {
  const data = new URLSearchParams();
  data.append('name', name);
  data.append('slug', slug);
  data.append('image', image);
  data.append('is_featured', String(isFeatured));
  data.append('category_id',category_id)
  try {
    const response = await fetch(`${BASE_URL}/v1/sub-category/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
      body: data,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Update sub-category failed');
    }
  } catch (error) {
    console.error('Error updating sub-category:', error);
    throw error;
  }
}
export async function deleteSubCategoryById(token: string, id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/sub-category/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      return { message: 'Sub-category deleted successfully' };
    } else {
      throw new Error('Delete sub-category failed');
    }
  } catch (error) {
    console.error('Error deleting sub-category:', error);
    throw error;
  }
}



//  services APIs
export async function createService(token: string, postData: any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/service`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    });

    if (response.ok) {
      return { message: 'Service created successfully', data: await response.json() };
    } else {
      throw new Error('Create service failed');
    }
  } catch (error) {
    console.error('Error creating service:', error);
    throw error;
  }
}
export async function getServiceList(token: string, page = 1, perPage = 100000) {
  try {
    const response = await fetch(`${BASE_URL}/v1/service?page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get service list failed');
    }
  } catch (error) {
    console.error('Error fetching service list:', error);
    throw error;
  }
}
export async function getServiceById(token: string, id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/service/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Get service by ID failed');
    }
  } catch (error) {
    console.error('Error fetching service by ID:', error);
    throw error;
  }
}
export async function updateServiceById(token: string, id: string, updatedData: any) {
  try {
    const response = await fetch(`${BASE_URL}/v1/service/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Update service failed');
    }
  } catch (error) {
    console.error('Error updating service:', error);
    throw error;
  }
}
export async function deleteServiceById(token: string, id: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/service/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      return { message: 'Service deleted successfully' };
    } else {
      throw new Error('Delete service failed');
    }
  } catch (error) {
    console.error('Error deleting service:', error);
    throw error;
  }
}

// front end categories page 
export async function fetchCategories() {
  try {
    const response = await fetch(`${BASE_URL}/v1/category/list`);
    if (response.ok) {
      const data = await response.json();
      if (data.status === 200 && data.data.isSuccess) {
        return data.data.payload.records;
      } else {
        throw new Error('Failed to fetch categories');
      }
    } else {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
}

// contact us form in front end 
export async function createContact(contactData: { name: string; phone: string; email: string; message: string; subject: string }) {
  try {
    const response = await fetch(`${BASE_URL}/v1/contact/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Failed to create contact');
    }
  } catch (error) {
    console.error('Error creating contact:', error);
    throw error;
  }
}
export async function fetchServiceList() {
  try {
    const response = await fetch(`${BASE_URL}/v1/service/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Failed to fetch service list');
    }
  } catch (error) {
    console.error('Error fetching service list:', error);
    throw error;
  }
}


export async function getListOfAppointmentsAdmin(token: string) {
  try {
    const response = await fetch(`${BASE_URL}/v1/appointment/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Failed to fetch appointments');
    }
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
}
export async function createBooking(tokenBooking: string, bookingDetails: {
  service_id: string,
  city: string,
  state: string,
  country: string,
  booking_date: string,
  appointment_time: string
}) {
  try {
    const response = await fetch(`${BASE_URL}/v1/appointment/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${tokenBooking}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingDetails),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Booking creation failed');
    }
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
}



