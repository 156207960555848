import React, { useState } from 'react';
import ProviderHeader from '../common/header';
import ProviderSidebar from '../common/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';

const PaymentSetting = () => {
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedSub, setselectedSub] = useState();
  const category = [
    { name: 'Select Payment ' },
    { name: 'Bank Transfer' },
    { name: 'UPI' },
  ];

  return (
    <>
      <>
        <ProviderHeader />
        <ProviderSidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Profile settings */}
            <div className="row">
              <div className="col-md-12">
                {/* Account Settings */}
                <div className="widget-title">
                  <h4>Payment Settings</h4>
                </div>
                <div className="general-info">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Account No</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Account No"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bank Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Bank Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bank Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">IFSC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter IFSC Code"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Pan No</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Pan No"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Sort Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Sort Code"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Routing No</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Routing No"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Email Id</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Contact No</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Contact No"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Payment Mode</label>
                        <Dropdown
                          value={selectedLanguage}
                          onChange={(e) => setselectedLanguage(e.value)}
                          options={category}
                          optionLabel="name"
                          placeholder="Select Paymentmode"
                          className="select service-select"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Payment Purpose
                        </label>

                        <Dropdown
                          value={selectedSub}
                          onChange={(e) => setselectedSub(e.value)}
                          options={category}
                          optionLabel="name"
                          placeholder="Select PaymentPurpose"
                          className="select service-select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="acc-submit">
                  <Link to="#" className="btn btn-secondary">
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-primary">
                    Save Changes
                  </Link>
                </div>
              </div>
              {/* /Account Settings */}
            </div>
            {/* /profile-settings */}
          </div>
        </div>
      </>
    </>
  );
};

export default PaymentSetting;
