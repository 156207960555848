import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { all_routes } from '../../../../core/data/routes/all_routes';

const CustomerBookingCalendar = () => {
  const routes = all_routes;
  const [selectedSub, setselectedSub] = useState(null);
  const sub = [{ name: 'Most Helpful' }, { name: 'A -&gt; Z' }];
  const events = [{ title: 'Meeting', start: new Date() }];
  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="widget-title">
            <h4>Booking List</h4>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div className="review-sort cus-fav-sort me-2">
            <p>Sort</p>
            <Dropdown
              value={selectedSub}
              onChange={(e) => setselectedSub(e.value)}
              options={sub}
              optionLabel="name"
              placeholder="English"
              className="select provider-select select-space"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to={routes.customerFavourite} className="active">
                  <Icon.Calendar  className="react-feather-custom"/>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Filter className="react-feather-custom"/>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Grid className="react-feather-custom"/>
                </Link>
              </li>
              <li>
                <Link to={routes.customerBooking}>
                  <Icon.List className="react-feather-custom"/>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
      <div id="calendar-book" />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        events={events}
      />
    </div>
  );
};

export default CustomerBookingCalendar;
