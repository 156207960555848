import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import * as Icon from 'react-feather';
import { all_routes } from '../../../core/data/routes/all_routes';

import { AllserviceInterface } from '../../../core/models/interface';
import {
  getServiceList,
  getCategoryList,
  deleteServiceById,
} from '../../../core/data/api/api';
import ServiceModal from '../common/modals/service-modal';
import UpdateServiceModal from '../common/modals/service-update-modal'



const AllService = () => {
  const routes = all_routes;
  const [data, setData] = useState<AllserviceInterface[]>([]);
  const [filteredData, setFilteredData] = useState<AllserviceInterface[]>([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [token, setToken] = useState<string>('');
  const value = [{ name: 'A - Z' }, { name: 'Z - A' }];
  const [selectedService, setSelectedService] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState({
    success: false,
    message: '',
    visible: false,
  });
  const [deleting, setDeleting] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const result = await getCategoryList(token);
        setCategoryData(result.data.payload.records);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    if (token) {
      fetchCategoryData();
    }
  }, [token]);

  const handleCategoryChange = (e: any) => {
    setSelectedCategoryId(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const serviceData = await getServiceList(token);
          if (
            serviceData &&
            serviceData.data.payload.data &&
            Array.isArray(serviceData.data.payload.data)
          ) {
            setData(serviceData.data.payload.data);
          } else {
            console.error(
              'Service data is not an array:',
              serviceData.data.payload.data,
            );
          }
        }
      } catch (error) {
        console.error('Error fetching service list:', error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    if (selectedCategoryId) {
      setFilteredData(
        data.filter(
          (service) => service.sub_category.category_id === selectedCategoryId,
        ),
      );
    } else {
      setFilteredData(data);
    }
  }, [selectedCategoryId, data]);

  const renderBody = (res: AllserviceInterface) => (
    <Link to="#" className="table-imgname">
      <span>{res.title}</span>
    </Link>
  );

  const renderBody2 = (res: AllserviceInterface) => (
    <h6
      className={`${
        res.status === 'Active'
          ? 'badge-active'
          : res.status === 'Delete'
            ? 'badge-delete'
            : res.status === 'Pending'
              ? 'badge-pending'
              : res.status === 'Inactive'
                ? 'badge-inactive'
                : ''
      }`}
    >
      {res.status}
    </h6>
  );
  const handleRowClick = (rowData: any) => {
    setSelectedService(rowData);
  };
  const actionButton = (rowData: any) => (
    <div className="action-language">
      <button
        className="btn btn-primary me-2"
        data-bs-toggle="modal"
        data-bs-target="#edit-service"
        onClick={() => handleRowClick(rowData.id)}
      >
        Show details
      </button>

      <Link
        className="delete-table me-3"
        to='#'
        data-bs-toggle="modal"
        data-bs-target="#update-service"
        onClick={() => handleRowClick(rowData.id)}
      >
        <Icon.Edit className="react-feather-custom" />
      </Link>

      <Link
        className="delete-table border-none"
        onClick={() => setServiceToDelete(rowData.id)}
        to="#"
        data-bs-toggle="modal"
        data-bs-target="#delete-item"
      >
        <Icon.Trash2 className="react-feather-custom" />
      </Link>
    </div>
  );

  const renderIndex = (rowData: any, { rowIndex }: any) => rowIndex + 1;

  const handleDelete = async (serviceId: any) => {
    setDeleting(true);
    try {
      await deleteServiceById(token, serviceId);
      setDeleteStatus({
        success: true,
        message: 'Service deleted successfully',
        visible: true,
      });
      setData((prevData) =>
        prevData.filter((service) => service.id !== serviceId),
      );
    } catch (error) {
      setDeleteStatus({
        success: false,
        message: 'Error deleting service',
        visible: true,
      });
    } finally {
      setDeleting(false);
      setTimeout(
        () => setDeleteStatus({ success: false, message: '', visible: false }),
        100000,
      );
    }
  };

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>All Services</h5>
            <div className="list-btn">
              <ul>
                <li>
                  <select
                    className="form-select"
                    value={selectedCategoryId}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select Category</option>
                    {categoryData.map((category: any) => (
                      <option
                        className="mb-1"
                        key={category.id}
                        value={category.id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <Icon.Filter className="react-feather-custom me-2" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <ImageWithBasePath
                            src="assets/admin/img/icons/sort.svg"
                            className="me-2"
                            alt="img"
                          />
                        </span>
                        <div className="review-sort">
                          <Dropdown
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.value)}
                            options={value}
                            optionLabel="name"
                            placeholder="A - Z"
                            className="select admin-select-breadcrumb"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link className="btn btn-primary" to={routes.addServices}>
                    <i className="fa fa-plus me-2" />
                    Create Services{' '}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to="services" className="active">
                        All Services
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="tab-contents-count">
                  <h6>Showing 8-10 of 84 results</h6>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-resposnive table-div">
                <DataTable
                  paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  value={Array.isArray(filteredData) ? filteredData : []}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  tableStyle={{ minWidth: '50rem' }}
                >
                  <Column header="#" body={renderIndex} />
                  <Column
                    sortable
                    field="title"
                    header="Service"
                    body={renderBody}
                  />

                  <Column sortable field="price" header="Price" />
                  <Column sortable field="duration" header="Duration" />

                  <Column
                    sortable
                    field="sub_category.name"
                    header="Sub Category"
                  />
                  <Column
                    sortable
                    field="status"
                    header="Status"
                    body={renderBody2}
                  />
                  <Column header="Action" body={actionButton} />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="delete-item"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {deleteStatus.visible ? (
              <div className="modal-body mx-auto">
                <div className="">
                  <h5 className="">{deleteStatus.message}</h5>
                </div>
                <div className="ml-5">
                  <button
                    type="button"
                    className="btn btn-secondary "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      setDeleteStatus({
                        success: false,
                        message: '',
                        visible: false,
                      })
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleDelete(serviceToDelete);
                }}
              >
                <button
                  type="button"
                  className="delete-popup"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-regular fa-rectangle-xmark" />
                </button>
                <div className="del-modal">
                  <h5>Do you really want to delete this service?</h5>
                  <p>Plumbing Service</p>
                </div>
                <div className="delete-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn modal-delete"
                    disabled={deleting}
                  >
                    {deleting ? 'Deleting...' : 'Delete'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <ServiceModal selectedService={selectedService} />
      <UpdateServiceModal selectedService={selectedService}/>
    </>
  );
};

export default AllService;
