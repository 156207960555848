import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { payout } from '../../../../core/data/json/payout';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { payoutDatas } from '../../../../core/models/interface';

const ProviderPayout = () => {
  const [data, setData] = useState([{}]);
  useEffect(() => {
    setData(payout);
  }, []);

  const columns = [
    { field: 'payoutDate', header: 'Payout Date' },
    { field: 'amount', header: 'Amount' },
    { field: 'refunds', header: 'Refunds' },
    { field: 'fees', header: 'Fees' },
    { field: 'total', header: 'Total' },
    { field: 'status', header: 'Status' },
    { field: 'paymentProcessed', header: 'Payment Processed' },
  ];
  const renderBody = (res: payoutDatas) => {
    return (
      <span
        className={` badge ${
          res.status == 'Paid' ? 'badge-success' : 'badge-warning'
        }`}
      >
        {res.status}
      </span>
    );
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Payout</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <Link
                to="#"
                className="btn btn-primary add-set"
                data-bs-toggle="modal"
                data-bs-target="#add-payout"
              >
                <Icon.Settings className="react-feather-icon me-2" />
                Set Payout
              </Link>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {/* Payout card */}
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/bal-icon.svg"
                        alt="Icon"
                        className={''}
                      />
                    </span>
                    <div className="balance-info">
                      <h6>Available Payout</h6>
                      <h3>
                        $
                        <CountUp end={180} duration={5} />
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <Link to="#" className="btn btn-primary view-transaction">
                      View Transactions
                    </Link>
                    <Link to="#" className="btn btn-secondary  btn-withdraw">
                      Withdraw
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/bal-icon.svg"
                        alt="Icon"
                        className={''}
                      />
                    </span>
                    <div className="balance-info">
                      <h6>Last Payout</h6>
                      <h3>
                        $
                        <CountUp end={200} duration={5} />
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <Link to="#" className="btn btn-secondary view-transaction">
                      View Transactions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/bal-icon.svg"
                        alt="Icon"
                        className={''}
                      />
                    </span>
                    <div className="balance-info">
                      <h6>Next Payout</h6>
                      <h3>
                        $
                        <CountUp end={200} duration={5} />
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <Link to="#" className="btn btn-secondary view-transaction">
                      View Transactions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Payout card */}
        </div>
        {/* Payout History */}
        <div className="row">
          <div className="col-md-12">
            <h6 className="subhead-title">Payout History</h6>
            <div className="provide-table manage-table">
              <div className="table-responsive">
                {/* <table className="table custom-table datatable" id="data-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Payout Date</th>
                      <th>Amount</th>
                      <th>Refunds</th>
                      <th>Fees</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Payment Processed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sep 26, 2023</td>
                      <td>$100.00</td>
                      <td className="text-light-danger">-$10.00</td>
                      <td className="text-light-danger">-$10.00</td>
                      <td>$90.00</td>
                      <td>
                        <span className="badge badge-warning">Pending</span>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>Sep 21, 2023</td>
                      <td>$100.00</td>
                      <td>-$0.00</td>
                      <td className="text-light-danger">-$10.00</td>
                      <td>$90.00</td>
                      <td>
                        <span className="badge-success">Paid</span>
                      </td>
                      <td>Sep 11, 2023</td>
                    </tr>
                    <tr>
                      <td>Sep 21, 2023</td>
                      <td>$100.00</td>
                      <td className="text-light-danger">-$0.00</td>
                      <td>-$10.00</td>
                      <td>$90.00</td>
                      <td>
                        <span className="badge-success">Paid</span>
                      </td>
                      <td>Sep 11, 2023</td>
                    </tr>
                    <tr>
                      <td>Sep 21, 2023</td>
                      <td>$100.00</td>
                      <td>-$0.00</td>
                      <td>-$10.00</td>
                      <td>$90.00</td>
                      <td>
                        <span className="badge-success">Paid</span>
                      </td>
                      <td>Sep 11, 2023</td>
                    </tr>
                    <tr>
                      <td>Sep 21, 2023</td>
                      <td>$100.00</td>
                      <td>-$0.00</td>
                      <td>-$10.00</td>
                      <td>$90.00</td>
                      <td>
                        <span className="badge-success">Paid</span>
                      </td>
                      <td>Sep 11, 2023</td>
                    </tr>
                  </tbody>
                </table> */}
                <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  className="table custom-table datatable"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  value={data}
                  tableStyle={{ minWidth: '50rem' }}
                >
                  {columns.map((col) =>
                    col.field == 'status' ? (
                      <Column
                        key={col.field}
                        sortable
                        field={col.field}
                        header={col.header}
                        body={renderBody}
                      />
                    ) : (
                      <Column
                        key={col.field}
                        sortable
                        field={col.field}
                        header={col.header}
                      />
                    ),
                  )}
                </DataTable>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div id="tablelength" />
              </div>
              <div className="col-md-9">
                <div className="table-ingopage">
                  <div id="tableinfo" />
                  <div id="tablepagination" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Payout History */}
      </div>
      <div>
        {/* Add Wallet */}
        <div className="modal fade custom-modal" id="add-payout">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content doctor-profile">
              <div className="modal-header border-bottom-0 justify-content-between pb-0">
                <h5 className="modal-title">Set Your Payouts</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="feather-x" />
                  <Icon.X className="react-feather-icon" />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="wallet-add">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="bank-selection">
                          <input
                            type="radio"
                            defaultValue="attach_link"
                            id="rolelink"
                            name="attachment"
                            defaultChecked
                          />
                          <label htmlFor="rolelink">
                            <ImageWithBasePath
                              src="assets/img/paypal.png"
                              alt="Paypal"
                              className={''}
                            />
                            <span className="role-check">
                              <i className="fa-solid fa-circle-check" />
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bank-selection">
                          <input
                            type="radio"
                            defaultValue="attach_link"
                            id="rolelink1"
                            name="attachment"
                          />
                          <label htmlFor="rolelink1">
                            <ImageWithBasePath
                              src="assets/img/stripe.png"
                              alt="Stripe"
                              className={''}
                            />
                            <span className="role-check">
                              <i className="fa-solid fa-circle-check" />
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bank-selection">
                          <input
                            type="radio"
                            defaultValue="attach_link"
                            id="rolelink2"
                            name="attachment"
                          />
                          <label htmlFor="rolelink2">
                            <ImageWithBasePath
                              src="assets/img/bank.png"
                              alt="image"
                              className={''}
                            />
                            <span className="role-check">
                              <i className="fa-solid fa-circle-check" />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Card Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Card Number"
                      />
                    </div>
                    <div className="modal-submit text-end">
                      <Link
                        to="#"
                        className="btn btn-secondary me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Wallet */}
      </div>
    </div>
  );
};

export default ProviderPayout;
