import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CreateService from './create-service/create-service';
import ProviderDetails from './providers/provider-details';
import ServiceDetails2 from './service-details';
import ServiceGrid from './service-grid/service';
import Search from './search/search';
import SearchList from './search/search-list';
import ProvidersList from './providers/providers-list';

const ServicesRoutes = () => {
  const all_services_routes = [
    {
      path: '/create-service',
      name: 'create-service',
      element: <CreateService />,
      route: Route,
    },
    {
      path: '/providers/provider-details',
      name: 'provider-details',
      element: <ProviderDetails />,
      route: Route,
    },
    {
      path: '/providers/provider-list',
      name: 'provider',
      element: <ProvidersList />,
      route: Route,
    },
    {
      path: '/service',
      name: 'ServiceGrid',
      element: <ServiceGrid />,
      route: Route,
    },
    {
      path: '/search',
      name: 'search',
      element: <Search />,
      route: Route,
    },
    {
      path: '/search-list',
      name: 'search-list',
      element: <SearchList />,
      route: Route,
    },
    {
      path: '/service-details',
      name: 'service-details-2',
      element: <ServiceDetails2 />,
      route: Route,
    },
    {
      path: '*',
      name: 'NotFound',
      element: <Navigate to="/" />,
      route: Route,
    },
  ];

  return (
    <Routes>
      {all_services_routes.map((route, idx) => (
        <Route path={route.path} element={route.element} key={idx} />
      ))}
    </Routes>
  );
};

export default ServicesRoutes;
