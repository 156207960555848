export const blogData = [
  {
    id: 1,
    img: 'assets/admin/img/services/service-03.jpg',
    category: 'Construction',
    language: 'English',
    date: '28 Sep 2023',
    Action: '',
  },
  {
    id: 2,
    img: 'assets/admin/img/services/service-02.jpg',
    category: 'Car Wash',
    language: 'English',
    date: '17 Sep 2023',
    Action: '',
  },
  {
    id: 3,
    img: 'assets/admin/img/services/service-04.jpg',
    category: 'Computer',
    language: 'English',
    date: '13 Sep 2023',
    Action: '',
  },
  {
    id: 4,
    img: 'assets/admin/img/services/service-09.jpg',
    category: 'Electrical',
    language: 'English',
    date: '07 Sep 2023',
    Action: '',
  },
  {
    id: 5,
    img: 'assets/admin/img/services/service-08.jpg',
    category: 'Cleaning',
    language: 'English',
    date: '07 Sep 2023',
    Action: '',
  },
  {
    id: 6,
    img: 'assets/admin/img/services/service-07.jpg',
    category: 'Interior',
    language: 'English',
    date: '07 Sep 2023',
    Action: '',
  },
  {
    id: 7,
    img: 'assets/admin/img/services/service-09.jpg',
    category: 'Carpentry',
    language: 'English',
    date: '22 Aug 2023',
    Action: '',
  },
  {
    id: 8,
    img: 'assets/admin/img/services/service-12.jpg',
    category: 'Plumbing',
    language: 'English',
    date: '15 Aug 2023',
    Action: '',
  },
];
