export const commentsData = [
  {
    id: 1,
    img: 'assets/admin/img/customer/user-01.jpg',
    userName: 'John Smith',
    email: 'johnsmith@example.com',
    phone: '+1 843-443-3282 ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '28 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 2,
    img: 'assets/admin/img/customer/user-07.jpg',
    userName: 'Johnny',
    email: 'johnny@example.com',
    phone: '+1 917-409-0861 ',
    content: 'Lorem ipsum dolor ',
    createdat: '23 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 3,
    img: 'assets/admin/img/customer/user-09.jpg',
    userName: 'Robert',
    email: 'robert@example.com',
    phone: '+1 956-623-2880 ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '08 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 4,
    img: 'assets/admin/img/customer/user-06.jpg',
    userName: 'Sharonda',
    email: 'sharonda@example.com',
    phone: '+1 559-741-9672 ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '02 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 5,
    img: 'assets/admin/img/customer/user-04.jpg',
    userName: 'Nicholas',
    email: 'nicholas@example.com',
    phone: '+1 559-741-9672 ',
    content: 'Lorem ipsum dolor ',
    createdat: '23 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 6,
    img: 'assets/admin/img/customer/user-01.jpg',
    userName: 'John Smith',
    email: 'johnsmith@example.com',
    phone: '+1 843-443-3282  ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '28 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 7,
    img: 'assets/admin/img/customer/user-07.jpg',
    userName: 'Johnny',
    email: 'johnny@example.com',
    phone: '+1 917-409-0861 ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '23 Sep 2023 16:43PM',
    Action: '',
  },
  {
    id: 8,
    img: 'assets/admin/img/customer/user-09.jpg',
    userName: 'Robert',
    email: 'robert@example.com',
    phone: '+1 956-623-2880 ',
    content: 'Lorem ipsum dolor sit amet, consectetur elit, ',
    createdat: '08 Sep 2023 16:43PM',
    Action: '',
  },
];
