import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import FooterOne from './footer-one';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../core/data/routes/all_routes';
import HomeHeader from './home-header';

const HomeOne = () => {
  const routes = all_routes;
  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const clientSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const partnerSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <HomeHeader type={1} />
      {/* Hero Section */}
      <section className="hero-section">
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center w-100">
              <div className="col-lg-7 col-md-10 mx-auto">
                <div className="section-search aos" data-aos="fade-up">
                  {/* <h1>Professional Services For Your Home &amp; Commercial </h1> */}
                  <h2>
                    Power Buddy: Your Ultimate Partner for All Your Needs, Right
                    at Your Doorstep
                  </h2>
                  <p>
                    Bringing comfort, care, and convenience to your doorstep.
                  </p>
                  {/* <div className="search-box">
                    <form>
                      <div className="search-input line">
                        <div className="search-group-icon">
                          <Icon.MapPin className="standard-feather" />
                        </div>
                        <div className="form-group mb-0">
                          <label>Your Location</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="America"
                          />
                        </div>
                      </div>
                      <div className="search-input">
                        <div className="search-group-icon search-icon">
                          <Icon.Search className="react-feather-custom" />
                        </div>
                        <div className="form-group mb-0">
                          <label>What are you looking for?</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Car Repair Services"
                          />
                        </div>
                      </div>
                      <div className="search-btn">
                        <Link className="btn btn-primary" to={routes.search}>
                          <Icon.Search className="react-feather-custom me-2" />
                          Search
                        </Link>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-imgs">
                  <div className="banner-1 shape-1">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner1.png"
                    />
                  </div>
                  <div className="banner-2 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner2.png"
                    />
                  </div>
                  <div className="banner-3 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner3.png"
                    />
                  </div>
                  <div className="banner-4 shape-2">
                    <ImageWithBasePath
                      className="img-responsive"
                      alt="banner"
                      src="assets/img/banner4.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Hero Section */}
      {/* Feature Section */}
      <section className="feature-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Featured Categories</h2>
                <p>Find Everything You Need, Right Here</p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <Link
                  to={routes.categories}
                  className="btn btn-primary btn-view"
                >
                  View All
                  <Icon.ArrowRightCircle className="standard-feather ms-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceGrid}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-01.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Construction</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-12.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceGrid}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-04.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Home Cleaning</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-09.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceGrid}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-05.svg"
                      alt="img"
                    />
                  </span>
                </div>
                {/* <h5>Interior</h5> */}
                <h5>Maintenance</h5>

                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-07.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.maintenance}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-02.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Car Wash</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-08.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.maintenance}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-03.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Electrical</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-01.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.maintenance}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-06.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Carpentry</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-03.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.maintenance}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-07.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Computer</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-06.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.maintenance}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-08.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Plumbing</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-11.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Feature Section */}
      {/* Choose Us */}
      <section className="reason-choose-us">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row">
              <div className="col-md-6 col-12 aos" data-aos="fade-up">
                <div className="reason-six">
                  {/* <p>WHY TRUELY SELL?</p> */}
                </div>
                <h2>
                  Reasons to <span>Choose Us</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="total-client-all">
                <div>
                  <p className="total-client-content">
                    Our professional cleaning service comes up with a complete
                    solution that makes your space sparkle!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/quality-cleaning.svg"
                  alt="image"
                />
                <h5>High Quality Cleaning</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/cdn-globe.svg"
                  alt="image"
                />
                <h5>Global Locations</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/technical-support.svg"
                  alt="image"
                />
                <h5>Anytime Support</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/money-cash.svg"
                  alt="image"
                />
                <h5>Spend Efficiently</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Choose Us */}
      {/* Service Section */}
      <section className="service-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Explore our Key Services</h2>
                <p>
                  Discover the best of our offerings. You will not be
                  disappointed.
                </p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <div className="owl-nav mynav" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings} className="service-slider">
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceGrid}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-11.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={1}
                      onClick={() => handleItemClick(1)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Construction</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[1] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceGrid}>
                        Construction Services
                      </Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      39159 Paseo Padre Parkway #119, Fremont, CA 94538
                    </p>
                    <div className="serv-info">
                      <h6>
                        {/* $25.00<span className="old-price">$35.00</span> */}
                      </h6>
                      <Link
                        to={routes.serviceGrid}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceGrid}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-09.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={2}
                      onClick={() => handleItemClick(2)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Cleaning </span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[2] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceGrid}>Cleaning Services</Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      101 Jefferson Drive, 1st floor, Menlo Park C.A 94025
                    </p>
                    <div className="serv-info">
                      <h6>
                        {/* $25.00<span className="old-price">$35.00</span> */}
                      </h6>
                      <Link
                        to={routes.serviceGrid}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceGrid}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-12.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={3}
                      onClick={() => handleItemClick(3)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Maintenance</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[3] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceGrid}>
                        Maintenance Services
                      </Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      101 Jefferson Drive, 1st floor, Menlo Park C.A 94025
                    </p>
                    <div className="serv-info">
                      <h6>
                        {/* $25.00<span className="old-price">$35.00</span> */}
                      </h6>
                      <Link
                        to={routes.serviceGrid}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="btn-sec aos" data-aos="fade-up">
              <Link
                to={routes.serviceGrid}
                className="btn btn-primary btn-view"
              >
                View All
                <Icon.ArrowRightCircle className="standard-feather ms-1" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Service Section */}
      {/* Work Section */}
      <section className="work-section pt-0">
        {/* <div className="container">
          <div className="row  aos" data-aos="fade-up">
            <div className="col-md-12">
              <div className="offer-paths">
                <div className="offer-pathimg">
                  <ImageWithBasePath src="assets/img/offer.png" alt="img" />
                </div>
                <div className="offer-path-content">
                  <h3>We Are Offering 14 Days Free Trial</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore minim
                    veniam, quis nostrud exercitation ullamco magna aliqua.{' '}
                  </p>
                  <Link
                    to={routes.freeTrail}
                    className="btn btn-primary btn-views"
                  >
                    Try 14 Days Free Trial
                    <Icon.ArrowRightCircle className="standard-feather ms-1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-heading aos" data-aos="fade-up">
                <h2 className="mt-5">How It Works</h2>
                <p>Aliquam lorem ante, dapibus in, viverra quis</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link to={routes.howItWorks}>
                <div className="work-box aos" data-aos="fade-up">
                  <div className="work-icon">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/work-icon.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <h5>Select Your Service</h5>
                  <p>
                    Find the right service for you by browsing our
                    categories.Make your work easier and more professional
                  </p>
                  <h4>01</h4>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to={routes.howItWorks}>
                <div className="work-box aos" data-aos="fade-up">
                  <div className="work-icon">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/find-icon.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <h5>Book a Slot</h5>
                  <p>
                    Please fill out the form with your desired date and time.
                    Make sure all the details are right to avoid delays.
                  </p>
                  <h4>02</h4>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to={routes.howItWorks}>
                <div className="work-box aos" data-aos="fade-up">
                  <div className="work-icon">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/place-icon.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <h5>Submit your Reservation</h5>
                  <p>
                    Once everything is finalized, send in the form. A
                    confirmation email along with any further instructions will
                    be sent to you.
                  </p>
                  <h4>03</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Work Section */}
      {/* Service Section */}
      <section className="service-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Most Popular Services</h2>
                <p>
                  Services are most frequently utilized by people in the US.
                </p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <div className="owl-nav mynav1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="popular-slider">
                <Slider {...settings}>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceGrid}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-11.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={1}
                        onClick={() => handleItemClick(1)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Construction</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[1] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceGrid}>
                          Construction Services
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        39159 Paseo Padre Parkway #119, Fremont, CA 94538
                      </p>
                      <div className="serv-info">
                        <h6>
                          {/* $25.00<span className="old-price">$35.00</span> */}
                        </h6>
                        <Link
                          to={routes.serviceGrid}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceGrid}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-09.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={2}
                        onClick={() => handleItemClick(2)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Cleaning </span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[2] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceGrid}>
                          Cleaning Services
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        101 Jefferson Drive, 1st floor, Menlo Park C.A 94025
                      </p>
                      <div className="serv-info">
                        <h6>
                          {/* $25.00<span className="old-price">$35.00</span> */}
                        </h6>
                        <Link
                          to={routes.serviceGrid}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceGrid}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-12.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={3}
                        onClick={() => handleItemClick(3)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Maintenance</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[3] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceGrid}>
                          Maintenance Services
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        101 Jefferson Drive, 1st floor, Menlo Park C.A 94025
                      </p>
                      <div className="serv-info">
                        <h6>
                          {/* $25.00<span className="old-price">$35.00</span> */}
                        </h6>
                        <Link
                          to={routes.serviceGrid}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="btn-sec aos" data-aos="fade-up">
              <Link
                to={routes.serviceGrid}
                className="btn btn-primary btn-view"
              >
                View All
                <Icon.ArrowRightCircle className="standard-feather ms-1" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Service Section */}
      
      {/* Client Section */}
      <section className="client-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>What our client says</h2>
                <p>
                  Read the Client Testimonials to see what our clients have to
                  say.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...clientSlider}>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-01.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      Power Buddy has helped me a lot. It just exceeded my
                      excellent quality level! I had an emergency plumbing issue
                      late at night, and their team was at my door within an
                      hour. They quickly fixed the problem with professionalism
                      and efficiency. Thanks to Power Buddy, I can relax knowing
                      that my home is in great hands. {' '}
                    </p>
                    <h5>Jessica M.</h5>
                    <h6>Director</h6>
                  </div>
                </div>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-02.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      I got my electrical problem fixed at my house with Power
                      Buddy. The electrician who visited my house was kind and
                      knowledgeable. He identified the issue right away and in
                      no time at all. In a short time, he repaired everything. I
                      found their outstanding quality, and the pricing was
                      reasonable.{' '}
                    </p>
                    <h5>David L.</h5>
                    <h6>Lead</h6>
                  </div>
                </div>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-03.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      Because of my hectic schedule, I usually struggle to clean
                      my house. Power Buddy&#39;s excellent cleaning services
                      have helped me a lot. The group was on time; they arrived
                      at the time I had scheduled. I liked that they used
                      eco-friendly items and paid close attention to every
                      detail. {' '}
                    </p>
                    <h5>Emily R.</h5>
                    <h6>CEO</h6>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Client Section */}

      <FooterOne />
    </>
  );
};
export default HomeOne;
