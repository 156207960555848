import React from 'react';

const LoginActivity = () => {
  return (
    <>
      {/* Security Settings */}
      <div className="col-lg-9">
        <div className="widget-title">
          <h4>Login Activity</h4>
        </div>
        <div className="provide-table  manage-table">
          <div className="table-responsive"></div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div id="tablelength" />
          </div>
          <div className="col-md-7">
            <div className="table-ingopage">
              <div id="tableinfo" />
              <div id="tablepagination" />
            </div>
          </div>
        </div>
      </div>
      {/* /Security Settings */}
    </>
  );
};

export default LoginActivity;
