import React, { useState } from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { createContact } from '../../../../core/data/api/api';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    subject: 'Welcome'
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Reset messages
    setSuccessMessage('');
    setErrorMessage('');

    // Check if all required fields are filled
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      setErrorMessage('All fields must be filled');
      return;
    }

    setLoading(true);
    try {
      const response = await createContact(formData);
      // console.log('Contact created successfully:', response);
      setSuccessMessage('Contact created successfully');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        subject: 'Welcome'
      });

      // Remove success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error creating contact:', error);
      setErrorMessage('Error creating contact. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath src="assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Contact Us</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          {/* Contact Details */}
          <div className="contact-details">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Phone />
                  </span>
                  <div className="contact-data">
                    <h4>Phone Number</h4>
                    <p>(510) 962-7900</p>
                    {/* <p>(123) 456-7890</p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Mail />
                  </span>
                  <div className="contact-data">
                    <h4>Email Address</h4>
                    <p>
                      <Link to="mailto:hello@embtelsolutions.com">
                      hello@embtelsolutions.com
                      </Link>
                    </p>
                  
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.MapPin />
                  </span>
                  <div className="contact-data">
                    <h4>Address</h4>
                    <p>39159, Paseo Padre Pkwy, Suite #119, Fremont, CA 94538</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Contact Details */}
          {/* Get In Touch */}
          <div className="row">
            <div className="col-md-6">
              <div className="contact-img">
                <ImageWithBasePath
                  src="assets/img/contact.jpg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-queries">
                <h2>Get In Touch</h2>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Enter Name*"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Enter Email Address*"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Phone Number</label>
                        <input
                          className="form-control"
                          type="text"
                          name="phone"
                          placeholder="Enter Phone Number"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Message</label>
                        <textarea
                          className="form-control"
                          name="message"
                          rows={4}
                          placeholder="Type Message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary" type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Send Message'}
                        {loading ? (
                          <Icon.Loader className="standard-feather ms-2" />
                        ) : (
                          <Icon.ArrowRightCircle className="standard-feather ms-2" />
                        )}
                      </button>
                      {successMessage && <p className="text-success mt-2">{successMessage}</p>}
                      {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /Get In Touch */}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
