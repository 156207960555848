export const ProviderLogin = [
  {
    date: '19 Sep 2023, 10:20:20',
    device: 'Safari Macos',
    ipAddress: '333.555.10.54',
    location: 'Newyork / USA',
    status: 'Completed',
  },
  {
    device: 'Safari Macos',
    date: '21 Sep 2023, 13:10:50',
    ipAddress: '224.111.12.75',
    location: 'Newyork / USA',
    status: 'Completed',
  },
  {
    device: 'Firefox Windows',
    date: '20 Sep 2023, 14:16:10',
    ipAddress: '232.222.12.72',
    location: 'Newyork / USA',
    status: 'Completed',
  },
  {
    device: 'Safari Macos',
    date: '19 Sep 2023, 10:20:20',
    ipAddress: '333.555.10.54',
    location: 'Newyork / USA',
    status: 'Completed',
  },
];
