import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

const ProviderAppointmentSettings = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);
  const [selectedValue3, setSelectedValue3] = useState(null);

  const value = [{ name: '30 Min' }, { name: '40 Min' }, { name: '50 Min' }];
  const value2 = [{ name: '30 Minutes' }, { name: '40 Minutes' }, { name: '50 Minutes' }];
  const value3 = [{ name: '4 Months' }, { name: '5 Months' }, { name: '6 Months' }];


    return (
        <div>
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="row">				
                {/* Appointment Settings */}
                <div className="col-md-12 mx-auto">
                  <div className="appointment-sec appointment-set-two">							
                    <div className="coupon-title">
                      <h4>Appointment Settings</h4>								
                    </div>
                    <div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Appointment Time Intervals</h5>
                        <p>Set the appointment time intervals</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.value)}
                        options={value}
                        optionLabel="name"
                        placeholder="30 Min"
                        className="select provider-select"
                      />
                      </div>
                    </div>
                    <div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Allow Multiple Booking for Same Time Slot</h5>
                        <p>Set the appointment time intervals</p>
                      </div>
                      <div className="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                        <input type="checkbox" id="status" className="check" defaultChecked />
                        <label htmlFor="status" className="checktoggle">checkbox</label>
                      </div>
                    </div>
                    <div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Appointment Auto Confirm</h5>
                        <p>Enable/disable appointment auto confirm</p>
                      </div>
                      <div className="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                        <input type="checkbox" id="status-one" className="check" defaultChecked />
                        <label htmlFor="status-one" className="checktoggle">checkbox</label>
                      </div>
                    </div>
                    <div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Minimum Advance Booking Time</h5>
                        <p>Minimum advance booking time (1 hours to 10 days)</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue2}
                        onChange={(e) => setSelectedValue2(e.value)}
                        options={value2}
                        optionLabel="name"
                        placeholder="30 Minutes"
                        className="select provider-select"
                      />
                      </div>
                    </div>
                    <div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Maximum Advance Booking Time</h5>
                        <p>Set the maximum advance booking time (1 Month to 5 years)</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue3}
                        onChange={(e) => setSelectedValue3(e.value)}
                        options={value3}
                        optionLabel="name"
                        placeholder="4 Months"
                        className="select provider-select"
                      />
                      </div>
                    </div>							
                    <div className="acc-submit appoint-submit">
                      <Link to="#" className="btn btn-secondary">Reset</Link>
                      <Link to="#" className="btn btn-primary">Save Changes</Link>
                    </div>
                  </div>								
                </div>
                {/* /Appointment Settings */}
              </div>
            </div>
          </div>
        </div>
      );
}

export default ProviderAppointmentSettings