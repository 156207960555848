import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { updateProfile } from '../../../../core/data/api/api';

const CustomerProfile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedGender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const genderOptions = [
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
  ];
  const [token, setToken] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('tokenUP');
    if (storedToken) {
      setToken(storedToken);
    } else {
      setError("Token not found. Please sign up again.");
    }
  }, []);

  const handleSaveChanges = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await updateProfile(
        name,
        email,
        mobile,
        selectedGender, 
        address,
        city,
        state,
        country,
        token
      );
      // console.log('Update profile response:', response);
      setSuccess('Profile updated successfully!');
    } catch (error) {
      // console.error('Error updating profile:', error);
      setError('An error occurred while updating the profile.');
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="col-lg-9">
        <div className="alert alert-success">
          {success}
         
        </div>
       <div> <Link to="/" className="btn btn-primary ml-2">Go Home</Link></div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="col-lg-9">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  return (
    <div className="col-lg-9">
      <div className="widget-title">
        <h4>Account Settings</h4>
      </div>
      <h6 className="user-title">General Information</h6>
      <div className="general-info">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Your Name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email Address"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Gender</label>
              <Dropdown
                value={selectedGender}
                onChange={(e) => setGender(e.value)} // Set the gender string directly
                options={genderOptions}
                placeholder="Select Gender"
                className="select w-100"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Address</label>
              <input
                type="text"
                className="form-control"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Your Address"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">City</label>
              <input
                type="text"
                className="form-control"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter Your City"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">State</label>
              <input
                type="text"
                className="form-control"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter Your State"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Country</label>
              <input
                type="text"
                className="form-control"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Enter Your Country"
              />
            </div>
          </div>
        </div>  
      </div>
      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}
      <div className="acc-submit">
        <Link to="#" className="btn btn-secondary">
          Cancel
        </Link>
        <button className="btn btn-primary" onClick={handleSaveChanges}>
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default CustomerProfile;
