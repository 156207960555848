import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { fetchServiceList } from '../../../core/data/api/api';
import { useSelector } from 'react-redux';
import { AppState } from '../../../core/models/interface';

const ServiceDetails2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceId } = location.state || {};
  const [service, setService] = useState(null);
  const [loginMessage, setLoginMessage] = useState('');
  const routes = all_routes;
  const token = useSelector((state: AppState) => state.user_token);


   
      // console.log('Token services Details:', token);
  

  useEffect(() => {
    const getServiceList = async () => {
      try {
        const data = await fetchServiceList();
        const services = data.data.payload.data;
        const matchedService = services.find(
          (service) => service.sub_category_id === serviceId,
        );

        if (matchedService) {
          setService(matchedService);
          // console.log('Matched Service:', matchedService);
        } else {
          console.log(
            'No matching service found for the provided sub_category_id',
          );
        }
      } catch (error) {
        console.error('Error fetching service list:', error);
      }
    };

    getServiceList();
  }, [serviceId]);

  const handleServiceClick = (id: any) => {
    if (token) {
      navigate(routes.booking1, { state: { bookingServiceId: id } }); // Navigate with state
    } else {
      setLoginMessage('Please login or register to book the service.');
    }
  };

  if (!service) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Construction</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {service.slug}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content section-booking">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="service-view">
                  <div className="service-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h1 className="electric-header">{service.title}</h1> <br />
                    </div>
                    <div>
                    <address className="service-location">
                      <i className="fas fa-location-arrow" />{' '}
                      {service.location.address}, {service.location.city},{' '}
                      {service.location.state}, {service.location.country} <br />
                    </address>
                    </div>
                    <div className="service-cate d-flex align-items-center">
                      <Link to={routes.search}>Construction</Link>
                    
                    </div>
                  </div>
                  <div className="service-images">
                    <div className="item">
                      <ImageWithBasePath
                        src="assets/img/services/service-077.jpeg"
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  {/*  Service description -----------  */}
                  <div className="service-details">
                    <ul
                      className="nav nav-pills service-tabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          to="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Service Details
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="card service-description">
                          <div className="card-body">
                            <p
                              className="mb-0"
                              dangerouslySetInnerHTML={{
                                __html: service.description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* additional Service  -----------  */}
                  <div className="service-details">
                    <ul
                      className="nav nav-pills service-tabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          to="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Additional Service
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="card">
                          <div className="card-body">
                            <div className="serviced-offer">
                              <ul className="list-bullet">
                                {service.additional_services.map(
                                  (additionalServices) => (
                                    <li key={additionalServices.id}>
                                      {' '}
                                      <span>
                                        {' '}
                                        <i className="fa-regular fa-circle-check" />
                                      </span>{' '}
                                      <b>{additionalServices.title}</b> {'  '}
                                      {additionalServices.price} -{' '}
                                      {additionalServices.price}
                                    </li>
                                  ),
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="sidebar-widget widget">
                    <div className="service-book">
                      <div className="btn btn-primary booking-btn" onClick={() =>
                              handleServiceClick(service.sub_category_id)
                            }>
                        {' '}
                        Book Service{' '}
                      </div>
                      
                    </div>
                    <div>
                      {loginMessage && <p className='text-danger mt-2'>{loginMessage}</p>}
                      </div>
                  </div>
                  <div className="card provider-widget clearfix">
                    <div className="card-body">
                      <h5 className="card-title">Service Provider</h5>
                      <div className="about-author d-flex align-items-center">
                        <div className="about-provider-img">
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid rounded-circle"
                              alt="image"
                              src="assets/img/provider/provider-23.png"
                            />
                          </Link>
                        </div>
                        <div className="provider-details">
                          <Link to="#" className="ser-provider-name">
                            {service.slug}
                          </Link>
                          <p className="last-seen">
                            <i className="fas fa-circle online" /> Online
                          </p>
                          <p className="text-muted mb-1">
                            Member Since 2023
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="provider-info">
                        <p className="mb-1">
                          <i className="far fa-envelope" />{' '}
                          <Link to="#">
                            <span> hello@embtelsolutions.com</span>
                          </Link>
                        </p>
                        <p className="mb-0">
                          <i className="fas fa-phone-alt" /> +1 (510) 962-7900
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card available-widget widget-book-detail">
                    <div className="card-body">
                      <h5 className="card-title">Service Availability</h5>
                      <ul>
                        {service.time_slots.map((timeSlot) => (
                          <li key={timeSlot.id}>
                            <span>{timeSlot.slot_type}</span>
                            {timeSlot.from} - {timeSlot.to}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content */}
      </>
    </>
  );
};

export default ServiceDetails2;
