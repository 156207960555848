import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const PrivacyPolicy = () => {
  const routes = all_routes;
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Privacy Policy</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}
            <div className="col-md-12">
              <div className="terms-content privacy-cont">
                <p>Welcome to the privacy statement for Power Buddy.</p>
                <p>
                  Power Buddy and our partners are committed to bringing a
                  variety of services directly to your home. This privacy
                  statement describes how we collect, use, and safeguard your
                  personal information when you visit, utilize, or interact with
                  our website or the &quot;Power Buddy&quot; mobile application.
                </p>
                <p>
                  We highly respect the user’s privacy, and we are committed to
                  protecting their personal information. We handle your
                  information with care and confidentiality. We collect, use,
                  and protect your information in accordance with our privacy
                  policy.
                </p>
                <h5 className="mt-5">What Data Do We Collect?</h5>
                <p>
                  In order to understand our users, we may collect the following
                  information when you use our website or app:
                </p>
                <ul>
                  <li>
                    <b>Contact information:</b> Name, contact number, and mailing
                    address.
                  </li>
                  <li>
                   <b> Login information:</b> username, password, and other info needed
                    to set up and keep your account on Power Buddy.
                  </li>
                  <li>
                    <b>Service Information:</b> Information about the services you can
                    get through our platform.
                  </li>
                  <li>
                    <b>Technical Details:</b> IP address, browser type, and browsing
                    history on our platform.
                  </li>
                </ul>

                <h5 className="mt-5">How Do We Utilize Your Information?</h5>
                <p>We utilize your personal data for reasons, including:</p>
                <ul>
                  <li>
                    <b> Offering services:</b> scheduling and maintaining appointments
                    with service providers so they can perform the tasks you
                    want.
                  </li>
                  <li>
                    <b>Enhancing User Experience:</b> We utilize your information to
                    know that you utilize our services; this will enable us to
                    know more about the delivery of our services. Eventually, we
                    can improve the overall quality of our services.
                  </li>
                  <li>
                   <b> Communication:</b> We will take the data regarding your contact
                    information, such as contact number and email ID, to send
                    you updates from our business. We will use this space to
                    give you news, offers, and other details about our services.
                  </li>
                  <li>
                    <b>Security:</b> To keep our users and the platform safe from
                    fraud, illegal access, and other security risks.
                  </li>
                  <li>
                    <b>Compliance:</b> To comply with legal requirements and settle any
                    disagreements that may develop in future.
                  </li>
                </ul>

                <h5 className="mt-5">Disclosure and Information Exchange</h5>
                <p>Your personal information might be disclosed to:</p>
                <ul>
                  <li>
                    <b>Service Experts:</b> To make it possible for them to offer the
                    services you have requested.
                  </li>
                  <li>
                   <b> Third-Party Service Providers:</b> Those that help us run our
                    business, manage our platform, or provide you with services.
                  </li>
                  <li>
                  <b>  Legal Authorities:</b> When mandated by legislation or to
                    safeguard our rights and welfare.
                  </li>
                </ul>

                <h5 className="mt-5">Safety Procedures</h5>
                <p>
                  To secure your data, we have implemented a number of security
                  measures as precautions. We make every possible effort to
                  ensure that your personal information is not accessed, used,
                  or disclosed by unauthorized parties. Security precautions
                  should be taken, including access controls, secure servers,
                  and encryption. Even after taking such precautions, you should
                  be aware that no electronic storage mechanism or transmission
                  over the Internet can guarantee complete security.
                </p>

                <h5 className="mt-5">Your Legal rights:</h5>
                <ul>
                  <li>
                  <b>Access and Update:</b> You can easily make a request to view your personal data collected by the
                  website, and you can make any necessary updates.
                  </li>
                  <li>
                  <b>Unsubscribe:</b> If you wish to unsubscribe from our service marketing channels, you can easily
                  unsubscribe from our marketing and promotional emails by using the &quot;opt-out&quot; option.
                  </li>
                  <li>
                  <b>Delete:</b> If you wish to delete your account, you can request that your account and personal data be
                  deleted.
                  </li>
                </ul>
<p>If you have a question about our privacy statements or our data handling procedures, just send an email to
                  (hello@embtelsolutions.com)</p>
                <p>We update our privacy policy regularly to make sure it accurately represents our procedures and
                regulatory requirements. Keep an eye on this policy for any changes.</p>
              </div>
            </div>
            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
