/* eslint-disable @typescript-eslint/no-unused-vars */
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { Dropdown } from 'primereact/dropdown';
import { all_routes } from '../../../core/data/routes/all_routes';
import * as Icon from 'react-feather';
import { getListOfAppointmentsAdmin } from '../../../core/data/api/api'; 
import { format } from 'date-fns';
interface Appointment {
  service_id: string;
  city: string;
  state: string;
  country: string;
  booking_date: string;
  appointment_time: string;
  email: string;
  name: string;
  mobile_no: number;
}

const Booking = () => {
  const routes = all_routes;
  const [token, setToken] = useState('');
  const [data, setData] = useState<Appointment[]>([]);
  const [selectedValue, setSelectedValue] = useState({
    service_id: '',
    city: '',
    state: '',
    country: '',
    booking_date: '',
    appointment_time: '',
    email: '',
    name: '',
    mobile_no: '',
  });
  const value = [{ name: 'A - Z' }, { name: 'Z - A' }];

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log("Token not found. Please sign up again.");
    }
  }, []);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const result = await getListOfAppointmentsAdmin(token);
        console.log(result.data.payload.data); // Log the payload
        if (Array.isArray(result.data.payload.data)) {
          setData(result.data.payload.data);
        } else {
          console.error('Error: Data is not an array:', result.data.payload);
        }
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    if (token) {
      fetchAppointments();
    }
  }, [token]);

  const rowNumberTemplate = (
    rowData: any,
    { rowIndex }: { rowIndex: number },
  ) => {
    return rowIndex + 1;
  };
  const renderDate = (rowData: any) =>
    format(new Date(rowData.booking_date), 'dd/MM/yyyy HH:mm');
  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>Booking List</h5>
            <div className="list-btn">
              <ul>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <Icon.Filter className="react-feather-custom me-2" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <ImageWithBasePath
                            src="assets/img/icons/sort.svg"
                            className="me-2"
                            alt="img"
                          />
                        </span>
                        <div className="review-sort">
                          <Dropdown
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.value)}
                            options={value}
                            optionLabel="name"
                            placeholder="A - Z"
                            className="select admin-select-breadcrumb"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.booking} className="active">
                        All Booking
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  {Array.isArray(data) && data.length > 0 ? (
                    <DataTable
                      paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      value={data}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: '50rem' }}
                    >
                       <Column
                      field="index"
                      header="#"
                      body={rowNumberTemplate}
                    ></Column>
                      <Column sortable field="user.name" header="Name"></Column>
                      <Column sortable field="user.email" header="Email"></Column>
                      <Column sortable field="user.mobile_no" header="Phone No"></Column>
                      <Column sortable field="city" header="City"></Column>
                      <Column sortable field="state" header="State"></Column>
                      <Column sortable field="country" header="Country"></Column>
                      <Column sortable field="booking_date" header="Booking Date"  body={renderDate}></Column>
                      <Column sortable field="appointment_time" header="Appointment Time"></Column>
                    </DataTable>
                  ) : (
                    <p>No data available</p>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
