import React, { useState } from 'react'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Dropdown } from 'primereact/dropdown';
const routes = all_routes;

const ProviderReview = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">						
                <div className="provider-subtitle">
                  <h6>Reviews</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.value)}
                  options={value}
                  optionLabel="name"
                  placeholder="A - Z"
                  className="select provider-select select-space" />
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Coupons */}
          <div className="row">
            <div className="col-md-12">						
              {/* Review List */}
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-12.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Building Construction Services.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-01.jpg" alt="image" /> Jeffrey Akridge, 
                    <span className="review-date">July 11, 2023 11:38 am</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-13.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Commercial Painting Services.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-02.jpg" alt="image" /> Nancy Olson,  
                    <span className="review-date">July 05, 2023 05:38 am</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-14.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Plumbing Services.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="image" /> Ramona Kingsley, 
                    <span className="review-date">July 29, 2023 10:38 am</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-15.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Wooden Carpentry Work.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="image" /> Ricardo Lung,  
                    <span className="review-date">July 26, 2023 2:22 am</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-16.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Air Conditioner Service.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="image" /> Annette Silva, 
                    <span className="review-date">July 13, 2023 17:38 am</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-17.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Toughened Glass Fitting Services.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-06.jpg" alt="image" /> Stephen Wilson,  
                    <span className="review-date">July 10, 2023 17:18 pm</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              <div className="review-list">
                <div className="review-imgs">
                  <Link to={routes.serviceDetails2}><ImageWithBasePath className="rounded img-fluid" src="assets/img/services/service-18.jpg" alt="image" /></Link>
                </div>
                <div className="review-info">
                  <h5>
                    <Link to={routes.serviceDetails2}>Car Repair Services.</Link> 
                  </h5>
                  <div className="review-user">
                    <ImageWithBasePath className="avatar rounded-circle" src="assets/img/profiles/avatar-07.jpg" alt="image" /> Ryan Rodriguez, 
                    <span className="review-date">July 10, 2023 14:38 pm</span>
                  </div>
                </div>
                <div className="review-count">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
              {/* /Review List */}	
              <div className="row">
                <div className="col-sm-6">
                  <div className="review-entries">
                    <span>Show</span>
                    <select>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                    <span>entries</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="review-pagination">
                    <p>1 - 07 of 10</p>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link className="page-link" to="#">1</Link>
                      </li>
                      <li className="page-item active">
                        <Link className="page-link" to="#">2 <span className="visually-hidden">(current)</span></Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">3</Link>
                      </li>
                    </ul>						
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Coupons */}
        </div>
      </div>
  )
}

export default ProviderReview