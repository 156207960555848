export const ipaddress = [
  {
    id: 1,
    ipAddress: '210.10.444',
    reason: 'Lorem ipsum dolor sit amet',
    date: '24 Sep 2023',
    action: '',
  },
  {
    id: 2,
    ipAddress: '211.11.111',
    reason: 'Lorem ipsum dolor sit amet',
    date: '18 Sep 2023',
    action: '',
  },
  {
    id: 3,
    ipAddress: '211.11.111',
    reason: 'Lorem ipsum dolor sit amet',
    date: '27 Sep 2023',
    action: '',
  },
  {
    id: 4,
    ipAddress: '213.12.333',
    reason: 'Lorem ipsum dolor sit amet',
    date: '27 Sep 2023',
    action: '',
  },
  {
    id: 5,
    ipAddress: '214.13.111',
    reason: 'Lorem ipsum dolor sit amet',
    date: '27 Sep 2023',
    action: '',
  },
];
