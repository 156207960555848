import React from 'react';

const ProviderNotification = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* Profile settings */}
              <div className="widget-title">
                <h4>Notifications</h4>
              </div>
              <div className="notification-table">
                {/* General Notification */}
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-thead">
                      <tr>
                        <th>General Notifications</th>
                        <th>Push</th>
                        <th>Email</th>
                        <th>SMS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="notify-item">
                            <h6>General Notifications Newsletter</h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_1"
                              className="check"
                              checked={true}
                            />
                            <label htmlFor="status_1" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_2"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_2" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_3"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_3" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="notify-item">
                            <h6>Daily Updates</h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_4"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_4" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_5"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_5" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_6"
                              className="check"
                            />
                            <label htmlFor="status_6" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* /General Notification */}
                {/* Booking Notification */}
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="table-thead">
                      <tr>
                        <th>Booking</th>
                        <th>Push</th>
                        <th>Email</th>
                        <th>SMS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="notify-item">
                            <h6>Booking Request</h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_7"
                              className="check"
                            />
                            <label htmlFor="status_7" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_8"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_8" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_9"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_9" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="notify-item">
                            <h6>Booking Status</h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_10"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_10" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_11"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_11" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_12"
                              className="check"
                            />
                            <label htmlFor="status_12" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="notify-item">
                            <h6>Refund Request</h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_13"
                              className="check"
                            />
                            <label htmlFor="status_13" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_14"
                              className="check"
                                checked={true}
                            />
                            <label htmlFor="status_14" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="status-toggle status-tog">
                            <input
                              type="checkbox"
                              id="status_15"
                              className="check"
                            />
                            <label htmlFor="status_15" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* /Booking Notification */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderNotification;
