import * as Icon from 'react-feather';
import React, { useState, useEffect } from 'react';
import { updateUserById } from '../../../../core/data/api/api'; // Adjust the import path as necessary

interface User {
  id: string;
}

interface CustomersModalProps {
  selectedUser: User | null;
}

const UpdateCustomer: React.FC<CustomersModalProps> = ({ selectedUser }) => {
  const [token, setToken] = useState('');
  const [userData, setUserData] = useState<any>({
    name: '',
    email: '',
    mobile: '',
    gender: '',
    address: '',
    city: '',
    state: '',
    country: '',
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedUser && token) {
      setLoading(true);
      setSuccessMessage('');
      try {
        await updateUserById(token, selectedUser.id, userData);
        setSuccessMessage('User updated successfully');
        setUserData({
          name: '',
          email: '',
          mobile: '',
          gender: '',
          address: '',
          city: '',
          state: '',
          country: '',
        });
      } catch (error) {
        console.error('Failed to update user:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="modal fade" id="update-customer-data">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update User Details</h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="react-feather-custom"></Icon.X>
              </button>
            </div>
            <div className="modal-body pt-0">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={userData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Email:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Phone Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile"
                      value={userData.mobile}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Gender :</label>
                    <select
                      className="form-control"
                      name="gender"
                      value={userData.gender}
                        onChange={handleChange}
                        required
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Address:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={userData.address}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">City:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={userData.city}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">State:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={userData.state}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Country:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={userData.country}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Update User Data
                    </button>
                  </div>
                </form>
              )}
              {successMessage && <p className="text-success mt-3">{successMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCustomer;
