import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { fetchServiceList } from '../../../../core/data/api/api';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const ServiceGrid = () => {
  const navigate = useNavigate(); // Initialize navigate
  const routes = all_routes;
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const [services, setServices] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  





 
  const category = [
    { name: 'Price Low to High' },
    { name: 'Price High to Low' },
  ];

  useEffect(() => {
    const getServiceList = async () => {
      try {
        const data = await fetchServiceList();
        setServices(data.data.payload.data);
        console.log(data.data.payload.data);
      } catch (error) {
        console.error('Error fetching service list:', error);
      }
    };

    getServiceList();
  }, []);

  const toggleHeight = () => {
    setIsExpanded(!isExpanded);
  };

  const handleItemClick = (index: any) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  const handleServiceClick = (id: any) => {
    navigate(routes.serviceDetails2, { state: { serviceId: id } }); 
  };

  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Services</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Service
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Service */}
            <div className="col-lg-12 col-sm-12">
              <div className="row sorting-div">
                <div className="col-lg-4 col-sm-12 ">
                  <div className="count-search">
                    <h6>Found Services</h6>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12 d-flex justify-content-end ">
                  <div className="sortbyset">
                    <div className="sorting-select">
                      <Dropdown
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.value)}
                        options={category}
                        optionLabel="name"
                        placeholder="Price Low to High"
                        className="select service-select"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Service List */}
                {services.map((service, index) => (
                  <div
                    className="col-xl-4 col-md-6"
                    key={service.sub_category_id}
                  >
                    <div className="service-widget servicecontent">
                      <div className="service-img">
                        <div
                          onClick={() =>
                            handleServiceClick(service.sub_category_id)
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service Image"
                            src="assets/img/services/service-077.jpeg"
                          />
                        </div>
                        <div
                          className="fav-item"
                          onClick={() => handleItemClick(index)}
                        >
                          <Link to={routes.categories}>
                            <span className="item-cat">
                              {service.sub_category.name}
                            </span>
                          </Link>
                          {/* <Link
                            to="#"
                            className={`fav-icon ${selectedItems[index] ? 'selected' : ''}`}
                          >
                            <Icon.Heart size={15} />
                          </Link> */}
                        </div>
                      </div>
                      <div className="service-content">
                        <h3 className="title">
                          <div
                            onClick={() =>
                              handleServiceClick(service.sub_category_id)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {service.title}
                          </div>
                        </h3>
                        <p>
                          <Icon.MapPin className="react-feather-custom" />
                          {service.location.address}, {service.location.city},{' '}
                          {service.location.state}, {service.location.country}
                        </p>
                        <div className="serv-info">
                          <Link to={routes.booking1} className="btn btn-book">
                            Book Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* /Service List */}
              </div>
              {/* Pagination */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="blog-pagination rev-page">
                    <nav>
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                          <Link
                            className="page-link page-prev"
                            to="#"
                            tabIndex={-1}
                          >
                            <i className="fa-solid fa-arrow-left me-1" /> PREV
                          </Link>
                        </li>
                        <li className="page-item active">
                          <Link className="page-link" to="#">
                            1
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link page-next" to="#">
                            NEXT <i className="fa-solid fa-arrow-right ms-1" />
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              {/* /Pagination */}
            </div>
            {/* /Service */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceGrid;
