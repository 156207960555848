import React, { useState } from 'react';
import * as Icon from 'react-feather';
import PagesAuthHeader from './common/header';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const FreeTrail = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const routes = all_routes;
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap mw-100">
                <div className="login-header text-center">
                  <h3>Start your free trial</h3>
                </div>
                {/* Login Form */}
                <form action={routes.login}>
                  <div className="form-group">
                    <label className="col-form-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Domain</label>
                    <div className="input-group domain-grp">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Recipient's username"
                        aria-describedby="basic"
                      />
                      <span className="input-group-text" id="basic">
                        .example.com
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Work Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Work Email"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        placeholder="*************"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <Icon.EyeOff  className="react-feather-custom"/> : <Icon.Eye  className="react-feather-custom"/>}
                      </span>
                    </div>
                  </div>
                  <Link to={routes.login}
                    className="btn btn-primary w-100 login-btn mb-0"
                    type="submit"
                  >
                    Register
                  </Link>
                </form>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrail;
