import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { ProviderPlanData } from '../../../../core/data/json/provider_plan';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const ProviderPlan = () => {
  const [active, setactive] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([{}]);
  useEffect(() => {
    setData(ProviderPlanData);
  }, []);
  const value = [{ name: 'A - Z' }, { name: 'Z - A' }];
  const columns = [
    { field: 'payoutDate', header: 'Payout Date' },
    { field: 'planDetails', header: 'Payout Details' },
    { field: 'amount', header: 'Amount' },
    { field: 'invoice', header: 'Invoice' },
    { field: 'status', header: 'Status' },
  ];
  const renderBody = (res: {
    status:
      | string
      | number
      | boolean
      | Iterable<React.ReactNode>
      | null
      | undefined;
  }) => {
    return (
      <span
        className={` badge ${
          res.status == 'Paid' ? 'badge-success' : 'badge-warning'
        }`}
      >
        {res.status}
      </span>
    );
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            {/* Security settings */}
            <div className="widget-title">
              <h4>Plan &amp; Billings</h4>
            </div>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column">
                {/* Current Plan */}
                <h6 className="subhead-title">Current Plan</h6>
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="plan-info">
                      <div className="plan-term">
                        <h5>Standard</h5>
                        <p>Our most popular plan for small teams.</p>
                        <h6>
                          <span>
                            <Icon.Calendar className="react-feather-icons" />{' '}
                            Renew Date:
                          </span>{' '}
                          Jan 22, 2023
                        </h6>
                      </div>
                      <div className="plan-price">
                        <h4>$291</h4>
                        <p>per user/Year</p>
                      </div>
                    </div>
                    <div className="plan-btns">
                      <Link to="#" className="btn btn-danger-light">
                        Cancel Subscription
                      </Link>
                      <Link to="#" className="btn btn-secondary-outline">
                        Upgrade
                      </Link>
                    </div>
                  </div>
                </div>
                {/* /Current Plan */}
              </div>
              <div className="col-lg-6 d-flex flex-column">
                {/* Payments */}
                <h6 className="subhead-title">Payment</h6>
                <div className="card flex-fill">
                  <div className="card-body pay-next">
                    <div className="plan-info">
                      <div className="plan-term">
                        <h5>Last Payment</h5>
                        <h6>
                          <Icon.Calendar className="react-feather-icons" />{' '}
                          January 22, 2023
                        </h6>
                      </div>
                      <div className="plan-price">
                        <Link to="#" className="btn btn-light-success">
                          Active
                        </Link>
                      </div>
                    </div>
                    <div className="plan-info">
                      <div className="plan-term">
                        <h5>Next Payment</h5>
                        <h6>
                          <Icon.Calendar className="react-feather-icons" />{' '}
                          January 22, 2023
                        </h6>
                      </div>
                      <div className="plan-price">
                        <Link to="#" className="btn btn-primary">
                          Manage Payment
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Payments */}
              </div>
              <div className="col-md-12">
                <h6 className="user-title">Payment Method</h6>
                <div className="row">
                  {/* Credit Card */}
                  <div className="col-md-6 col-xl-4 d-flex flex-column">
                    <Link to="#" onClick={() => setactive(!active)}>
                      <div
                        className={`card card-payments  flex-fill ${
                          active ? 'active' : ''
                        }`}
                      >
                        <div className="card-body">
                          <div>
                            <h6>Credit Card</h6>
                            <div className="card-num">
                              <div className="visa-img">
                                <ImageWithBasePath
                                  src="assets/img/icons/visa.svg"
                                  alt="image"
                                />
                              </div>
                              <h6>
                                **** **** **** <span>9525</span>
                              </h6>
                            </div>
                          </div>
                          <div className="card-remove">
                            <Link to="#">
                              Remove Card{' '}
                              <Icon.Trash2 className="react-feather-icons" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* /Credit Card */}
                  {/* Debit Card */}
                  <div className="col-md-6 col-xl-4 d-flex flex-column">
                    <Link to="#" onClick={() => setactive(!active)}>
                      <div
                        className={`card card-payments  flex-fill ${
                          !active ? 'active' : ''
                        }`}
                      >
                        <div className="card-body">
                          <div>
                            <h6>Debit Card</h6>
                            <div className="card-num">
                              <div className="visa-img">
                                <ImageWithBasePath
                                  src="assets/img/icons/mastercard.svg"
                                  alt="image"
                                />
                              </div>
                              <h6>
                                **** **** **** <span>9525</span>
                              </h6>
                            </div>
                          </div>
                          <div className="card-remove">
                            <Link to="#">
                              Remove Card{' '}
                              <Icon.Trash2 className="react-feather-icons" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* /Debit Card */}
                  {/* Add Card */}
                  <div className="col-md-6 col-xl-4 d-flex flex-column">
                    <div className="card-payment-add flex-fill">
                      <Link to="#">
                        <span>
                          <Icon.Plus className="react-feather-icon" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* /Add Card */}
                </div>
                {/* Billing History */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <h6 className="subhead-title">Billing History</h6>
                    </div>
                    <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                      <div className="review-sort filter-dropdown me-2 d-flex align-items-center ">
                        <p>Sort</p>
                        <Dropdown
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.value)}
                          options={value}
                          optionLabel="name"
                          placeholder="A -&gt; Z"
                          className="select service-select"
                        />
                      </div>
                      <div className="grid-listview">
                        <ul>
                          <li>
                            <Link to="#">
                              <Icon.Filter className="react-feather-icon" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="provide-table">
                  <div className="table-responsive">
                    <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      className="table custom-table datatable"
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      value={data}
                      tableStyle={{ minWidth: '50rem' }}
                    >
                      {columns.map((col) =>
                        col.field == 'status' ? (
                          <Column
                            key={col.field}
                            sortable
                            field={col.field}
                            header={col.header}
                            body={renderBody}
                          />
                        ) : (
                          <Column
                            key={col.field}
                            sortable
                            field={col.field}
                            header={col.header}
                          />
                        ),
                      )}
                    </DataTable>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div id="tablelength" />
                  </div>
                  <div className="col-md-7">
                    <div className="table-ingopage">
                      <div id="tableinfo" />
                      <div id="tablepagination" />
                    </div>
                  </div>
                </div>
                {/* /Billing History */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderPlan;
