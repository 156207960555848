
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import React from 'react';

interface User {
  id: string;
  role_id: string;
    name: string;
    img?: string;
    email: string;
    mobile_no: string;
    created_at: string;
    status: string;
    user_type: string;
}

interface CustomersModalProps {
    selectedUser: User | null;
}
const CustomersModal: React.FC<CustomersModalProps> = ({ selectedUser }) => {
  return (
    <>
        
     
      {/* Edit Customer */}
      <div className="modal fade" id="edit-customer">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header"> 
              <h5 className="modal-title">User Details</h5>
              <button
                type="button" 
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="react-feather-custom"></Icon.X>
              </button>
            </div>
            <div className="modal-body pt-0">
              <form>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Name:</label>
                  <p className='fs-5'>{selectedUser?.name}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Email:</label>
                  <p className='fs-5'>{selectedUser?.email}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Phone:</label>
                  <p className='fs-5'>{selectedUser?.mobile_no}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">User Type:</label>
                  <p className='fs-5'>{selectedUser?.user_type}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Created:</label>
                  <p className='fs-5'>{selectedUser?.created_at}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">User ID:</label>
                  <p className='fs-5'>{selectedUser?.id}</p>
                </div>
                <div className="mb-3 border-bottom">
                  <label className="form-label">Role ID:</label>
                  <p className='fs-5'>{selectedUser?.role_id}</p>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Change User Data
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      {/* /Edit Customer */}
    </>
  );
};
export default CustomersModal;


