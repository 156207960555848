import React, { useState, useCallback } from 'react';
import ServiceInformation from './serviceInformation';
import Availability from './availability';
import Location from './location';
import Gallery from './gallery';

const EditService = () => {
  const [TabChange, setTabChange] = useState(true);
  const [TabChange1, setTabChange1] = useState(false);
  const [TabChange2, setTabChange2] = useState(false);
  const [TabChange3, setTabChange3] = useState(false);
  const [PageChange, setPageChange] = useState('information');

  const initialFormData = {
    title: '',
    slug: '',
    buddy_id: '',
    category_id: '',
    sub_category_id: '',
    price: '',
    duration: '',
    description: '',
    status: 'DRAFT',
    video_link: '',
    additional_services: [
      {
        title: '',
        price: '',
        duration: '',
      },
    ],
    availability: [
      {
        from: '',
        to: '',
        slot: '',
        day: '',
      },
    ],
    media: [],
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    gmap_place_id: '',
    latitude: '',
    longitude: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleFormDataChange = (newFormData: any) => {
    setFormData(newFormData);
  };

  const handleAvailabilityDataChange = (newAvailabilityData: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      availability: newAvailabilityData,
    }));
  };

  const handleLocationDataChange = (locationData: any) => {
    setFormData((prevData) => ({ ...prevData, ...locationData }));
  };

  const handleMediaDataChange = useCallback((newMedia: any) => {
    setFormData((prevData) => ({ ...prevData, media: newMedia }));
  }, []);

  const availabilityTab = () => {
    setPageChange('booking');
    setTabChange1(true);
    setTabChange(false);
    setTabChange2(false);
    setTabChange3(false);
  };

  const serviceTab = () => {
    setPageChange('information');
    setTabChange1(false);
    setTabChange2(false);
    setTabChange3(false);
    setTabChange(true);
  };

  const locationTab = () => {
    setPageChange('location');
    setTabChange2(true);
    setTabChange1(false);
    setTabChange(false);
    setTabChange3(false);
  };

  const galleryTab = () => {
    setPageChange('gallery');
    setTabChange3(true);
    setTabChange2(false);
    setTabChange1(false);
    setTabChange(false);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="progressbar-card">
              <ul id="progress-head">
                <li className="active">Edit Services - Service Information</li>
                <li>Edit Services - Availability</li>
                <li>Edit Services - Location</li>
                <li>Edit Services - Gallery</li>
              </ul>
              <ul id="progressbar">
                <li className={TabChange ? 'active' : ''}>
                  <div className="multi-step-icon">
                    <span>
                      <i className="far fa-check-circle" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li className={TabChange1 ? 'active' : ''}>
                  <div className="multi-step-icon">
                    <span>
                      <i className="far fa-clock" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li className={TabChange2 ? 'active' : ''}>
                  <div className="multi-step-icon">
                    <span>
                      <i className="far fa-map" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li className={TabChange3 ? 'active' : ''}>
                  <div className="multi-step-icon">
                    <span>
                      <i className="far fa-images" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 m-auto">
            {PageChange === 'information' ? (
              <ServiceInformation
                formData={formData}
                setFormData={handleFormDataChange}
                nextTab={availabilityTab}
              />
            ) : PageChange === 'booking' ? (
              <Availability
                prevTab={serviceTab}
                nextTab={locationTab}
                setAvailabilityData={handleAvailabilityDataChange}
              />
            ) : PageChange === 'location' ? (
              <Location
                formData={formData}
                prevTab={availabilityTab}
                nextTab={galleryTab}
                setLocationData={handleLocationDataChange}
              />
            ) : (
              <Gallery
                formData={formData}
                prevTab={locationTab}
                setMediaData={handleMediaDataChange}
                setFormData={setFormData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditService;
