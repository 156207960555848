import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProviderCouponsModal from '../../common/modals/provider-coupons-modal';
import { useSelector } from 'react-redux';
import { Coupons } from '../../../../core/models/interface';

const ProviderCoupons = () => {
  const [selectedValue1, setSelectedValue1] = useState(null);

  const data = useSelector((state: any) => state.provider_coupons);

  const value1 = [
    { name: 'A - Z' },
    { name: 'Z - A' },
    { name: 'Most Helpful' },
  ];

  const statusRenderer = (rowData: Coupons) => {
    if (rowData.status === 'Inaction') {
      return <span className="badge-danger">{rowData.status}</span>;
    } else if (rowData.status === 'Action') {
      return <span className="badge-success">{rowData.status}</span>;
    } else {
      return rowData.status;
    }
  };
  const actionButton = () => {
    return (
      <div className="table-actions">
        <Link
          className="action-set"
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#edit-coupon"
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link className="action-set confirm-text" to="#">
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-md-4">
                  <div className="provider-subtitle">
                    <h6>Coupons</h6>
                  </div>
                </div>
                <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                  <div className="review-sort me-2">
                    <p>Sort</p>
                    <Dropdown
                      value={selectedValue1}
                      onChange={(e) => setSelectedValue1(e.value)}
                      options={value1}
                      optionLabel="name"
                      placeholder="A - Z"
                      className="select provider-select select-space"
                    />
                  </div>
                  <div className="grid-listview me-2">
                    <ul>
                      <li>
                        <Link to="#">
                          <Icon.Filter className="react-feather-custom" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <button
                    className="btn btn-primary add-set"
                    data-bs-toggle="modal"
                    data-bs-target="#add-coupon"
                  >
                    <Icon.Plus className="react-feather-custom" /> Add Coupon
                  </button>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Coupons */}
            <div className="row">
              <div className="col-md-12">
                <div className="provide-table manage-table">
                  <div className="table-responsive">
                    <table
                      className="table custom-table datatable mb-0"
                      id="data-table"
                    >
                      <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        value={data}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                      >
                        <Column sortable field="name" header="Name"></Column>
                        <Column sortable field="code" header="Code"></Column>
                        <Column sortable field="type" header="Type"></Column>
                        <Column
                          sortable
                          field="discount"
                          header="Discount"
                        ></Column>
                        <Column sortable field="limit" header="Limit"></Column>
                        <Column sortable field="used" header="Used"></Column>
                        <Column
                          sortable
                          field="validDate"
                          header="Valid Date"
                        ></Column>
                        <Column
                          sortable
                          field="servicesName"
                          header="Services Name"
                        ></Column>
                        <Column
                          sortable
                          field="status"
                          header="Status"
                          body={statusRenderer}
                        ></Column>
                        <Column
                          sortable
                          field="action"
                          header="Action"
                          body={actionButton}
                        ></Column>
                      </DataTable>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div id="tablelength" />
                  </div>
                  <div className="col-md-9">
                    <div className="table-ingopage">
                      <div id="tableinfo" />
                      <div id="tablepagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Coupons */}
          </div>
        </div>
      </div>
      <ProviderCouponsModal />
    </>
  );
};

export default ProviderCoupons;
