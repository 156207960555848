import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const CustomerBooking = () => {
  const routes = all_routes;
  const [selectedSub, setselectedSub] = useState(null);
  const sub = [
    { name: 'Recent' },
    { name: 'Older' },
    { name: 'A to  Z ' },
    { name: 'Z to A ' },
  ];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="widget-title">
            <h4>Booking List</h4>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div className="review-sort  cus-fav-sort me-2">
            <p>Sort</p>

            <Dropdown
              value={selectedSub}
              onChange={(e) => setselectedSub(e.value)}
              options={sub}
              optionLabel="name"
              placeholder="English"
              className="select provider-select select-space"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to={routes.customerBookingCalendar}>
                  <Icon.Calendar size={15} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Filter size={15} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Grid size={15} />
                </Link>
              </li>
              <li>
                <Link to={routes.customerBooking} className="active">
                  <Icon.List size={15} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
      {/* Booking List */}
      <div className="booking-list">
        <div className="booking-widget">
          <div className="booking-img">
            <Link to={routes.bookingDetails}>
              <ImageWithBasePath
                src="assets/img/services/service-08.jpg"
                alt="User Image"
              />
            </Link>
            <div
              className="fav-item"
              key={1}
              onClick={() => handleItemClick(1)}
            >
              <Link
                to="#"
                className={`fav-icon ${selectedItems[1] ? 'selected' : ''}`}
              >
                <Icon.Heart size={15} />
              </Link>
            </div>
          </div>
          <div className="booking-det-info">
            <h3>
              <Link to={routes.bookingDetails}>Computer Services</Link>
              <span className="badge badge-danger">Cancelled by User</span>
            </h3>
            <ul className="booking-details">
              <li>
                <span className="book-item">Booking Date</span> : 27 Sep,
                17:00-18:00
              </li>
              <li>
                <span className="book-item">Amount</span> : $100.00{' '}
                <span className="badge-grey badge-new">Paypal</span>
              </li>
              <li>
                <span className="book-item">Location</span> : Newyork, USA
              </li>
              <li>
                <span className="book-item">Customer</span> :
                <div className="user-book">
                  <div className="avatar avatar-xs">
                    <ImageWithBasePath
                      className="avatar-img rounded-circle"
                      alt="User Image"
                      src="assets/img/profiles/avatar-02.jpg"
                    />
                  </div>
                  John Doe
                </div>
                <p>info@johndoe.com</p>
                <p>+1 888 888 8888</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="booking-action action-popup-btn">
          <Link to="#" className="btn btn-secondary">
            <i className="fa-solid fa-circle-info" />
            Reason
          </Link>
          <Link
            to={routes.booking1}
            className="btn btn-primary"
            
          >
            <i className="fa-regular fa-calendar-days" />
            Reschedule
          </Link>
        </div>
      </div>
      {/* /Booking List */}
      {/* Booking List */}
      <div className="booking-list">
        <div className="booking-widget">
          <div className="booking-img">
            <Link to={routes.bookingDetails}>
              <ImageWithBasePath
                src="assets/img/services/service-18.jpg"
                alt="User Image"
              />
            </Link>
            <div
              className="fav-item"
              key={2}
              onClick={() => handleItemClick(2)}
            >
              <Link
                to="#"
                className={`fav-icon ${selectedItems[2] ? 'selected' : ''}`}
              >
                <Icon.Heart size={15} />
              </Link>
            </div>
          </div>
          <div className="booking-det-info">
            <h3>
              <Link to={routes.bookingDetails}>Car Repair Services</Link>{' '}
              <span className="badge badge-success">Completed</span>
            </h3>
            <ul className="booking-details">
              <li>
                <span className="book-item">Booking Date</span> : 23 Sep,
                10:00-11:00
              </li>
              <li>
                <span className="book-item">Amount</span> : $50.00{' '}
                <span className="badge-grey">COD</span>
              </li>
              <li>
                <span className="book-item">Location</span> : Alabama, USA
              </li>
              <li>
                <span className="book-item">Customer</span> :
                <div className="user-book">
                  <div className="avatar avatar-xs">
                    <ImageWithBasePath
                      className="avatar-img rounded-circle"
                      alt="User Image"
                      src="assets/img/profiles/avatar-03.jpg"
                    />
                  </div>
                  John Smith
                </div>
                <p>info@johndoe.com</p>
                <p>+1 607-276-5393</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="booking-action action-popup-btn">
          <Link
            to={routes.booking1}
            className="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#reschedule"
          >
            <i className="fa-regular fa-calendar-days" />
            Rebook
          </Link>
          <Link
            to=""
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#add-review"
          >
            <i className="fa-solid fa-star" />
            Add Review
          </Link>
        </div>
      </div>
      {/* /Booking List */}
      {/* Booking List */}
      <div className="booking-list">
        <div className="booking-widget">
          <div className="booking-img">
            <Link to={routes.bookingDetails}>
              <ImageWithBasePath
                src="assets/img/services/service-10.jpg"
                alt="User Image"
              />
            </Link>
            <div
              className="fav-item"
              key={3}
              onClick={() => handleItemClick(3)}
            >
              <Link
                to="#"
                className={`fav-icon ${selectedItems[3] ? 'selected' : ''}`}
              >
                <Icon.Heart size={15} />
              </Link>
            </div>
          </div>
          <div className="booking-det-info">
            <h3>
              <Link to={routes.bookingDetails}>Car Repair Services</Link>{' '}
              <span className="badge badge-success">Completed</span>
            </h3>
            <ul className="booking-details">
              <li>
                <span className="book-item">Booking Date</span> : 23 Sep,
                10:00-11:00
              </li>
              <li>
                <span className="book-item">Amount</span> : $50.00{' '}
                <span className="badge-grey">COD</span>
              </li>
              <li>
                <span className="book-item">Location</span> : Alabama, USA
              </li>
              <li>
                <span className="book-item">Customer</span> :
                <div className="user-book">
                  <div className="avatar avatar-xs">
                    <ImageWithBasePath
                      className="avatar-img rounded-circle"
                      alt="User Image"
                      src="assets/img/profiles/avatar-03.jpg"
                    />
                  </div>
                  John Smith
                </div>
                <p>info@johndoe.com</p>
                <p>+1 607-276-5393</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="booking-action action-popup-btn">
          <Link
            to={routes.booking1}
            className="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#reschedule"
          >
            <i className="fa-regular fa-calendar-days" />
            Rebook
          </Link>
          <div className="view-action mt-3 mb-0 me-0 ms-0">
            <div className="rating text-center">
              <i className="fas fa-star filled" />
              <i className="fas fa-star filled" />
              <i className="fas fa-star filled" />
              <i className="fas fa-star filled" />
              <i className="fas fa-star filled" />
            </div>
            <Link to={routes.bookingDetails} className="view-btn p-0">
              View Details
            </Link>
          </div>
        </div>
      </div>
      {/* /Booking List */}
      {/* Booking List */}
      <div className="booking-list">
        <div className="booking-widget">
          <div className="booking-img">
            <Link to={routes.bookingDetails}>
              <ImageWithBasePath
                src="assets/img/services/service-07.jpg"
                alt="User Image"
              />
            </Link>
            <div
              className="fav-item"
              key={4}
              onClick={() => handleItemClick(4)}
            >
              <Link
                to="#"
                className={`fav-icon ${selectedItems[4] ? 'selected' : ''}`}
              >
                <Icon.Heart size={15} />
              </Link>
            </div>
          </div>
          <div className="booking-det-info">
            <h3>
              <Link to={routes.bookingDetails}>Interior Designing</Link>{' '}
              <span className="badge badge-info">Inprogress</span>
            </h3>
            <ul className="booking-details">
              <li>
                <span className="book-item">Booking Date</span> : 27 Sep,
                17:00-18:00
              </li>
              <li>
                <span className="book-item">Amount</span> : $50.00{' '}
                <span className="badge-grey">COD</span>
              </li>
              <li>
                <span className="book-item">Location</span> : Washington, DC,
                USA
              </li>
              <li>
                <span className="book-item">Customer</span> :
                <div className="user-book">
                  <div className="avatar avatar-xs">
                    <ImageWithBasePath
                      className="avatar-img rounded-circle"
                      alt="User Image"
                      src="assets/img/profiles/avatar-06.jpg"
                    />
                  </div>
                  Quentin
                </div>
                <p>info@quentin.com</p>
                <p>+1 601-810-9218</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="booking-action action-popup-btn">
          <Link to={routes.customerChat} className="btn btn-secondary">
            <i className="fa-brands fa-rocketchat" /> Chat
          </Link>
          <Link to="#" className="btn btn-primary">
            <i className="fa-solid fa-circle-xmark" />
            Cancel
          </Link>
        </div>
      </div>
      {/* /Booking List */}
      {/* Booking List */}
      <div className="booking-list">
        <div className="booking-widget">
          <div className="booking-img">
            <Link to={routes.bookingDetails}>
              <ImageWithBasePath
                src="assets/img/services/service-13.jpg"
                alt="User Image"
              />
            </Link>
            <div
              className="fav-item"
              key={5}
              onClick={() => handleItemClick(5)}
            >
              <Link
                to="#"
                className={`fav-icon ${selectedItems[5] ? 'selected' : ''}`}
              >
                <Icon.Heart size={15} />
              </Link>
            </div>
          </div>
          <div className="booking-det-info">
            <h3>
              <Link to={routes.bookingDetails}>House Cleaning Services</Link>{' '}
              <span className="badge badge-warning">Pending</span>
            </h3>
            <ul className="booking-details">
              <li>
                <span className="book-item">Booking Date</span> : 27 Sep,
                17:00-18:00
              </li>
              <li>
                <span className="book-item">Amount</span> : $375.00{' '}
                <span className="badge-grey">COD</span>
              </li>
              <li>
                <span className="book-item">Location</span> : Virginia, USA
              </li>
              <li>
                <span className="book-item">Customer</span> :
                <div className="user-book">
                  <div className="avatar avatar-xs">
                    <ImageWithBasePath
                      className="avatar-img rounded-circle"
                      alt="User Image"
                      src="assets/img/profiles/avatar-02.jpg"
                    />
                  </div>
                  Johnson
                </div>
                <p>info@johnson.com</p>
                <p>+1 312-328-3970</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="booking-action action-popup-btn">
          <Link to={routes.customerChat} className="btn btn-secondary">
            <i className="fa-brands fa-rocketchat" /> Chat
          </Link>
          <Link to="#" className="btn btn-primary">
            <i className="fa-solid fa-circle-xmark" />
            Cancel
          </Link>
        </div>
      </div>
      {/* /Booking List */}
      {/* Pagination */}
      <div className="row">
        <div className="col-sm-6">
          <div className="review-entries">
            <span>Show</span>
            <select>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span>entries</span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="review-pagination">
            <p>1 - 5 of 10</p>
            <ul className="pagination">
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  2 <span className="visually-hidden">(current)</span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Pagination */}
    </div>
  );
};

export default CustomerBooking;
