export const inProgressBookingData = [
  {
    '#': '1',
    date: '28 Sep 2023',
    bookingTime: '10:00:00 - 11:00:00',
    provider: 'John Smith',
    user: 'Sharon',
    service: 'Computer Repair',
    amount: '$80',
    status: 'Inprogress',
    img1: 'assets/img/customer/user-01.jpg',
    img2: 'assets/img/customer/user-03.jpg',
    img3: 'assets/img/services/service-03.jpg',
  },
  {
    '#': '2',
    date: '10 Sep 2023',
    bookingTime: '18:00:00 - 19:00:00',
    provider: 'Johnny',
    user: 'Pricilla',
    service: 'Car Repair Services',
    amount: '$50',
    status: 'Inprogress',
    img1: 'assets/img/customer/user-04.jpg',
    img2: 'assets/img/customer/user-05.jpg',
    img3: 'assets/img/services/service-02.jpg',
  },
  {
    '#': '3',
    date: '25 Sep 2023',
    bookingTime: '12:00:00 - 13:00:00',
    provider: 'Robert',
    user: 'Amanda',
    service: 'Steam Car Wash',
    amount: '$50',
    status: 'Inprogress',
    img1: 'assets/img/customer/user-06.jpg',
    img2: 'assets/img/customer/user-02.jpg',
    img3: 'assets/img/services/service-04.jpg',
  },
  {
    '#': '4',
    date: '08 Sep 2023',
    bookingTime: '07 Oct 2023 11:22:51',
    provider: 'Sharonda',
    user: 'James',
    service: 'House Cleaning',
    amount: '$50',
    status: 'Inprogress',
    img1: 'assets/img/customer/user-09.jpg',
    img2: 'assets/img/customer/user-01.jpg',
    img3: 'assets/img/services/service-09.jpg',
  },
];
