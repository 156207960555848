import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const ProviderChat = () => {
  const [addClass, setAddClass] = useState(false);

  const toggleClass = () => {
    setAddClass((prevAddClass) => !prevAddClass);
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="container">
          <div className="customer-chat">
            <div className="row chat-window">
              {/* Chat User List */}
              <div className="col-lg-3 chat-cont-left d-flex">
                <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                  <div className="chat-header">
                    <div>
                      <h6>Chats</h6>
                      <p>Start New Conversation</p>
                    </div>
                  </div>
                  <div className="chat-search chat-search-head">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control search-chat chat-search"
                      />
                      <div className="search-icon-right">
                        <span className="search_btn">
                          <Icon.Search className="react-feather-custom" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body contacts_body chat-users-list chat-scroll">
                    <div className="chat-header inner-chat-header pt-0">
                      <div>
                        <h6>Recent</h6>
                      </div>
                    </div>
                    <Link to="#" className="media d-flex active">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar avatar-online">
                          <ImageWithBasePath
                            src="assets/img/profiles/chat-profile-01.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div>
                          <div className="user-name">Hendrita</div>
                          <div className="user-last-chat">
                            Lorem ipsum dolor
                          </div>
                        </div>
                        <div>
                          <div className="last-chat-time">10:12 AM</div>
                        </div>
                      </div>
                    </Link>
                    <Link to="#" className="media d-flex read-chat">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar avatar-online">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div>
                          <div className="user-name">John Smith</div>
                          <div className="user-last-chat">
                            <Icon.Image className="react-feather-custom" />{' '}
                            Image
                          </div>
                        </div>
                        <div>
                          <div className="last-chat-time">10:19 AM</div>
                          <div className="badge badge-primary badge-pill">
                            2
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="#" className="media d-flex">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div>
                          <div className="user-name">Audrey</div>
                          <div className="user-last-chat">
                            <Icon.Video className="react-feather-custom" />{' '}
                            Video
                          </div>
                        </div>
                        <div>
                          <div className="last-chat-time">7:30 PM</div>
                        </div>
                      </div>
                    </Link>
                    <Link to="#" className="media read-chat d-flex">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar avatar-away">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div>
                          <div className="user-name">David</div>
                          <div className="user-last-chat">
                            <Icon.FileText className="react-feather-custom" />{' '}
                            Document
                          </div>
                        </div>
                        <div>
                          <div className="last-chat-time">6:59 PM</div>
                        </div>
                      </div>
                    </Link>
                    <Link to="#" className="media read-chat d-flex">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar avatar-online">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div>
                          <div className="user-name">Ashley</div>
                          <div className="user-last-chat">typing...</div>
                        </div>
                        <div>
                          <div className="last-chat-time">11:21 AM</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Chat User List */}
              {/* Chat Content */}
              <div
                className={`col-lg-9 chat-cont-right chat-window-long ${
                  addClass ? 'window-full-width' : ''
                }`}
              >
                {/* Chat History */}
                <div className="card chat-window mb-0">
                  <div className="card-header msg_head">
                    <div className="d-flex bd-highlight">
                      <Link
                        id="back_user_list"
                        to="#"
                        className="back-user-list"
                      >
                        <i className="fas fa-chevron-left" />
                      </Link>
                      <div className="img_cont">
                        <ImageWithBasePath
                          className="rounded-circle user_img"
                          src="assets/img/profiles/chat-profile-01.jpg"
                          alt="User"
                        />
                      </div>
                      <div className="user_info">
                        <span>Hendrita</span>
                        <p className="mb-0 active">Online</p>
                      </div>
                    </div>
                    <div className="chat-options">
                      <ul>
                        <li>
                          <Link to="#">
                            <Icon.Volume2
                              color="#74788d"
                              className="react-feather-custom"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.Search
                              color="#74788d"
                              className="react-feather-custom"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <Icon.Video
                              color="#74788d"
                              className="react-feather-custom"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="chat-profile-open"
                            onClick={toggleClass}
                          >
                            <Icon.User
                              color="#74788d"
                              id="task_chat"
                              className="react-feather-custom"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="with-bg">
                            <Icon.MoreHorizontal
                              color="#74788d"
                              className="react-feather-custom"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body msg_card_body chat-scroll pt-0">
                    <ul className="list-unstyled">
                      <li className="chat-date mt-0">
                        <span>Yesterday</span>
                      </li>
                      <li className="media received d-flex">
                        <div className="avatar flex-shrink-0">
                          <ImageWithBasePath
                            src="assets/img/profiles/chat-profile-01.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="media-body flex-grow-1">
                          <div className="msg-box">
                            <div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, incididunt ut labore et dolore
                                magna aliqua.{' '}
                              </p>
                            </div>
                            <span className="drop-item message-more-option">
                              <Link
                                to="#"
                                className="more-vertical-bar"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Icon.MoreVertical className="react-feather-custom" />
                              </Link>
                              <small className="dropdown-menu">
                                <Link className="dropdown-item" to="#">
                                  <Icon.Copy className="me-2 react-feather-custom" />
                                  Copy
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <Icon.Trash2 className="me-2 react-feather-custom" />
                                  Delete
                                </Link>
                              </small>
                            </span>
                            <ul className="chat-msg-info name-date">
                              <li>Hendrita</li>
                              <li>
                                <span className="chat-time">
                                  09:30 am
                                  <i className="fa-solid fa-check-double read" />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li className="media received d-flex">
                        <div className="avatar flex-shrink-0">
                          <ImageWithBasePath
                            src="assets/img/profiles/chat-profile-01.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="media-body flex-grow-1">
                          <div className="msg-box">
                            <div className="msg-img">
                              <div className="chat-msg-attachments">
                                <div className="chat-attachment">
                                  <ImageWithBasePath
                                    src="assets/img/chat-img-01.jpg"
                                    alt="Attachment"
                                  />
                                  <Link to="#" className="chat-attach-download">
                                    <i className="fas fa-download" />
                                  </Link>
                                </div>
                                <div className="chat-attachment">
                                  <ImageWithBasePath
                                    src="assets/img/chat-img-02.jpg"
                                    alt="Attachment"
                                  />
                                  <Link to="#" className="chat-attach-download">
                                    <i className="fas fa-download" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <span className="drop-item message-more-option">
                              <Link
                                to="#"
                                className="more-vertical-bar"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Icon.MoreVertical className="react-feather-custom" />
                              </Link>
                              <small className="dropdown-menu">
                                <Link className="dropdown-item" to="#">
                                  <Icon.Copy className="me-2 react-feather-custom" />
                                  Copy
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <Icon.Trash2 className="me-2 react-feather-custom" />
                                  Delete
                                </Link>
                              </small>
                            </span>
                            <ul className="chat-msg-info name-date">
                              <li>Hendrita</li>
                              <li>
                                <span className="chat-time">
                                  09:30 am
                                  <i className="fa-solid fa-check-double un-read" />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li className="media sent d-flex">
                        <div className="avatar flex-shrink-0">
                          <ImageWithBasePath
                            src="assets/img/profiles/chat-profile-02.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="media-body flex-grow-1">
                          <div className="msg-box">
                            <div>
                              <p>
                                Sed ut perspiciatis unde omnis iste natus error
                                accusantium doloremque laudantium
                              </p>
                            </div>
                            <span className="drop-item message-more-option">
                              <Link
                                to="#"
                                className="more-vertical-bar"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Icon.MoreVertical className="react-feather-custom" />
                              </Link>
                              <small className="dropdown-menu">
                                <Link className="dropdown-item" to="#">
                                  <Icon.Copy className="me-2 react-feather-custom" />
                                  Copy
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <Icon.Trash2 className="me-2 react-feather-custom" />
                                  Delete
                                </Link>
                              </small>
                            </span>
                            <ul className="chat-msg-info name-date">
                              <li>
                                <span className="chat-time">
                                  10:45 am
                                  <i className="fa-solid fa-check" />
                                </span>
                              </li>
                              <li>John Steven</li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li className="chat-date">
                        <span>Today</span>
                      </li>
                      <li className="media received d-flex">
                        <div className="avatar flex-shrink-0">
                          <ImageWithBasePath
                            src="assets/img/profiles/chat-profile-01.jpg"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="media-body flex-grow-1">
                          <div className="msg-box">
                            <div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, incididunt ut labore et dolore
                                magna aliqua.{' '}
                              </p>
                            </div>
                            <span className="drop-item message-more-option">
                              <Link
                                to="#"
                                className="more-vertical-bar"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Icon.MoreVertical className="react-feather-custom" />
                              </Link>
                              <small className="dropdown-menu">
                                <Link className="dropdown-item" to="#">
                                  <Icon.Copy className="me-2 react-feather-custom" />
                                  Copy
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <Icon.Trash2 className="me-2 react-feather-custom" />
                                  Delete
                                </Link>
                              </small>
                            </span>
                            <ul className="chat-msg-info name-date">
                              <li>Hendrita</li>
                              <li>
                                <span className="chat-time">
                                  09:30 am
                                  <i className="fa-solid fa-check-double read" />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    <div className="input-group">
                      <div className="btn-file btn">
                        <Icon.Plus className="react-feather-custom" />
                        <input type="file" />
                      </div>
                      <input
                        className="form-control type_msg mh-auto empty_check"
                        placeholder="Write your message..."
                      />
                      <div className="send-action">
                        <Link to="#">
                          <i className="fa-solid fa-face-smile" />
                        </Link>
                        <Link to="#">
                          <Icon.Mic className="react-feather-custom" />
                        </Link>
                        <button className="btn btn-primary btn_send">
                          <i className="fa fa-paper-plane" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Chat Profile */}
                <div
                  className="chat-cont-profile chat-profile-right"
                  id="task_window"
                >
                  <div className="card mb-sm-3 mb-md-0 flex-fill">
                    <div className="card-header">
                      <div className="profile-wrap">
                        <div className="chat-profile">
                          <Link
                            id="back_user_lists"
                            to="#"
                            className="back-user-list"
                          >
                            <i className="fas fa-chevron-left" />
                          </Link>
                          <div className="chat-profile-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/chat-profile-01.jpg"
                              alt="User"
                            />
                          </div>
                          <div className="chat-profile-info">
                            <h6>Hendrita</h6>
                            <p>Newyork</p>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <Link to="#">
                              <Icon.Mail className="react-feather-custom" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <Icon.Phone className="react-feather-custom" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <Icon.MapPin className="react-feather-custom" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="chat-pro-list">
                        <ul>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-briefcase" />
                              Company
                            </span>
                            <span className="role-info">Company Name</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-user" />
                              Role
                            </span>
                            <span className="role-info">Co-founder</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-phone" />
                              Phone
                            </span>
                            <span className="role-info">+1 123 456 7890</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-envelope" />
                              Email
                            </span>
                            <span className="role-info">
                              info@johnsteven.com
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="chat-media">
                        <div className="chat-media-title">
                          <h6>Shared Media</h6>
                          <span>(3475 items)</span>
                        </div>
                        <div className="media-list">
                          <ul className="nav">
                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-photo"
                                className="active"
                              >
                                Photos
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-file"
                              >
                                File
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-vdo"
                              >
                                Video
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-link"
                              >
                                Link
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content pt-0">
                          <div className="tab-pane active" id="chat-photo">
                            <div className="photo-list">
                              <ul>
                                <li>
                                  <ImageWithBasePath
                                    src="assets/img/services/service-03.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <ImageWithBasePath
                                    src="assets/img/services/service-04.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <ImageWithBasePath
                                    src="assets/img/services/service-05.jpg"
                                    alt="img"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-vdo">
                            <div className="photo-list">
                              <ul>
                                <li>
                                  <ImageWithBasePath
                                    src="assets/img/services/service-01.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <ImageWithBasePath
                                    src="assets/img/services/service-04.jpg"
                                    alt="img"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-file">
                            <div className="photo-file">
                              <ul>
                                <li>
                                  <Icon.FileText className="me-2 react-feather-custom" />{' '}
                                  admin_v1.0.zip
                                </li>
                                <li>
                                  <Icon.FileText className="me-2 react-feather-custom" />{' '}
                                  test.pdf
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-link">
                            <div className="photo-link">
                              <ul>
                                <li>
                                  <Link to="#">
                                    <Icon.Link2 className="me-2 react-feather-custom" />{' '}
                                    Sed ut perspiciatis
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <Icon.Link2 className="me-2 react-feather-custom" />{' '}
                                    https:example.com
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="chat-notification">
                          <ul>
                            <li>
                              <p>
                                <i className="fa-solid fa-bell" /> Notifications
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_1"
                                  className="check"
                                  defaultChecked
                                />
                                <label
                                  htmlFor="status_1"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i className="fa-solid fa-star" /> Add To
                                Favourites
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_2"
                                  className="check"
                                  defaultChecked
                                />
                                <label
                                  htmlFor="status_2"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i className="fa-solid fa-volume-xmark" /> Mute
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_3"
                                  className="check"
                                />
                                <label
                                  htmlFor="status_3"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="clear-list">
                          <ul>
                            <li>
                              <Link to="#">
                                <Icon.Trash2 className="react-feather-custom" />
                                Clear Chat
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <Icon.ExternalLink className="react-feather-custom" />
                                Export Chat
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <Icon.AlertCircle className="react-feather-custom" />
                                Report Contact
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Chat Content */}
              </div>
              {/* /Chat Content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderChat;
