import React, { useState, useEffect } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

type Props = {
  prevTab: CallableFunction;
  nextTab: CallableFunction;
  setAvailabilityData: (data: any) => void;
};

const Availability: React.FC<Props> = ({
  prevTab,
  nextTab,
  setAvailabilityData,
}) => {
  dayjs.extend(customParseFormat);

  const [workingHours, setWorkingHours] = useState([
    {
      from: dayjs('00:00:00', 'HH:mm:ss'),
      to: dayjs('00:00:00', 'HH:mm:ss'),
      slot: '',
      day: 'ALL_DAYS',
    },
  ]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const formattedWorkingHours = workingHours.map(({ from, to, ...rest }) => ({
      ...rest,
      from: from.format('h:mm A'),
      to: to.format('h:mm A'),
    }));
    setAvailabilityData(formattedWorkingHours);
  }, [workingHours, setAvailabilityData]);

  const addNewWorkingHourRow = () => {
    setWorkingHours([
      ...workingHours,
      {
        from: dayjs('00:00:00', 'HH:mm:ss'),
        to: dayjs('00:00:00', 'HH:mm:ss'),
        slot: '',
        day: 'ALL_DAYS',
      },
    ]);
  };

  const deleteWorkingHourRow = (index: number) => {
    const updatedWorkingHours = workingHours.filter((_, idx) => idx !== index);
    setWorkingHours(updatedWorkingHours);
  };

  const handleTimeChange = (index: number, key: string, value: any) => {
    const updatedWorkingHours = workingHours.map((hour, idx) =>
      idx === index ? { ...hour, [key]: value } : hour,
    );
    setWorkingHours(updatedWorkingHours);
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const updatedWorkingHours = workingHours.map((hour, idx) =>
      idx === index ? { ...hour, [name]: value } : hour,
    );
    setWorkingHours(updatedWorkingHours);
  };

  const handleDayChange = (index: number, day: string) => {
    const updatedWorkingHours = workingHours.map((hour, idx) =>
      idx === index ? { ...hour, day } : hour,
    );
    setWorkingHours(updatedWorkingHours);
  };

  const validateForm = () => {
    const errorMessages: string[] = [];

    // Check for empty slots
    workingHours.forEach((hour, index) => {
      if (!hour.slot.trim()) {
        errorMessages.push(`Slot cannot be empty at row ${index + 1}`);
      }
    });

    // Check for duplicate entries
    const uniqueEntries = new Set();
    workingHours.forEach((hour, index) => {
      const entry = `${hour.day}-${hour.from.format('HH:mm')}-${hour.to.format('HH:mm')}-${hour.slot}`;
      if (uniqueEntries.has(entry)) {
        errorMessages.push(`You set the same availability time more than once at row ${index + 1}`);
      } else {
        uniqueEntries.add(entry);
      }
    });

    setErrors(errorMessages);
    return errorMessages.length === 0;
  };

  const handleNextClick = () => {
    if (validateForm()) {
      nextTab();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="available-section card-section">
            <div className="available-heading">
              <h4>Availability</h4>
            </div>
            <label className="col-form-label">Configure Time Slot</label>
            <div className="timeslot-sec available-time-slot">
             
              <div className="tab-content pt-0">
                <div className="tab-pane active" id="all_days">
                  <div className="hours-info">
                    {/* <h4 className="nameof-day">Configure Your Availability</h4> */}
                    {workingHours.map((hour, index) => (
                      <div key={index} className="row hours-cont">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Day</label>
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {hour.day.replace('_', ' ')}
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'ALL_DAYS')}>All Days</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'MONDAY')}>Monday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'TUESDAY')}>Tuesday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'WEDNESDAY')}>Wednesday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'THURSDAY')}>Thursday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'FRIDAY')}>Friday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'SATURDAY')}>Saturday</Link>
                                <Link className="dropdown-item" to="#" onClick={() => handleDayChange(index, 'SUNDAY')}>Sunday</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>From</label>
                            <div className="form-availability-field">
                              <TimePicker
                                className="timepicker input-group-text"
                                onChange={(value) =>
                                  handleTimeChange(index, 'from', value)
                                }
                                value={hour.from}
                                format="h:mm A"
                              />
                              <span className="cus-icon">
                                <i className="fe fe-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>To</label>
                            <div className="form-availability-field">
                              <TimePicker
                                className="timepicker input-group-text"
                                onChange={(value) =>
                                  handleTimeChange(index, 'to', value)
                                }
                                value={hour.to}
                                format="h:mm A"
                              />
                              <span className="cus-icon">
                                <i className="fe fe-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Slot</label>
                            <input
                              type="text"
                              className="form-control"
                              name="slot"
                              value={hour.slot}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              placeholder="Enter Slot"
                            />
                          </div>
                        </div>
                        {index > 0 && (
                          <div className="col-md-1">
                            <button
                              onClick={() => deleteWorkingHourRow(index)}
                              className="btn btn-danger-outline delete-icon"
                            >
                              <Icon.Trash2 className="react-feather-custom trashicon" />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    {errors.length > 0 && (
                      <div className="alert alert-danger mt-3">
                        <ul>
                          {errors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <Link
                    to="#"
                    className="link-sets add-text add-hours"
                    onClick={addNewWorkingHourRow}
                  >
                    <i className="fe fe-plus-circle" /> Add More
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="bottom-btn">
                    <div className="field-btns">
                      <button
                        className="btn btn-prev prev_btn"
                        type="button"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        onClick={prevTab}
                      >
                        <i className="fas fa-arrow-left" /> Prev
                      </button>
                      <button
                        className="btn btn-primary next_btn"
                        type="button"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        
                        onClick={handleNextClick}
                      >
                        Next <i className="fas fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Availability;
