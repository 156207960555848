import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { createService } from '../../../../core/data/api/api';

type Props = {
  prevTab: CallableFunction;
  formData: any;
  setMediaData: (media: string[]) => void; 
  setFormData: (data: any) => void;
};

const Gallery: React.FC<Props> = ({ prevTab, formData, setMediaData, setFormData }) => {
  const [media, setMedia] = useState<string[]>([]);
  const [token, setToken] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    setMediaData(media);
  }, [media, setMediaData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImages = Array.from(files).map(file => URL.createObjectURL(file));
      setMedia(prevImages => [...prevImages, ...newImages]);
    }
  };

  const deleteImage = (index: number) => {
    const updatedImages = media.filter((_, i) => i !== index);
    setMedia(updatedImages);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  const handleNextClick = async () => {
    console.log('All form data we are getting:', formData);
    try {
      const response = await createService(token, formData);
      setMessage(response.message);
      setIsSuccess(true);
    } catch (error) {
      setMessage('Error creating service. Please try again.');
      setIsSuccess(false);
      console.error('Error creating service:', error);
    }
  };

  const handleAddNewService = () => {
    setIsSuccess(null);
    setMessage('');
    setMedia([]);
    setFormData({});
    prevTab();
  };

  return (
    <>
      <div className="addition-service card-section">
        <div className="heading-service">
          <h4>Gallery</h4>
        </div>
        <div className="form-uploads mb-4">
          <div className="form-uploads-path">
            <img src="assets/img/icons/upload.svg" alt="img" />
            <div className="file-browse">
              <h6>Drag & drop image or </h6>
              <div className="file-browse-path">
                <input type="file" multiple onChange={handleFileChange} />
                <Link to="#"> Browse</Link>
              </div>
            </div>
            <h5>Supported formats: JPEG, PNG</h5>
          </div>
        </div>
        <div className="file-preview">
          <label>Select Default Image</label>
          <ul className="gallery-selected-img">
            {media.map((image, index) => (
              <li key={index}>
                <div className="img-preview">
                  <img
                    src={image}
                    alt={`                  Service Image ${index + 1}`}
                    style={{ width: '100px', height: '100px' }}
                  />
                  <Link
                    to="#"
                    className="remove-gallery"
                    onClick={() => deleteImage(index)}
                  >
                    <Icon.Trash2 style={{ width: '16px', height: '16px' }} />
                  </Link>
                </div>
                <label className="custom_check">
                  <input type="radio" name="gallery" defaultChecked />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bottom-btn">
        <div className="field-btns">
          <button
            className="btn btn-prev prev_btn"
            type="button"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
            onClick={prevTab}
          >
            <i className="fas fa-arrow-left" /> Prev
          </button>
          <button
            className="btn btn-primary next_btn"
            type="button"
            onClick={handleNextClick}
          >
            Save <i className="fas fa-arrow-right" />
          </button>
        </div>
      </div>

      {isSuccess !== null && (
        <div className={`popup-message ${isSuccess ? 'success' : 'error'}`}>
          <div className="popup-content">
            <p>{message}</p>
            {isSuccess ? (
              <>
                
                <button className="btn btn-primary" >
                <a className='text-black ' href="/admin/services/add-service">Add New Service</a>
                </button>
                <button className="btn btn-secondary" onClick={() => setIsSuccess(null)}>
                  Close
                </button>
              </>
            ) : (
              <button className="btn btn-secondary" onClick={() => setIsSuccess(null)}>
                Back to Form
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;

