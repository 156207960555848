import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { deleteCategoryById } from '../../../../core/data/api/api'; // Import the delete API function

interface CategoriesModalProps {
  selectedCatogries: {
    id: string;
  };
}

const DeleteCategoriesModal: React.FC<CategoriesModalProps> = ({
  selectedCatogries,
}) => {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    try {
      await deleteCategoryById(token, selectedCatogries.id);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        const modalElement = document.getElementById('delete-category');
        if (modalElement) {
          (modalElement as any).classList.remove('show');
          (modalElement as any).style.display = 'none';
          document.body.classList.remove('modal-open');
          const backdrop = document.querySelector('.modal-backdrop');
          if (backdrop) {
            backdrop.remove();
          }
        }
        setSuccess(false); 
      }, 2000); 
    } catch (error) {
      setError('Failed to delete category');
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="modal fade" id="delete-category">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body pt-0">
              <div className="text-center">
                <Icon.Trash2 size={45} color='#f05050 ' className="text-danger fs-1" />
                <div className="mt-4">
                  <h4>Delete Category?</h4>
                  {error && <p className="text-danger">{error}</p>}
                  {success && <p className="text-success">Category deleted successfully!</p>}
                </div>
              </div>
              {!success && (
                <div className="d-flex gap-2 justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn w-sm btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn w-sm btn-danger"
                    onClick={handleDelete}
                    disabled={loading}
                  >
                    {loading ? 'Deleting...' : 'Yes, Delete It!'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCategoriesModal;
