export const banktransfer = [
  {
    '#': '1',
    img1: 'assets/admin/img/services/service-03.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Computer Repair',
    customer: 'John Smith',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '28 Sep 2023',
    status: 'Pending',
  },
  {
    '#': '2',
    img1: 'assets/admin/img/services/service-02.jpg',
    img2: 'assets/admin/img/customer/user-04.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Car Repair Services',
    customer: 'Johnny',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '23 Sep 2023',
    status: 'Successful',
  },
  {
    '#': '3',
    img1: 'assets/admin/img/services/service-04.jpg',
    img2: 'assets/admin/img/customer/user-02.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Steam Car Wash',
    customer: 'Amanda',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '13 Sep 2023',
    status: 'Approved',
  },
  {
    '#': '4',
    img1: 'assets/admin/img/services/service-09.jpg',
    img2: 'assets/admin/img/customer/user-04.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'House Cleaning',
    customer: 'James',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '10 Sep 2023',
    status: 'Rejected',
  },
  {
    '#': '4',
    img1: 'assets/admin/img/services/service-03.jpg',
    img2: 'assets/admin/img/customer/user-02.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'House Cleaning',
    customer: 'James',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '10 Sep 2023',
    status: 'Pending',
  },
  {
    '#': '5',
    img1: 'assets/admin/img/services/service-02.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Computer Repair',
    customer: 'John Smith',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '28 Sep 2023',
    status: 'Pending',
  },
  {
    '#': '6',
    img1: 'assets/admin/img/services/service-04.jpg',
    img2: 'assets/admin/img/customer/user-02.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Car Repair Services',
    customer: 'Johnny',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '23 Sep 2023',
    status: 'Successful',
  },
  {
    '#': '7',
    img1: 'assets/admin/img/services/service-09.jpg',
    img2: 'assets/admin/img/customer/user-04.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'Steam Car Wash',
    customer: 'Amanda',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '13 Sep 2023',
    status: 'Approved',
  },
  {
    '#': '8',
    img1: 'assets/admin/img/services/service-03.jpg',
    img2: 'assets/admin/img/customer/user-02.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'House Cleaning',
    customer: 'James',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '10 Sep 2023',
    status: 'Rejected',
  },
  {
    '#': '9',
    img1: 'assets/admin/img/services/service-02.jpg',
    img2: 'assets/admin/img/customer/user-04.jpg',
    img3: 'assets/admin/img/invoice.jpg',
    service: 'House Cleaning',
    customer: 'James',
    receipt: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '10 Sep 2023',
    status: 'Pending',
  },
];
