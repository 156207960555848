export const providerEarningsAdminData = [
  {
    '#': '1',
    service: 'Computer Repair',
    user: 'Mark',
    paymentType: 'Wallet',
    amount: '$38.25',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-03.jpg',
  },
  {
    '#': '2',
    service: 'Car Repair Services',
    user: 'Bernice',
    paymentType: 'COD',
    amount: '$20',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-02.jpg',
  },
  {
    '#': '3',
    service: 'Steam Car Wash',
    user: 'Arlene',
    paymentType: 'Wallet',
    amount: '$10',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-04.jpg',
  },
  {
    '#': '4',
    service: 'House Cleaning',
    user: 'Margarett',
    paymentType: 'COD',
    amount: '$90',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
  {
    '#': '5',
    service: 'Computer Repair',
    user: 'Kevin',
    paymentType: 'Wallet',
    amount: '$38.25',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-03.jpg',
  },
  {
    '#': '6',
    service: 'Car Repair Services',
    user: 'Willard',
    paymentType: 'COD',
    amount: '$20',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
  {
    '#': '7',
    service: 'Steam Car Wash',
    user: 'Emma',
    paymentType: 'Wallet',
    amount: '$10',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-04.jpg',
  },
  {
    '#': '8',
    service: 'House Cleaning',
    user: 'Eva',
    paymentType: 'COD',
    amount: '$90',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
];
