import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Pricing = () => {
  const routes = all_routes;
  return (
      <>
        <div className="bg-img">
          <ImageWithBasePath
            src="assets/img/bg/work-bg-03.png"
            alt="img"
            className="bgimg1"
          />
          <ImageWithBasePath
            src="assets/img/bg/work-bg-03.png"
            alt="img"
            className="bgimg2"
          />
        </div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Pricing</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Pricing
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="price-toggle">
                  <div className="status-toggle d-inline-flex align-items-center">
                    Monthly
                    <input type="checkbox" id="status_1" className="check" />
                    <label htmlFor="status_1" className="checktoggle">
                      checkbox
                    </label>
                    Yearly
                  </div>
                </div>
              </div>
              {/* Price List */}
              <div className="col-md-4 d-flex">
                <div className="price-card flex-fill">
                  <div className="price-head">
                    <div className="price-level">
                      <h6>Basic</h6>
                    </div>
                    <h1>
                      $50 <span>/ month</span>
                    </h1>
                  </div>
                  <div className="price-body">
                    <ul>
                      <li className="active">10 Services</li>
                      <li className="active">10 Stafff</li>
                      <li className="active">100 Appointments</li>
                      <li className="inactive">Gallery</li>
                      <li className="inactive">Addition Services</li>
                    </ul>
                    <div className="text-center">
                      <Link to="#" className="btn btn-choose">
                        Choose <i className="feather-arrow-right-circle" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Price List */}
              {/* Price List */}
              <div className="col-md-4 d-flex">
                <div className="price-card flex-fill">
                  <div className="price-head">
                    <div className="price-level">
                      <h6>Business</h6>
                      <span className="badge-success">Recommended</span>
                    </div>
                    <h1>
                      $200 <span>/ month</span>
                    </h1>
                  </div>
                  <div className="price-body">
                    <ul>
                      <li className="active">20 Services</li>
                      <li className="active">20 Stafff</li>
                      <li className="active">Unlimited Appointments</li>
                      <li className="inactive">Gallery</li>
                      <li className="inactive">Addition Services</li>
                    </ul>
                    <div className="text-center">
                      <Link to="#" className="btn btn-choose">
                        Choose <i className="feather-arrow-right-circle" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Price List */}
              {/* Price List */}
              <div className="col-md-4 d-flex">
                <div className="price-card flex-fill">
                  <div className="price-head">
                    <div className="price-level">
                      <h6>Enterprise</h6>
                    </div>
                    <h1>
                      $450 <span>/ month</span>
                    </h1>
                  </div>
                  <div className="price-body">
                    <ul>
                      <li className="active">Unlimited Services</li>
                      <li className="active">Unlimited Stafff</li>
                      <li className="active">Unlimited Appointments</li>
                      <li className="active">Gallery</li>
                      <li className="active">Addition Services</li>
                    </ul>
                    <div className="text-center">
                      <Link to="#" className="btn btn-choose">
                        Choose <i className="feather-arrow-right-circle" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Price List */}
            </div>
          </div>
        </div>
      </>
  );
};

export default Pricing;
