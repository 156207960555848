export const RejectedTranferlistdata = [
  {
    id: '1',
    name: 'Computer Repair',
    customer: 'John Smith',
    invoice: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '28 Sep 2023',
    status: 'Rejected',
    image: '/assets/img/services/service-03.jpg',
    customerimg: '/assets/img/customer/user-01.jpg',
    invoiceimg: '/assets/admin/img/invoice.jpg',
    className: 'badge-delete',
  },
  {
    id: '2',
    name: 'Car Repair Services',
    customer: 'Johnny',
    invoice: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '23 Sep 2023',
    status: 'Rejected',
    image: '/assets/img/services/service-02.jpg',
    customerimg: '/assets/img/customer/user-04.jpg',
    invoiceimg: '/assets/admin/img/invoice.jpg',
    className: 'badge-delete',
  },
  {
    id: '3',
    name: 'Steam Car Wash',
    customer: 'Amanda',
    invoice: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '13 Sep 2023',
    status: 'Rejected',
    image: '/assets/img/services/service-04.jpg',
    customerimg: '/assets/img/customer/user-02.jpg',
    invoiceimg: '/assets/admin/img/invoice.jpg',
    className: 'badge-delete',
  },
  {
    id: '4',
    name: 'House Cleaning',
    customer: 'James',
    invoice: '#123456',
    description: 'Lorem ipsum dolor sit',
    date: '10 Sep 2023',
    status: 'Rejected',
    image: '/assets/img/services/service-09.jpg',
    customerimg: '/assets/img/customer/user-01.jpg',
    invoiceimg: '/assets/admin/img/invoice.jpg',
    className: 'badge-delete',
  },
];
