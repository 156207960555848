import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Faq = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Frequently Asked Questions</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Frequently Asked Questions
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Faq List */}
            <div className="col-md-10 mx-auto">
              <div className="faq-section">
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqOne"
                      aria-expanded="false"
                    >
                      How does Power Buddy function?
                    </Link>
                  </h4>
                  <div id="faqOne" className="card-collapse collapse">
                    <p>
                      First you schedule an appointment after downloading our
                      app or visiting our website. Then you will select the
                      service you require, pick a time slot, and make your
                      appointment. A trained specialist will arrive at your
                      location at the appointed time to perform the services.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqTwo"
                      aria-expanded="false"
                    >
                      Which areas do you work in?
                    </Link>
                  </h4>
                  <div id="faqTwo" className="card-collapse collapse">
                    <p>
                      We are a fast-developing company and opening service
                      centers at different locations. Please check our website
                      or app to see if we are available where you are.
                    </p>
                  </div>
                </div>
                {/* Booking and Scheduling */}
               
                <h5 className='mb-2 mt-5 text-primary'>Booking and Scheduling</h5>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqThree"
                      aria-expanded="false"
                    >
                      How do I book a service?
                    </Link>
                  </h4>
                  <div id="faqThree" className="card-collapse collapse">
                    <p>
                      You can book a service using our app or website. Select
                      the service you require, select your preferred time slot,
                      and confirm your reservation.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqFour"
                      aria-expanded="false"
                    >
                      Is it possible to reschedule or cancel my booking?
                    </Link>
                  </h4>
                  <div id="faqFour" className="card-collapse collapse">
                    <p>
                      Yes, for your convenience the option of cancellation is
                      available on both the website and the application.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqFive"
                      aria-expanded="false"
                    >
                      Do I need to provide any materials or equipment for the
                      service?
                    </Link>
                  </h4>
                  <div id="faqFive" className="card-collapse collapse">
                    <p>
                      No, our professionals are mostly equipped with all of the
                      necessary tools and materials to perform their duties. If
                      some special material is required by you, we will inform
                      you in advance.
                    </p>
                  </div>
                </div>
             
                  
                {/* Services and Professionals */}
                <h5 className='mb-2 mt-5 text-primary'>Services and Professionals</h5>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqSeven"
                      aria-expanded="false"
                    >
                      What types of services do you offer?
                    </Link>
                  </h4>
                  <div id="faqSeven" className="card-collapse collapse">
                    <p>
                      We work for your every need and to make your life easier.
                      Thus, we provide a wide range of services and our most
                      utilized services are home cleaning, appliance repair,
                      beauty services, plumbing, electrical work, painting, pest
                      control, and more. For more information on services, you
                      can check out our services page.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqEight"
                      aria-expanded="false"
                    >
                      How do you verify the service personnel that you use?
                    </Link>
                  </h4>
                  <div id="faqEight" className="card-collapse collapse">
                    <p>
                      A rigorous background investigation and verification of
                      their degree of expertise and experience are performed on
                      each of our professionals. To guarantee that they deliver
                      exceptional service, we make sure they are appropriately
                      taught and qualified.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqNine"
                      aria-expanded="false"
                    >
                      Do the service providers have insurance?
                    </Link>
                  </h4>
                  <div id="faqNine" className="card-collapse collapse">
                    <p>
                      Absolutely, yes all our service providers are insured, and
                      you enjoy services with complete peace of mind.
                    </p>
                  </div>
                </div>
                {/* Cost and Method of Payment */}
                <h5 className='mb-2 mt-5 text-primary'>Cost and Method of Payment</h5>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqTen"
                      aria-expanded="false"
                    >
                      What is the price of your services?
                    </Link>
                  </h4>
                  <div id="faqTen" className="card-collapse collapse">
                    <p>
                      The type of service and your location determine the
                      pricing of the services. On our app or website, you can
                      find complete pricing information at the time of service
                      booking.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqEleven"
                      aria-expanded="false"
                    >
                      Are there any other fees that I should know about?
                    </Link>
                  </h4>
                  <div id="faqEleven" className="card-collapse collapse">
                    <p>
                      We clearly inform you of the prices of service at the time
                      of booking and there will be no additional charges.
                    </p>
                  </div>
                </div>

                {/* Client Assistance */}
                <h5 className='mb-2 mt-5 text-primary'>Client Assistance</h5>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqTwelve"
                      aria-expanded="false"
                    >
                      What is the process for contacting customer service?
                    </Link>
                  </h4>
                  <div id="faqTwelve" className="card-collapse collapse">
                    <p>
                      It is very easy to get in touch with our customer care,
                      you can reach our customer service department via the
                      website or app. We also offer email and phone support. You
                      may find our contact information on the &#39;Contact
                      Us&#39; tab.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqThirteen"
                      aria-expanded="false"
                    >
                      Can I give the service feedback?
                    </Link>
                  </h4>
                  <div id="faqThirteen" className="card-collapse collapse">
                    <p>
                      Thank you for your feedback. Using our app or website, you
                      can rank and review the service provider after the job is
                      done. We value your feedback because it enables us to
                      enhance our offerings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /Faq List */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
