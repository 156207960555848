import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import {
  getServiceById,
  updateServiceById,
  getCategoryList,
} from '../../../../core/data/api/api';
import { Link } from 'react-router-dom';

interface ServiceModalProps {
  selectedService: any;
}

const UpdateServiceModal: React.FC<ServiceModalProps> = ({
  selectedService,
}) => {
  const [token, setToken] = useState<string>('');
  const [serviceData, setServiceData] = useState<any>(null);
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [initialTimeSlots, setInitialTimeSlots] = useState<any[]>([]);
  const [initialAdditionalServices, setInitialAdditionalServices] = useState<
    any[]
  >([]);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);


  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const result = await getCategoryList(token);
        setCategoryData(result.data.payload.records);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    if (token) {
      fetchCategoryData();
    }
  }, [token]);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (!selectedService) return;
      setIsLoading(true);
      try {
        const result = await getServiceById(token, selectedService);
        setServiceData(result.data.payload);
        // console.log(result)
        if (result.data.payload.time_slots) {
          setInitialTimeSlots(result.data.payload.time_slots);
        }
        if (result.data.payload.additional_services) {
          setInitialAdditionalServices(result.data.payload.additional_services);
        }
      } catch (error) {
        console.error('Error fetching service data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchServiceData();
    }
  }, [token, selectedService]);



  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
    setFormChanged(true);
  };

  const handleNestedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    parent: string,
    key: string,
    index?: number,
  ) => {
    if (index !== undefined) {
      const newNestedArray = [...serviceData[parent]];
      newNestedArray[index] = {
        ...newNestedArray[index],
        [key]: e.target.value,
      };
      setServiceData({
        ...serviceData,
        [parent]: newNestedArray,
      });
    } else {
      setServiceData({
        ...serviceData,
        [parent]: {
          ...serviceData[parent],
          [key]: e.target.value,
        },
      });
    }
    setFormChanged(true);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategoryId = e.target.value;
    setServiceData({
      ...serviceData,
      sub_category: {
        ...serviceData.sub_category,
        category_id: selectedCategoryId,
      },
    });
    setFormChanged(true);
  };

  const handleDeleteAdditionalService = (index: number) => {
    const newAdditionalServices = [...serviceData.additional_services];
    newAdditionalServices.splice(index, 1);
    setServiceData({
      ...serviceData,
      additional_services: newAdditionalServices,
    });
    setFormChanged(true);
  };

  const handleAddAdditionalService = () => {
    const newAdditionalService = {
      id: Date.now(),
      title: '',
      price: '',
      duration: '',
    };
    setServiceData({
      ...serviceData,
      additional_services: [
        ...serviceData.additional_services,
        newAdditionalService,
      ],
    });
    setFormChanged(true);
  };

  const handleAddTimeSlot = () => {
    const newTimeSlot = {
      id: Date.now(),
      slot_type: '',
      from: '',
      to: '',
    };
    setServiceData({
      ...serviceData,
      time_slots: [...serviceData.time_slots, newTimeSlot],
    });
    setFormChanged(true);
  };

  const handleDeleteTimeSlot = (index: number) => {
    const newTimeSlots = [...serviceData.time_slots];
    newTimeSlots.splice(index, 1);
    setServiceData({ ...serviceData, time_slots: newTimeSlots });
    setFormChanged(true);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file),
      );
      setUploadedImages((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
      setFormChanged(true);
    }
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasTimeSlotsChanged =
      JSON.stringify(serviceData.time_slots) !==
      JSON.stringify(initialTimeSlots);
    const hasAdditionalServicesChanged =
      JSON.stringify(serviceData.additional_services) !==
      JSON.stringify(initialAdditionalServices);

    if (!formChanged) {
      alert('Nothing is updated in the form. Cannot submit.');
      return;
    }

    const transformedData = {
      title: serviceData.title,
      slug: serviceData.slug,
      buddy_id: serviceData.buddy_id,
      category_id: serviceData.sub_category.category_id,
      sub_category_id: serviceData.sub_category.id,
      price: serviceData.price,
      duration: serviceData.duration,
      description: serviceData.description,
      status: serviceData.status,
      additional_services: hasAdditionalServicesChanged
        ? serviceData.additional_services.map((service: any) => ({
            title: service.title,
            price: service.price,
            duration: service.duration,
          }))
        : initialAdditionalServices,
      video_link: serviceData.video_link,
      availability: hasTimeSlotsChanged
        ? serviceData.time_slots.map((slot: any) => ({
            from: slot.from,
            to: slot.to,
            slot: slot.slot,
            day: slot.slot_type,
          }))
        : initialTimeSlots,
      media: uploadedImages.length > 0 ? uploadedImages : serviceData.media,
      address: serviceData.location.address,
      country: serviceData.location.country,
      state: serviceData.location.state,
      city: serviceData.location.city,
      pincode: serviceData.location.pincode,
      gmap_place_id: serviceData.location.gmap_place_id,
      latitude: serviceData.location.latitude,
      longitude: serviceData.location.longitude,
    };

    console.log(
      'Submitting payload:',
      JSON.stringify(transformedData, null, 2),
    );

    try {
      const response = await updateServiceById(
        token,
        selectedService,
        transformedData,
      );
      console.log('Response from API:', JSON.stringify(response, null, 2));
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Error updating service:', error);
      alert('Failed to update service.');
    }
  };

  return (
    <div className="modal fade" id="update-service">
      <div className="modal-dialog modal-dialog-centered custom-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Service</h5>
            <button
              type="button"
              className="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Icon.X className="react-feather-custom" />
            </button>
          </div>
          <div className="modal-body pt-0">
            {isLoading ? (
              <p>Loading...</p>
            ) : serviceData ? (
              <div>
                {isSuccess ? (
                  <h5 className="text-center text-primary">
                    Service is successfully updated!
                  </h5>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Title:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={serviceData.title}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Slug:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="slug"
                            value={serviceData.slug}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Buddy ID:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="buddy_id"
                            value={serviceData.buddy_id}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Category:</label>
                          <select
                            className="form-control"
                            value={serviceData.sub_category.category_id}
                            onChange={handleCategoryChange}
                          >
                            {categoryData.map((category: any) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Price:</label>
                          <input
                            type="number"
                            className="form-control"
                            name="price"
                            value={serviceData.price}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Duration:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="duration"
                            value={serviceData.duration}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Status:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="status"
                            value={serviceData.status}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Description:</label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={serviceData.description}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Video Link:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="video_link"
                            value={serviceData.video_link}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="form-label">
                          Additional Services:
                        </label>
                        {serviceData.additional_services.map(
                          (additionalService: any, index: number) => (
                            <div key={additionalService.id} className="mb-3">
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`additionalServiceTitle_${index}`}
                                    value={additionalService.title}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'additional_services',
                                        'title',
                                        index,
                                      )
                                    }
                                    disabled={
                                      index < initialAdditionalServices.length
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`additionalServicePrice_${index}`}
                                    value={additionalService.price}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'additional_services',
                                        'price',
                                        index,
                                      )
                                    }
                                    disabled={
                                      index < initialAdditionalServices.length
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`additionalServiceDuration_${index}`}
                                    value={additionalService.duration}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'additional_services',
                                        'duration',
                                        index,
                                      )
                                    }
                                    disabled={
                                      index < initialAdditionalServices.length
                                    }
                                  />
                                </div>

                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleDeleteAdditionalService(index)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                        <button
                          type="button"
                          className="btn btn-primary mt-3"
                          onClick={handleAddAdditionalService}
                        >
                          Add Additional Service
                        </button>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="form-label">
                          Availability Time Slots:
                        </label>
                        {serviceData.time_slots.map(
                          (timeSlot: any, index: number) => (
                            <div key={timeSlot.id} className="mb-3">
                              <div className="row">
                                <div className="col-md-3">
                                  <select
                                    className="form-control mb-2"
                                    name={`timeSlotDay_${index}`}
                                    value={timeSlot.slot_type}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'time_slots',
                                        'slot_type',
                                        index,
                                      )
                                    }
                                    disabled={index < initialTimeSlots.length}
                                  >
                                    <option value="All DAYS">All Days</option>
                                    <option value="MONDAY">Monday</option>
                                    <option value="TUESDAY">Tuesday</option>
                                    <option value="WEDNESDAY">Wednesday</option>
                                    <option value="THURSDAY">Thursday</option>
                                    <option value="FRIDAY">Friday</option>
                                    <option value="STURDAY">Saturday</option>
                                    <option value="SUNDAY">Sunday</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`timeSlotFrom_${index}`}
                                    value={timeSlot.from}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'time_slots',
                                        'from',
                                        index,
                                      )
                                    }
                                    disabled={index < initialTimeSlots.length}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`timeSlotTo_${index}`}
                                    value={timeSlot.to}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'time_slots',
                                        'to',
                                        index,
                                      )
                                    }
                                    disabled={index < initialTimeSlots.length}
                                  />
                                </div>
                                <div className="col-md-1">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name={`timeSlotTo_${index}`}
                                    value={timeSlot.slot}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        e,
                                        'time_slots',
                                        'slot',
                                        index,
                                      )
                                    }
                                    disabled={index < initialTimeSlots.length}
                                  />
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleDeleteTimeSlot(index)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                        <button
                          type="button"
                          className="btn btn-primary mt-3"
                          onClick={handleAddTimeSlot}
                        >
                          Add Time Slot
                        </button>
                      </div>
                      <div className="col-md-12 mt-4">
                      <div className="mb-3">
                          <label className="form-label">Images:</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={handleImageUpload}
                          />
                        </div>
                        <div className="d-flex flex-wrap">
                          {uploadedImages.map((image, index) => (
                            <div key={index} className="me-2 mb-2">
                              <img
                                src={image}
                                alt={`uploaded ${index}`}
                                style={{ width: '100px', height: '100px' }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <h5>Location:</h5>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            value={serviceData.location.address}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'address')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Country:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={serviceData.location.country}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'country')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">State:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={serviceData.location.state}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'state')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">City:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={serviceData.location.city}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'city')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Pincode:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="pincode"
                            value={serviceData.location.pincode}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'pincode')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">
                            Google Maps Place ID:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="gmap_place_id"
                            value={serviceData.location.gmap_place_id}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'gmap_place_id')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Latitude:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="latitude"
                            value={serviceData.location.latitude}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'latitude')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="mb-3">
                          <label className="form-label">Longitude:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="longitude"
                            value={serviceData.location.longitude}
                            onChange={(e) =>
                              handleNestedChange(e, 'location', 'longitude')
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                )}
              </div>
            ) : (
              <p>Service data not available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateServiceModal;
