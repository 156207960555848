import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { getServiceById } from '../../../../core/data/api/api';

interface ServiceModalProps {
  selectedService: any;
}

const ServiceModal: React.FC<ServiceModalProps> = ({ selectedService }) => {
  const [token, setToken] = useState<string>('');
  const [serviceData, setServiceData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (!selectedService) return; // Return early if selectedService is not set
      setIsLoading(true); // Set loading state to true
      try {
        const result = await getServiceById(token, selectedService);
        setServiceData(result.data.payload);
      } catch (error) {
        console.error('Error fetching service data:', error);
      } finally {
        setIsLoading(false); // Set loading state to false
      }
    };

    if (token && selectedService) {
      fetchServiceData();
    }
  }, [token, selectedService]);

  return (
    <div className="modal fade" id="edit-service">
      <div className="modal-dialog modal-dialog-centered custom-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Service</h5>
            <button
              type="button"
              className="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Icon.X className="react-feather-custom" />
            </button>
          </div>
          <div className="modal-body pt-0">
            {isLoading ? (
              <p>Loading...</p>
            ) : serviceData ? (
              <form>
                <div className="row">
                  {/* Service Details */}
                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Title:</label>
                      <p className="fs-5">{serviceData.title}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Price:</label>
                      <p className="fs-5">{serviceData.price}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Duration:</label>
                      <p className="fs-5">{serviceData.duration}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Provider:</label>
                      <p className="fs-5">{serviceData.buddy_id}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Category:</label>
                      <p className="fs-5">
                        {serviceData.sub_category.category_id}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Subcategory:</label>
                      <p className="fs-5">{serviceData.sub_category.name}</p>
                    </div>
                  </div>

                  <div className="col-md-12 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Description:</label>
                      <p className="fs-5">{serviceData.description}</p>
                    </div>
                  </div>

                  <div className="col-md-12 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Video Link:</label>
                      <p className="fs-5">{serviceData.video_link}</p>
                    </div>
                  </div>
                </div>
                {/* Additional Services */}
                <h5 className="mt-4">Additional Services</h5>
                {serviceData.additional_services.map(
                  (additionalService: any) => (
                    <div key={additionalService.id} className="mb-3">
                      <div className="row">
                        <div className="col-md-4 border-bottom mt-1">
                          <label className="form-label">Title:</label>
                          <p className="fs-5">{additionalService.title}</p>
                        </div>

                        <div className="col-md-4 border-bottom mt-1">
                          <label className="form-label">Price:</label>
                          <p className="fs-5">{additionalService.price}</p>
                        </div>

                        <div className="col-md-4 border-bottom mt-1">
                          <label className="form-label">Duration:</label>
                          <p className="fs-5">{additionalService.duration}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}

                {/* Availability Time Slots */}
                <h5 className="mt-4">Availability Time Slots</h5>
                {serviceData.time_slots.map((slot: any) => (
                  <div key={slot.id} className="mb-3">
                    <div className="row">
                      <div className="col-md-4 border-bottom mt-1">
                        <label className="form-label">day:</label>
                        <p className="fs-5">{slot.slot_type}</p>
                      </div>

                      <div className="col-md-4 border-bottom mt-1">
                        <label className="form-label">From:</label>
                        <p className="fs-5">{slot.from}</p>
                      </div>

                      <div className="col-md-4 border-bottom mt-1">
                        <label className="form-label">To:</label>
                        <p className="fs-5">{slot.to}</p>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Location */}
                <h5 className="mt-4">Location</h5>
                <div className="row">
                  <div className="col-md-12 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Address:</label>
                      <p className="fs-5">{serviceData.location.address}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Country:</label>
                      <p className="fs-5">{serviceData.location.country}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">City:</label>
                      <p className="fs-5">{serviceData.location.city}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">State:</label>
                      <p className="fs-5">{serviceData.location.state}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Pincode:</label>
                      <p className="fs-5">{serviceData.location.pincode}</p>
                    </div>
                  </div>

                  <div className="col-md-12 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Google Map Place ID:</label>
                      <p className="fs-5">
                        {serviceData.location.gmap_place_id}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Latitude:</label>
                      <p className="fs-5">{serviceData.location.latitude}</p>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom mt-4">
                    <div className="mb-3">
                      <label className="form-label">Longitude:</label>
                      <p className="fs-5">{serviceData.location.longitude}</p>
                    </div>
                  </div>
                </div>

                {/* Media Images */}
                <h5 className="mt-4">Media</h5>
                {serviceData.media.map((mediaItem: any) => (
                  <div key={mediaItem.id} className="mb-3">
                    <div className="row">
                      <div className="col-md-12 border-bottom mt-1">
                        <label className="form-label">Image:</label>
                        <img
                          src={mediaItem.url}
                          alt={mediaItem.alt}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </form>
            ) : (
              <p>No service data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
