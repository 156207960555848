import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import CatogriesModal from '../common/modals/catogries-modal';
import CatogriesUpdateModal from '../common/modals/catogries-update-modal';
import DeleteCategoriesModal from '../common/modals/delete-categories-modal';
import { getCategoryList } from '../../../core/data/api/api';
import { format } from 'date-fns';

const CategoriesList = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Z - A' }];
  const [data, setData] = useState([]);
  const [token, setToken] = useState('');
  const [selectedCatogries, setSelectedCatogries] = useState({
    id: '',
    name: '',
    slug: '',
    image_url: '',
    is_featured: false,
  });

  useEffect(() => {
    const storedToken = localStorage.getItem('AdminToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.log('Token not found. Please sign up again.');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        
        const result = await getCategoryList(token);
        setData(result.data.payload.records);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    if (token) {
      fetchData();
    }
  }, [token]);

  // const renderImage = (rowData: any) => (
  //   <div className="image-column">
  //     <img
  //       src={rowData.image_url}
  //       alt={rowData.name}
  //       style={{ width: '40px', height: '40px', borderRadius: '50%' }}
  //     />
  //   </div>
  // );

  const handleRowClick = (rowData: any) => {
    setSelectedCatogries(rowData);
    // console.log("data of user we get",rowData)
  };

  const rowNumberTemplate = (
    rowData: any,
    { rowIndex }: { rowIndex: number },
  ) => {
    return rowIndex + 1;
  };

  const renderDate = (rowData: any) =>
    format(new Date(rowData.created_at), 'dd/MM/yyyy HH:mm');

  const actionButton = (rowData: any) => (
    <div className="table-actions d-flex">
      <button
        className="btn btn-primary me-2"
        onClick={() => handleRowClick(rowData)}
        data-bs-toggle="modal"
        data-bs-target="#edit-category"
      >
        Show details
      </button>
      <Link
        className="delete-table me-2"
        to="#"
        onClick={() => handleRowClick(rowData)}
        data-bs-toggle="modal"
        data-bs-target="#update-category"
      >
        <Icon.Edit className="react-feather-custom" />
      </Link>
      <Link
        className="delete-table border-none"
        onClick={() => handleRowClick(rowData)}
        to="#"
        data-bs-toggle="modal"
        data-bs-target="#delete-category"
      >
        <Icon.Trash2 className="react-feather-custom" />
      </Link>
    </div>
  );

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit mb-0">
            <h5>Categories</h5>
            <div className="list-btn">
              <ul>
                <li>
                  <div className="filter-sorting">
                    <ul>
                      <li>
                        <Link to="#" className="filter-sets">
                          <Icon.Filter className="react-feather-custom me-2" />
                          Filter
                        </Link>
                      </li>
                      <li>
                        <span>
                          <ImageWithBasePath
                            src="assets/img/icons/sort.svg"
                            className="me-2"
                            alt="img"
                          />
                        </span>
                        <div className="review-sort">
                          <Dropdown
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.value)}
                            options={value}
                            optionLabel="name"
                            placeholder="A - Z"
                            className="select admin-select-breadcrumb"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#add-category"
                  >
                    <i className="fa fa-plus me-2" />
                    Add Category
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={data}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 40]}
                    showGridlines
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column
                      field="index"
                      header="#"
                      body={rowNumberTemplate}
                    ></Column>
                    <Column sortable field="name" header="Categories"></Column>
                    <Column
                      sortable
                      field="slug"
                      header="Categories Slug"
                    ></Column>
                    <Column
                      sortable
                      field="created_at"
                      header="Date"
                      body={renderDate}
                    ></Column>
                    <Column
                      sortable
                      field="is_featured"
                      header="Featured"
                    ></Column>
                    <Column header="Action" body={actionButton}></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CatogriesModal selectedCatogries={selectedCatogries} />
      <CatogriesUpdateModal selectedCatogries={selectedCatogries} />
      <DeleteCategoriesModal selectedCatogries={selectedCatogries} />
    </>
  );
};

export default CategoriesList;
