import React from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const BookingPayment = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="content book-content">
        <div className="container">
          <div className="row">
            {/* Booking */}
            <div className="col-lg-10 mx-auto">
              {/* Booking Step */}
              <ul className="step-register row">
                <li className="activate col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li className="active col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/wallet-icon.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Payment</h6>
                    <p>Select Payment Gateway</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Done </h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>
              {/* /Booking Step */}
              {/* Booking Payment */}
              <div className="row">
                <div className="col-lg-6">
                  <h5 className="pay-title">Payment Methods</h5>
                  <div className="payment-card payment-bg">
                    <div className="payment-head">
                      <div className="payment-title">
                        <label className="custom_radio">
                          <input
                            type="radio"
                            name="payment"
                            className="card-payment"
                            defaultChecked
                          />
                          <span className="checkmark" />
                        </label>
                        <h6>Wallet</h6>
                      </div>
                    </div>
                  </div>
                  <div className="payment-card">
                    <div className="payment-head">
                      <div className="payment-title">
                        <label className="custom_radio">
                          <input
                            type="radio"
                            name="payment"
                            className="card-payment"
                          />
                          <span className="checkmark" />
                        </label>
                        <h6>Cash On Delivery</h6>
                      </div>
                    </div>
                  </div>
                  <div className="payment-card">
                    <div className="payment-head">
                      <div className="payment-title">
                        <label className="custom_radio credit-card-option">
                          <input
                            type="radio"
                            name="payment"
                            className="card-payment"
                          />
                          <span className="checkmark" />
                        </label>
                        <h6>Credit / Debit Card</h6>
                      </div>
                    </div>
                  </div>
                  <div className="payment-list" style={{ display: 'none' }}>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Name on Card</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Name on Card"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="col-form-label">Card Number</label>
                          <input
                            className="form-control"
                            placeholder="**** **** **** ****"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 text-end">
                        <div className="form-group">
                          <label className="col-form-label">&nbsp;</label>
                          <ImageWithBasePath
                            src="assets/img/payment-card.png"
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Expiration date
                          </label>
                          <input
                            className="form-control"
                            placeholder="MM/YY"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Security code
                          </label>
                          <input
                            className="form-control"
                            placeholder="CVV"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h5 className="pay-title">Booking Summary</h5>
                  <div className="summary-box">
                    <div className="booking-info">
                      <div className="service-book">
                        <div className="service-book-img">
                          <ImageWithBasePath src="assets/img/booking.jpg" alt="img" />
                        </div>
                        <div className="serv-profile">
                          <span className="badge">Car Wash</span>
                          <h2>Car Repair Services</h2>
                          <ul>
                            <li className="serv-pro">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-01.jpg"
                                alt="img"
                              />
                            </li>
                            <li className="serv-review">
                              <i className="fa-solid fa-star" />{' '}
                              <span>4.9 </span>(255 reviews)
                            </li>
                            <li className="service-map">
                              <Icon.MapPin
                                className="react-feather-custom"
                                color="#C2C9D1"
                              />{' '}
                              Alabama, USA
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="booking-summary">
                      <ul className="booking-date">
                        <li>
                          Date <span>07/09/2023</span>
                        </li>
                        <li>
                          Time <span>12.30 Pm - 01. 00 PM</span>
                        </li>
                        <li>
                          Service Provider <span>Thomas Herzberg</span>
                        </li>
                      </ul>
                      <ul className="booking-date">
                        <li>
                          Subtotal <span>$150.00</span>
                        </li>
                        <li>
                          Coupoun Discount <span>$5.00</span>
                        </li>
                        <li>
                          Services Charges <span>$3.00</span>
                        </li>
                      </ul>
                      <div className="booking-total">
                        <ul className="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span className="total-cost">$148.00</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="booking-coupon">
                    <div className="form-group w-100">
                      <div className="coupon-icon">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Coupon Code"
                        />
                        <span>
                          <ImageWithBasePath
                            src="assets/img/icons/coupon-icon.svg"
                            alt="image"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary apply-btn">
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="save-offer">
                    <p>
                      <i className="fa-solid fa-circle-check" /> Your total
                      saving on this order $5.00
                    </p>
                  </div>
                  <div className="booking-pay">
                    <Link
                      to={routes.bookingDone}
                      className="btn btn-primary btn-pay w-100"
                    >
                      Proceed to Pay $148
                    </Link>
                    <Link to="#" className="btn btn-secondary btn-skip">
                      Skip
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Booking Payment */}
            </div>
            {/* /Booking */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPayment;
